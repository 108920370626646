import {
  Autocomplete,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "../../../globalStyle";
import manImg from "../../../assets/icons/applications/man.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import {
  createPCContactThunk,
  editPCContactThunk,
  getPCContact,
  getPCContactForCreate,
} from "../../../store/actions/PCContactActions";
import CustomButton from "../../Common/CustomButton";
import CustomInput from "../../Common/CustomInput";
import CustomSelect from "../../Common/CustomSelect";
import { AddContactInputWrapper } from "../style";
import {
  PCDataType,
  dataType,
  postDataType,
  postPCDataType,
  selectT,
} from "../../../types/commonTypes";
import { FlexBetween, FlexCenter } from "../../Common/FlexWrapper";
import { nameTypes } from "../../PCApplications/data";
import { Add } from "@mui/icons-material";
import useSearch from "../../../hooks/useSearch";
import {
  getEmployees,
  getEmployeesList,
} from "../../../store/actions/employeesActions";
import UseReplace from "../../../hooks/useReplace";
import { useLocation, useNavigate } from "react-router-dom";
import CustomAutocomplete from "../../Common/CustomAutocomplete";
import { SearchInputWrapper } from "../../Process/style";
import i18next from "i18next";
import { getTypes } from "../../../store/actions/settingsActions";
import CustomInputTextfield from "../../Common/CustomInputTextfield";
import styled from "styled-components";
const Error = styled.div<{ errorFs?: string }>`
  color: red;
  margin-top: 15px;
  font-size: ${({ errorFs }) => (errorFs ? errorFs : "12px")};
`;
const AddContact: React.FC<{
  handleClose: () => void;
  setRemovedContacts: React.Dispatch<React.SetStateAction<any[] | undefined>>;
}> = ({ handleClose, setRemovedContacts }) => {
  const { Data } = useAppSelector((state) => state.settings);
  const { pcInterest } = Data;
  const { dataList: employees } = useAppSelector((state) => state.employees);
  const {
    dataForCreate: contacts,
    pending,
    editingItem,
    indicator,
  } = useAppSelector((state) => state.PCContact);
  const query = useSearch();
  const { pathname } = useLocation();

  const [data, setData] = useState<PCDataType | postPCDataType>({
    company_name: "",
    id: null,
    created_at: "",
    full_name: "",
    job_title: "",
    note: "",
    interests: null,
    telegram: "",
    manager: null,
    person_contacts: [{ phone_number: "" }],
  });
  const [checkErrors, setCheckErrors] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [finalValue, setFinalValue] = useState<string>("");

  useEffect(() => {
    const delay = 500; // Adjust the delay as needed (e.g., 500 milliseconds)
    const timeoutId = setTimeout(() => {
      setFinalValue(data.company_name);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [data.company_name]);
  useEffect(() => {
    dispatch(getPCContactForCreate(finalValue.trim()));
    dispatch(getTypes({ url: "physical/interest", dataType: "pcInterest" }));
  }, [finalValue]);
  const onChange = (value: string | number | any[], name: string) => {
    setCheckErrors(false);
    if (name === "company_name") {
      setData({ ...data, company_name: String(value) });
    } else if (name !== "company_name") setData({ ...data, [name]: value });
  };
  const onChangeSelect = (
    e: SelectChangeEvent,
    name: string,
    customData: selectT[]
  ) => {
    const selectData = customData;
    setData({
      ...data,
      [name]: selectData.find((item) => item.id === Number(e.target.value)!)!,
    });
  };
  const onChangeSelectAutocomplete = (
    e: string,
    name: string,
    dataItem: selectT[]
  ) => {
    const selectData = dataItem;
    console.log(
      selectData.find((item) => item.id === Number(e)),
      "dsafjkdsbfjhdb"
    );

    setData({
      ...data,
      [name]: selectData.find((item) => item.id === Number(e))!,
    });
  };
  console.log(contacts);

  useEffect(() => {
    if (indicator.includes("edit")) setData(editingItem);
  }, [editingItem, indicator]);
  const [nameExistAppear, setNameExistAppear] = useState(false);
  console.log(nameExistAppear, checkErrors);

  useEffect(() => {
    if (!pending) {
      if (
        !!!contacts?.results?.find(
          (contact) =>
            contact.company_name?.toLowerCase() ===
              data.company_name?.toLowerCase() &&
            (data.telegram?.trim()
              ? contact.telegram?.toLowerCase() === data.telegram?.toLowerCase()
              : contact.person_contacts[0]?.phone_number &&
                contact.person_contacts[0]?.phone_number ===
                  data.person_contacts[0]?.phone_number)
        ) ||
        editingItem.company_name?.toLowerCase() ===
          data.company_name?.toLowerCase()
      ) {
        setNameExistAppear(false);
      } else if (
        !!contacts?.results?.find(
          (contact) =>
            contact.company_name?.toLowerCase() ===
              data.company_name?.toLowerCase() &&
            (data.telegram.trim()
              ? contact.telegram?.toLowerCase() === data.telegram?.toLowerCase()
              : contact.person_contacts[0]?.phone_number ===
                data.person_contacts[0]?.phone_number)
        )
      ) {
        setNameExistAppear(true);
      }
    }
  }, [
    contacts.results,
    pending,
    data.company_name,
    data.telegram,
    data.person_contacts,
  ]);
  const handleSubmit = () => {
    if (
      nameExistAppear ||
      !data.company_name ||
      !data.full_name ||
      !data.job_title ||
      !data.interests?.length ||
      !(
        data.telegram.trim() ||
        !!data.person_contacts.filter(
          (contact) => contact.phone_number && contact.phone_number.length >= 13
        ).length
      )
    )
      setCheckErrors(true);
    else {
      if (indicator.includes("edit"))
        dispatch(
          editPCContactThunk({
            ...data,
            interests:
              data.interests?.map((interest) => (interest as selectT)?.id) ||
              null,
          } as postPCDataType)
        );
      else
        dispatch(
          createPCContactThunk({
            ...data,
            interests:
              data.interests?.map((interest) => (interest as selectT)?.id) ||
              null,
          } as postPCDataType)
        );
    }
  };
  // const navigate = useNavigate();
  // const handleContactSelection = (item: any) => {
  //   navigate(`/contact${UseReplace("id", String(item.id))}`);
  //   dispatch(handleClose);
  // };
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const handleInputChange = (value: string) => {
    dispatch(getEmployeesList({ name: value }));
    setInputValue(value);
    setAutoCompleteOptions(employees.results);
  };

  useEffect(() => {
    if (employees.results) {
      setAutoCompleteOptions(
        employees.results.map((value) => ({
          id: Number(value?.id),
          name: value?.first_name + " " + value?.last_name,
        }))
      );
    }
  }, [employees, dispatch]);
  useEffect(() => {
    data.manager?.first_name
      ? setInputValue(data.manager?.first_name + " " + data.manager?.last_name)
      : // @ts-ignore
        setInputValue(data.manager?.name);
  }, [data.manager]);
  return (
    <Box
      display="flex"
      borderRadius="10px"
      overflow="auto"
      bgcolor="#fff"
      width="100%"
      height="100%"
      maxHeight="90vh"
      boxShadow="0px 4px 6px -3px rgba(0, 0, 0, 0.1)"
    >
      <Stack width={"400px"} p="16px 47px" gap="30px" alignItems={"center"}>
        <Text c="#4f4f4f" fs="24px" fw="700">
          {indicator.includes("edit") ? t("contact.edit") : t("contact.add")}
        </Text>

        <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("common.full_name")}
          </Text>
          <CustomInput
            fs="14px"
            name="full_name"
            showError={checkErrors && !data.full_name}
            errorFs="12px"
            value={data.full_name}
            errorText={t("validations.fill")!}
            onChange={onChange}
            fullWidth
            type="text"
            height="30px"
            pl="10px"
          />
        </AddContactInputWrapper>
        <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("common.company_name")}
          </Text>
          {/* <FlexBetween gap="10px"> */}
          {/* <CustomSelect
              width="80px"
              value={
                data.name_type?.id ||
                nameTypes.find((type) => type.value === String(data.name_type))
                  ?.id
              }
              values={nameTypes}
              onChange={(e) => onChangeSelect(e, "name_type", nameTypes)}
              height="30px"
              showError={checkErrors && !data.name_type}
            /> */}
          <CustomInput
            fs="14px"
            name="company_name"
            showError={(checkErrors && !data.company_name) || nameExistAppear}
            errorFs="12px"
            value={data.company_name}
            onChange={onChange}
            fullWidth
            errorText={nameExistAppear ? "Этот контакт уже есть!" : ""}
            type="text"
            height="30px"
            pl="10px"
          />
          {/* </FlexBetween> */}
        </AddContactInputWrapper>
        {indicator.includes("edit") && (
          <AddContactInputWrapper>
            <Text c="#757575" fs="12px" fw="400">
              {t("contact.created_by")}
            </Text>
            <CustomAutocomplete
              inputValue={inputValue}
              setInputValue={handleInputChange}
              selectedOption={data.manager}
              setSelectedOption={(e) => {
                if (onChangeSelectAutocomplete) {
                  onChangeSelectAutocomplete(
                    e,
                    "manager",
                    employees.results?.map((value) => {
                      return {
                        id: Number(value?.id),
                        name: value?.first_name + " " + value?.last_name,
                      };
                    })
                  );
                } else return undefined;
              }}
              options={autoCompleteOptions}
            />
          </AddContactInputWrapper>
        )}
        {/* <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("contact.activity")}
          </Text>
          <CustomSelect
            width="100%"
            value={String(data.activity?.id)}
            values={Data.activities}
            onChange={(e) => onChangeSelect(e, "activity", Data.activities)}
          />
        </AddContactInputWrapper> */}
        <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("common.job_title")}
          </Text>
          <CustomInput
            fs="14px"
            name="job_title"
            showError={checkErrors && !data.job_title}
            errorFs="12px"
            value={data.job_title}
            errorText={t("validations.fill")!}
            onChange={onChange}
            fullWidth
            type="text"
            height="30px"
            pl="10px"
          />
        </AddContactInputWrapper>
        <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("common.interest")}
          </Text>
          <SearchInputWrapper>
            <Autocomplete
              multiple
              filterSelectedOptions
              value={(data.interests as selectT[]) || []}
              onChange={(event, newValue) => {
                console.log(newValue, event, "dewqdcaskoowq");
                onChange(newValue, "interests");
              }}
              className="recommendedInput"
              id="controllable-states-demo"
              options={
                pcInterest?.filter(
                  (item) =>
                    !(data.interests as selectT[])?.find(
                      (i: any) => i.id === item.id
                    )?.name
                ) || []
              }
              getOptionLabel={(option) => option.name}
              sx={{ width: "100%", padding: 0 }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    div: { height: "27px", padding: "0 !important" },
                    fieldset: { height: "40px", padding: "0 !important" },
                  }}
                  {...params}
                  placeholder="Выбирать"
                />
              )}
            />
            {checkErrors &&
              (!data.interests || data.interests.length === 0) && (
                <Error>*{i18next.t("validations.fill")}</Error>
              )}
          </SearchInputWrapper>
        </AddContactInputWrapper>
        <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("common.telegram")}
          </Text>
          <CustomInput
            fs="14px"
            name="telegram"
            showError={
              checkErrors &&
              !(
                data.telegram.trim() ||
                !!data.person_contacts.filter((contact) => contact.phone_number)
                  .length
              )
            }
            errorFs="12px"
            value={data.telegram}
            errorText={t("validations.fill")!}
            onChange={onChange}
            fullWidth
            type="text"
            height="30px"
            pl="10px"
          />
        </AddContactInputWrapper>
        <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("common.note")}
          </Text>
          <CustomInputTextfield
            fs="14px"
            name="note"
            errorFs="12px"
            value={data.note || ""}
            onChange={onChange}
            fullWidth
            type="text"
            height="50px"
            pl="10px"
          />
        </AddContactInputWrapper>
        {data.person_contacts &&
          data.person_contacts.map((contact, idx) => (
            <Stack gap={"20px"} key={idx} style={{ width: "100%" }}>
              <FlexBetween gap="10px">
                <h3>Контактное лицо {idx + 1}</h3>
                <h4
                  onClick={() => {
                    if (data.person_contacts.length === 1) {
                      const value = [{ phone_number: "", person_contact: "" }];
                      onChange(value, "person_contacts");
                      const removedValue = data.person_contacts.filter(
                        (contact, id) => idx === id
                      );
                      setRemovedContacts((prevState) =>
                        prevState
                          ? [...prevState, removedValue[0]]
                          : [removedValue[0]]
                      );
                    } else {
                      const value = data.person_contacts.filter(
                        (contact, id) => idx !== id
                      );
                      const removedValue = data.person_contacts.filter(
                        (contact, id) => idx === id
                      );
                      setRemovedContacts((prevState) =>
                        prevState
                          ? [...prevState, removedValue[0]]
                          : [removedValue[0]]
                      );
                      onChange(value, "person_contacts");
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  X
                </h4>
              </FlexBetween>

              {/* <AddContactInputWrapper>
              <Text c="#757575" fs="12px" fw="400">
                Контактное лицо
              </Text>
              <CustomInput
                name="person_contact"
                showError={
                  checkErrors && !(data.telegram || contact.phone_number)
                }
                value={contact.person_contact}
                // onChange={onChange}
                onChange={(e, name) => {
                  // if (!cannotChange) {
                  let value = [...data.person_contacts];
                  value[idx] = { ...value[idx], person_contact: String(e) };
                  console.log(value, name);
                  onChange(value, "person_contacts");
                  // } else return undefined;
                }}
                fullWidth
                type="text"
                height="33px"
                pl="10px"
              />
            </AddContactInputWrapper> */}
              <AddContactInputWrapper>
                <Text c="#757575" fs="12px" fw="400">
                  Телефон
                </Text>
                <CustomInput
                  name="phone_number"
                  showError={
                    checkErrors &&
                    !(
                      data.telegram.trim() ||
                      (contact.phone_number &&
                        contact.phone_number.length >= 13)
                    )
                  }
                  // errorText={
                  // data.phone_number.length < 13 ? "went wrong" : undefined
                  // !data.phone_number.startsWith("+998")
                  //   ? "went wrong"
                  //   : undefined
                  // }
                  value={contact.phone_number}
                  // onChange={onChange}
                  onChange={(e, name) => {
                    // if (!cannotChange) {
                    let value = [...data.person_contacts];
                    value[idx] = {
                      ...value[idx],
                      phone_number: String(e || ""),
                    };
                    console.log(value);

                    onChange(value, "person_contacts");
                    // } else return undefined;
                  }}
                  fullWidth
                  type="number"
                  height="33px"
                  pl="10px"
                />
              </AddContactInputWrapper>
            </Stack>
          ))}
        <FlexCenter>
          <CustomButton
            bgColor="#d3d3d3"
            fs="20px"
            value={<Add />}
            color="#000"
            type="button"
            width="123px"
            height="32px"
            padding="0"
            fw="700"
            onClick={() => {
              const value = [
                ...data.person_contacts,
                { person_contact: "", phone_number: "" },
              ];
              onChange(value, "person_contacts");
            }}
          />
        </FlexCenter>

        {/* 
      <AddContactInputWrapper>
        <Text c="#757575" fs="12px" fw="400">
          Контактное лицо
        </Text>
        <CustomInput
          fs="14px"
          name="person_contact"
          showError={checkErrors && !data.person_contact}
          value={data.person_contact}
          onChange={onChange}
          // onChange={!editingApp ? onChange : undefined}
          fullWidth
          type="text"
          height="30px"
          pl="10px"
        />
      </AddContactInputWrapper>

      <AddContactInputWrapper>
        <Text c="#757575" fs="12px" fw="400">
          {t("common.phoneNumber")}
        </Text>
        <CustomInput
          fs="14px"
          name="phone_number"
          showError={checkErrors && !data.phone_number}
          errorFs="12px"
          value={data.phone_number}
          errorText={t("validations.fill")!}
          onChange={onChange}
          fullWidth
          type="number"
          height="30px"
          pl="10px"
        />
      </AddContactInputWrapper> */}

        <Stack direction={"row"} gap="17px" alignItems="center">
          <Text
            c="#6C6C6C"
            fs="20px"
            fw="700"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          >
            {t("common.cancel")}
          </Text>
          <CustomButton
            bgColor="var(--primary)"
            fs="20px"
            value={
              indicator.includes("edit") ? t("common.edit") : t("common.save")
            }
            color="#fff"
            type="button"
            width="123px"
            height="32px"
            disable={nameExistAppear || checkErrors}
            padding="0"
            fw="700"
            onClick={handleSubmit}
          />
        </Stack>
      </Stack>
      <Stack
        borderLeft="1px solid #fefe"
        width={"400px"}
        p="16px 47px"
        gap="30px"
        alignItems={"center"}
      >
        {contacts.results?.length > 0 ? (
          <List sx={{ width: "100%" }}>
            {contacts.results.slice(0, 6).map((item: any) => (
              <ListItem
                disablePadding
                key={item.id}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  columnGap: "10px",
                  padding: "5px 15px",
                  marginTop: "7px",
                  width: "100%",
                  background: "#e4e4e4",
                  color: "#000",
                }}
              >
                <ListItemText
                  sx={{
                    textAlign: "left",
                    span: {
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Inter !important",
                    },
                  }}
                  primary={item.company_name}
                />
                <ListItemText
                  sx={{
                    textAlign: "left",
                    span: {
                      fontSize: "12px",
                      fontWeight: "400",
                      fontFamily: "Inter !important",
                      color: "#00000080",
                    },
                  }}
                  primary={
                    item.person_contacts[0]?.phone_number || item?.telegram
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <img src={manImg} height="250px" alt="" />
        )}
      </Stack>
    </Box>
  );
};

export default AddContact;
