export const defaultListItem = {
  work: null,
  job: null,
  price: "",
  price2: "",
  sum: "",
  number: "",
  total_price: "",
};
export const defaultLCClientData = {
  company_name: "",
  inn: "",
  activity: null,
  name_type: null,
  interests:null,
  deadline: "",
  note:"",
  expired_reason: "",
  is_archived: false,
  is_expired: false,
  manager: null,
  brand: [""],
  contacts: [{ phone_number: "", full_name: "", job_title: "", telegram: "" }],
};
export const defaultClientData = {
  name: "",
  telegram: "",
  activity: null,
  deadline: "",
  expired_reason: "",
  is_archived: false,
  is_expired: false,
  created_by: null,
  name_type: null,
  person_contacts: [{ phone_number: "", person_contact: "" }],
};
export const nameTypes = [
  { name: "ООО", value: "ooo", id: 1 },
  { name: "ЧП", value: "chp", id: 2 },
  { name: "ИП", value: "ip", id: 3 },
  { name: "СП", value: "sp", id: 4 },
  { name: "ННО", value: "nno", id: 5 },
  { name: "АО", value: "ao", id: 6 },
  { name: "ЧЛ", value: "chl", id: 7 },
  { name: "Гос", value: "гос", id: 8 },
  { name: "Ин.пр.", value: "ин_пр", id: 9 },
];
