import styled from "styled-components";
export const WrapperTable = styled.div`
  margin-top: 2rem;
  max-height: 600px;
  height: 100%;
  overflow: auto;

  thead tr th {
    position: sticky;
    top: 0;
  }

  /* If we use border,
we must use table-collapse to avoid
a slight movement of the header row */
  table {
    border-collapse: collapse;
  }

  /* Because we must set sticky on th,
 we have to apply background styles here
 rather than on thead */
  th {
    padding: 5px;
    padding-left: 15px;
    border-left: 1px dotted rgba(200, 209, 224, 0.6);
    border-bottom: 1px solid #e8e8e8;
    background: #ffc491;
    text-align: left;
    /* With border-collapse, we must use box-shadow or psuedo elements
  for the header borders */
    box-shadow: 0px 0px 0 2px #e8e8e8;
  }

  /* Basic Demo styling */
  table {
    width: 100%;
    font-family: sans-serif;
  }
  table td {
    padding: 16px;
  }
  table th div {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-right: 16px;
    }
  }
  tbody tr {
    border-bottom: 2px solid #e8e8e8;
  }
  thead {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
  tbody tr:hover {
    background: #e6f7ff;
  }
`;
export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 25%;
`;

export const Accordions = styled.div`
  height: 90%;
  /* height: calc(100vh - 230px); */
  overflow: auto;
  padding-right: 10px;
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: var(--primary);
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #919191;
    border-radius: 6px;
    cursor: pointer;
  }
`;

export const Expand = styled.div<{ isPayed: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;

  background-color: ${({ isPayed }) => (isPayed ? "#27AE60" : "#EB5757")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;

  svg {
    color: #fff;
  }
`;

export const PopoverCircle = styled.div<{ isActive: boolean }>`
  width: 7px;
  height: 7px;
  background: ${({ isActive }) => (isActive ? "#ff810e" : "transparent")};
  border-radius: 100%;
`;

export const AddContactInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DatePickerWrapper = styled.div`
  position: relative;

  .sign {
    position: absolute;
    background: var(--primary);
    border-radius: 3px 0px 0px 3px;
    width: 34px;
    height: 26px;
    /* left: 5px; */
    z-index: 1;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
  }
`;

export const CheckboxIcon = styled.div`
  width: 17px;
  height: 17px;
  background: #e0e0e0;
  border: 1px solid #bdbdbd;
  border-radius: 3px;
  transform: matrix(1, 0, 0, -1, 0, 0);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckedIcon = styled.div`
  width: 11px;
  height: 11px;
  background: #ff810e;
  border-radius: 2px;
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

export const ProcessLineWrapper = styled.div`
  height: 13px;
  border-radius: 6px;
  background: #e0e0e0;
  width: calc(100% - 55px);
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 3px;

  span {
    width: 7px;
    height: 7px;
    background: #828282;
    border-radius: 100%;
  }
`;

export const ProcessLine = styled.div<{ stepCount: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 13px;
  background: #ff8d3a;
  border-radius: 8px;
  width: calc(${({ stepCount }) => `${stepCount * 25}%`} + 6px);
  width: ${({ stepCount }) => stepCount === 0 && "0px"};
  display: flex;
  justify-content: space-between;
  padding: ${({ stepCount }) => (stepCount === 0 ? "0px" : "3px")};

  span {
    width: 7px;
    height: 7px;
    background: #fcfcfc;
    border-radius: 100%;
  }
`;
