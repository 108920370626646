import { createAsyncThunk } from "@reduxjs/toolkit";
import i18next from "i18next";
import { httpRequest } from "../../utils/request";
import { openSnackbar } from "../slices/mainSlice";
import {
  PCDataType,
  dataType,
  postDataType,
  postPCDataType,
} from "../../types/commonTypes";
import { nameTypes } from "../../components/PCApplications/data";

export const getPCContact = createAsyncThunk(
  "PCContact/getPCContact",
  async (query: any, thunkAPI): Promise<PCDataType[]> => {
    const ordering = query?.get("ordering");
    const searchName =
      query?.get("search-client-name") ||
      query?.get("search-recommended-name") ||
      query?.get("search-recommended-client-name");
    const name = query?.get("search-name");
    const activity = query?.get("activity");
    const manager = query?.get("manager");
    const from = query?.get("from");
    const to = query?.get("to");
    const page = query?.get("page");
    const status = JSON.parse(query?.get("status") || "[]")?.map(
      (item: any) => item?.id
    );
    const interest = JSON.parse(query?.get("interest") || "[]")?.map(
      (item: any) => item?.id
    );
    return await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/list/?${ordering ? `ordering=${ordering}` : ""}${
          activity ? `&activity=${activity}` : ""
        }${manager ? `&manager=${manager}` : ""}${
          from ? `&date_from=${from}` : ``
        }${to ? `&date_to=${to}` : ``}${
          searchName ? `&name=${searchName}` : ``
        }${page ? `&page=${page}` : ``}${name ? `&name=${name}` : ``}${
          status.length ? `&status=${status}` : ``
        }${interest.length ? `&interest=${interest}` : ``}`,
      })
    );
  }
);
export const getPCContactForCreate = createAsyncThunk(
  "PCContact/getPCContactForCreate",
  async (name: any, thunkAPI): Promise<PCDataType[]> => {
    return await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/list/?${name ? `name=${name}` : ``}`,
      })
    );
  }
);
export const getPCContactById = createAsyncThunk(
  "PCContact/getPCContactById",
  async (id: string, thunkAPI) =>
    await thunkAPI.dispatch(
      httpRequest({ url: `physical-client/detail/${id}/` })
    )
);

export const getPCContactActions = createAsyncThunk(
  "PCContact/getPCContactActions",
  async (id: string, thunkAPI) =>
    await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/order/filter/client/${id}/`,
      })
    )
);

export const createPCContactThunk = createAsyncThunk(
  "PCContact/createPCContactThunk",
  async (data: postPCDataType, thunkAPI) => {
    const order = {
      company_name: data.company_name,
      full_name: data.full_name,
      note: data.note,
      telegram: data.telegram,
      job_title: data.job_title,
      interests: data.interests,
      person_contacts: data.person_contacts.filter(
        (contact) => contact.phone_number
      ),
    };

    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/create/`,
        method: "POST",
        body: order,
        // haveImg: true,
      })
    );

    if (res.result.id) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.created"),
        })
      );

      return res.result;
    }
  }
);

export const deletePCContactThunk = createAsyncThunk(
  "PCContact/deletePCContactThunk",
  async (_, thunkAPI) => {
    const ids = (thunkAPI.getState() as any).PCContact.selectedItems;
    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/delete/`,
        method: "POST",
        body: { ids },
        noJson: true,
      })
    );

    if (res.status === 204 || res.status === 200) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.deleted"),
        })
      );
      return { status: "success" };
    }
  }
);

export const editPCContactThunk = createAsyncThunk(
  "PCContact/editPCContactThunk",
  async (data: postPCDataType, thunkAPI) => {
    const order = {
      company_name: data.company_name,
      note: data.note,
      full_name: data.full_name,
      telegram: data.telegram,
      job_title: data.job_title,
      interests: data.interests,
      manager: data.manager?.id,
      person_contacts: data.person_contacts.filter(
        (contact) => contact.phone_number
      ),
    };
    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/update/${data.id}/`,
        method: "PUT",
        body: order,
        // haveImg: true,
      })
    );

    if (res.data.id) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.updated"),
        })
      );
      return res.data;
    }
  }
);
