import React, { useState } from "react";
import styled from "styled-components";

interface Option {
  id: string;
  name: string;
}

interface AutocompleteProps {
  options: Option[];
  inputValue: string;
  height?: string;
  selectedOption: any;
  setInputValue: (value: string) => void;
  setSelectedOption: (value: string) => void;
}

const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
  input {
    height: ${(props: { height: string; showOptions: boolean }) =>
      props.height || "33px"};
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0 8px;
    font-size: 16px;
    font-weight: 500;
    outline: none; /* Remove default input focus border */
  }

  ul {
    list-style-type: none;
    z-index: 3;
    padding: 0;
    margin: 0;
    position: absolute;
    background: #fff;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-top: none;
    border-radius: 0 0 4px 4px;
    overflow-y: auto;
    max-height: 150px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: ${(props: { height: string; showOptions: boolean }) =>
      props.showOptions ? "block" : "none"};
  }

  li {
    padding: 10px;
    cursor: pointer;
  }

  li:hover {
    background-color: #eee;
  }

  li.selected {
    background-color: var(
      --primary
    ); /* Change this color to your desired highlight color */
  }
`;

const Autocomplete: React.FC<AutocompleteProps> = ({
  options,
  inputValue,
  selectedOption,
  setInputValue,
  setSelectedOption,
  height = "",
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setShowOptions(true);
  };

  const handleInputBlur = () => {
    setShowOptions(false);
  };
  const handleInputFocus = (ev: any) => {
    console.log(ev);

    setShowOptions(true);
  };

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option.id);
    setInputValue(option.name);
    setShowOptions(false);
  };

  return (
    <AutocompleteWrapper
      onMouseLeave={handleInputBlur}
      onMouseEnter={handleInputFocus}
      showOptions={showOptions}
      height={height}
    >
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocusCapture={handleInputFocus}
        onClick={handleInputFocus}
        placeholder=""
      />
      <ul style={{ zIndex: 5 }}>
        {options?.map((option) => (
          <li
            key={option.id}
            onClick={() => handleOptionClick(option)}
            className={option.id === selectedOption.id ? "selected" : ""}
          >
            {option.name}
          </li>
        ))}
      </ul>
    </AutocompleteWrapper>
  );
};

export default Autocomplete;
