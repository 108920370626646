import {
  Autocomplete,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Text } from "../../globalStyle";
import CustomButton from "../Common/CustomButton";
import CustomSelect from "../Common/CustomSelect";
import dayjs, { Dayjs } from "dayjs";
import { getTypes } from "../../store/actions/settingsActions";
import useSearch from "../../hooks/useSearch";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useLocation, useNavigate } from "react-router-dom";
import UseReplace from "../../hooks/useReplace";
import { useTranslation } from "react-i18next";
import "dayjs/locale/en-gb";
import { FilterWrapper } from "../PCApplications/style";
import {
  getPCApplications,
  getPCApplicationsByStep,
} from "../../store/actions/PCApplicationsActions";
import { FlexWrapper } from "../Common/FlexWrapper";
import { Replay } from "@mui/icons-material";
import {
  setPCAppsByStepPending,
  setAppsData,
} from "../../store/slices/PCApplicationsSlice";
import { statusT } from "../../types/commonTypes";
import { nameTypes } from "../PCApplications/data";
import {
  getLCClientsList,
  getPCClientsList,
} from "../../store/actions/clientsListActions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "../Common/DatePicker";
import { getEmployeesList } from "../../store/actions/employeesActions";
import { getManagersThunk } from "../../store/actions/mainActions";
import { SearchInputWrapper } from "../PCApplications/components/AddApplication/style";

const Filter: React.FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  const { Data, profile } = useAppSelector((state) => state.settings);
  const { managers } = useAppSelector((state) => state.main);

  const { dataList: employees } = useAppSelector((state) => state.employees);
  const { about, activities, lsStatuses, pcStatuses, pcInterest, lcInterest } =
    Data;
  const query = useSearch();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");

  const handleInputChange = (value: string) => {
    dispatch(getEmployeesList({ name: value }));
    setInputValue(value);
    setAutoCompleteOptions(employees?.results);
  };
  // const appTypes = t("applications.applicationsTypes", {
  //   returnObjects: true,
  // }) as statusT[];
  const onChangePicker = (value: Dayjs | null, keyPicker: string) => {
    const date = `${value?.year()}-${
      value?.month()! + 1 > 9
        ? value?.month()! + 1
        : "0" + (value?.month()! + 1)
    }-${value?.date()}`;
    navigate(`${pathname}${UseReplace(keyPicker, date)}`);
  };
  const onChange = (event: SelectChangeEvent, name: string) => {
    navigate(`${pathname}${UseReplace(name, event.target.value)}`);
  };
  useEffect(() => {
    if (employees.results) {
      setAutoCompleteOptions(
        employees.results.map((value) => ({
          id: Number(value?.id),
          name: value?.first_name + " " + value?.last_name,
        }))
      );
    }
  }, [employees, dispatch]);
  useEffect(() => {
    dispatch(getTypes({ url: "legal-settings", dataType: "lsStatuses" }));
    dispatch(getTypes({ url: "physical-settings", dataType: "pcStatuses" }));
    dispatch(getTypes({ url: "legal/interest", dataType: "lcInterest" }));
    dispatch(getTypes({ url: "physical/interest", dataType: "pcInterest" }));
    dispatch(getTypes({ url: "activity" }));
    dispatch(getTypes({ url: "activity" }));
    dispatch(getManagersThunk(""));
    dispatch(getTypes({ url: "survey", dataType: "about" }));
  }, [dispatch]);
  const hasPermission = [
    "director",
    "executive_director",
    "head_community_department",
  ];
  return (
    <Stack
      gap="26px"
      bgcolor={"#FCFCFC"}
      borderRadius="5px"
      boxShadow="0px 4px 6px -3px rgba(0, 0, 0, 0.1)"
      padding="15px 37px 20px 37px"
      alignItems="center"
      position="relative"
    >
      <Text c="#4f4f4f" fs="24px" fw="700">
        {t("filter.filter")}
      </Text>
      <Stack gap="50px" direction="row">
        <Stack gap="20px">
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              {window.location.pathname === "/clients-list-pc"
                ? "ЧЛ Интерес"
                : "ЮР Интерес"}
            </Text>

            <SearchInputWrapper>
              <Autocomplete
                multiple
                filterSelectedOptions
                value={JSON.parse(query.get("interest") || "[]") || null}
                onChange={(event, newValue) => {
                  console.log(newValue, event, "dewqdcaskoowq");
                  navigate(
                    `${pathname}${UseReplace(
                      "interest",
                      newValue.length ? JSON.stringify(newValue) : ""
                    )}`
                  );
                }}
                className="recommendedInput"
                id="controllable-states-demo"
                options={
                  window.location.pathname === "/clients-list-lc"
                    ? lcInterest || []
                    : pcInterest || []
                }
                getOptionLabel={(option) => option.name}
                sx={{ width: "100%", padding: 0 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Выбирать" />
                )}
              />
            </SearchInputWrapper>
          </FilterWrapper>
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              {window.location.pathname === "/clients-list-pc"
                ? "По ЧЛ Статусам заявки"
                : "По ЮР Статусам заявки"}
            </Text>
            {/* <CustomSelect
              width="261px"
              value={query.get("status") || ""}
              values={
                window.location.pathname === "/clients-list-lc"
                  ? lsStatuses
                  : pcStatuses
              }
              onChange={(e) => onChange(e, "status")}
            /> */}
            <SearchInputWrapper>
              <Autocomplete
                multiple
                filterSelectedOptions
                value={JSON.parse(query.get("status") || "[]") || null}
                onChange={(event, newValue) => {
                  console.log(newValue, event, "dewqdcaskoowq");
                  navigate(
                    `${pathname}${UseReplace(
                      "status",
                      newValue.length ? JSON.stringify(newValue) : ""
                    )}`
                  );
                }}
                className="recommendedInput"
                id="controllable-states-demo"
                options={
                  window.location.pathname === "/clients-list-lc"
                    ? lsStatuses || []
                    : pcStatuses || []
                }
                getOptionLabel={(option) => option.name}
                sx={{ width: "100%", padding: 0 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Выбирать" />
                )}
              />
            </SearchInputWrapper>
          </FilterWrapper>

          {/* {hasPermission.includes(profile.role) && ( */}
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              Менеджер
            </Text>
            <CustomSelect
              width="100%"
              height="33px"
              value={query.get("manager") || ""}
              values={managers?.map((item) => ({
                id: item.id,
                name: `${item.first_name} ${item.last_name}`,
              }))}
              onChange={(e) => onChange(e, "manager")}
            />
          </FilterWrapper>
          {/* )} */}
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              {t("filter.time")}
            </Text>
            <Stack direction={"row"} gap="15px">
              <LocalizationProvider
                adapterLocale="en-gb"
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  keyPicker="date_from"
                  title={t("filter.from")}
                  value={dayjs(query.get("date_from")) || dayjs("2023-01-01")}
                  onChangePicker={onChangePicker}
                />

                <DatePicker
                  keyPicker="date_to"
                  title={t("filter.to")}
                  value={dayjs(query.get("date_to")) || dayjs("2023-01-01")}
                  onChangePicker={onChangePicker}
                />
              </LocalizationProvider>
            </Stack>
          </FilterWrapper>
          {window.location.pathname === "/clients-list-lc" && (
            <>
              <FilterWrapper>
                <Text c="#828282" fs="9px" fw="400">
                  От куда о нас узнали
                </Text>
                <CustomSelect
                  width="261px"
                  value={query.get("survey")!}
                  values={about}
                  onChange={(e) => onChange(e, "survey")}
                />
              </FilterWrapper>
              <FilterWrapper>
                <Text c="#828282" fs="9px" fw="400">
                  {t("common.activity")}
                </Text>
                <CustomSelect
                  width="261px"
                  value={query.get("activity") || ""}
                  values={activities}
                  onChange={(e) => onChange(e, "activity")}
                />
              </FilterWrapper>
            </>
          )}
        </Stack>
      </Stack>

      <Stack direction={"row"} gap="17px" alignItems="center">
        <Text
          c="#6C6C6C"
          fs="20px"
          fw="700"
          onClick={handleClose}
          style={{ cursor: "pointer" }}
        >
          {t("common.cancel")}
        </Text>
        <CustomButton
          bgColor="var(--primary)"
          fs="20px"
          value="Поиск"
          color="#fff"
          type="button"
          width="123px"
          height="32px"
          padding="0"
          fw="700"
          onClick={() => {
            handleClose();
            window.location.pathname === "/clients-list-pc"
              ? dispatch(getPCClientsList({ query }))
              : dispatch(getLCClientsList({ query }));
          }}
        />
      </Stack>

      <FlexWrapper
        position="relative"
        bottom="0"
        right="0"
        color="#828282"
        alignItems="center"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          if (window.location.pathname === "/clients-list-pc") {
            dispatch(getPCClientsList({}));
            navigate(`/clients-list-pc`);
          } else {
            dispatch(getLCClientsList({}));
            navigate(`/clients-list-lc`);
          }
          handleClose();
        }}
      >
        <Replay fontSize="small" />
        <Text c="#828282" fs="14px" fw="400">
          Сбросить
        </Text>
      </FlexWrapper>
    </Stack>
  );
};

export default Filter;
