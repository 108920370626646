import React, { FC, useEffect, useState } from "react";
import {
  ItemsPerPage,
  PaginationTable,
  WrapperClientsTable,
  WrapperTable,
} from "./style";
import { useDispatch } from "react-redux";
import {
  getLCClientsList,
  getPCClientsList,
} from "../../../store/actions/clientsListActions";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { nameTypes } from "../../PCApplications/data";
import UseReplace from "../../../hooks/useReplace";
import { useLocation, useNavigate } from "react-router-dom";
import useSearch from "../../../hooks/useSearch";
import { calculateOrderInPage } from "../../../utils/helpers";
import { Pagination } from "@mui/material";
import {
  getMonitoringAppList,
  getMonitoringClientList,
} from "../../../store/actions/monitoringActions";
import { useTranslation } from "react-i18next";
type Props = {
  searchValue: string;
};

const MonitoringTable: FC<Props> = ({ searchValue }) => {
  const { applicationsList, clientsList, pending, openModal } = useAppSelector(
    (state) => state.monitoring
  );
  console.log(pending);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const query = useSearch();
  const { t } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    if (window.location.pathname.split("/")[2] === "application") {
      dispatch(
        getMonitoringAppList({ query, page_size: pageSize, page: currentPage })
      );
    } else if (window.location.pathname.split("/")[2] === "client") {
      dispatch(
        getMonitoringClientList({
          query,
          page_size: pageSize,
          page: currentPage,
        })
      );
    }
  }, []);
  useEffect(() => {
    if (window.location.pathname.split("/")[2] === "application") {
      dispatch(
        getMonitoringAppList({
          query,
          name: searchValue,
          page_size: pageSize,
          page: currentPage,
        })
      );
    } else if (window.location.pathname.split("/")[2] === "client") {
      dispatch(
        getMonitoringClientList({
          query,
          name: searchValue,
          page_size: pageSize,
          page: currentPage,
        })
      );
    }
    setPageSize(10);
    setCurrentPage(1);
    setSortDirection("");
    setSortField("");
    setCurrentSort("");
  }, [query.get("search-name")]);
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [currentSort, setCurrentSort] = useState("");

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    if (window.location.pathname.split("/")[2] === "application") {
      dispatch(
        getMonitoringAppList({
          query,
          name: searchValue,
          page_size: pageSize,
          page: pageNumber,
        })
      );
    } else if (window.location.pathname.split("/")[2] === "client") {
      dispatch(
        getMonitoringClientList({
          query,
          name: searchValue,
          page_size: pageSize,
          page: pageNumber,
        })
      );
    }
  };
  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    setCurrentPage(1);
    if (window.location.pathname.split("/")[2] === "application") {
      dispatch(
        getMonitoringAppList({
          query,
          name: searchValue,
          page_size: newSize,
          page: currentPage,
        })
      );
    } else if (window.location.pathname.split("/")[2] === "client") {
      dispatch(
        getMonitoringClientList({
          query,
          name: searchValue,
          page_size: newSize,
          page: currentPage,
        })
      );
    }
  };

  function convertToFormattedString(number: number) {
    return number?.toLocaleString("en-US");
  }
  const formatter = new Intl.NumberFormat("fr-FR");
  const changeTypeText = (changeType: string) => {
    switch (changeType) {
      case "post":
        return t("monitoring.created");
      case "update":
        return t("monitoring.updated");
      case "delete":
        return t("monitoring.deleted");
      case "restore":
        return t("monitoring.restored");
      default:
        return "Неизвестно";
    }
  };
  return (
    <WrapperClientsTable>
      <WrapperTable>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>
                <div>
                  <p>
                    {window.location.pathname.split("/")[2] === "application"
                      ? "Заявка"
                      : "Клиент"}
                  </p>
                </div>
              </th>
              <th>
                Тип{" "}
                {window.location.pathname.split("/")[2] === "application"
                  ? "заявка"
                  : "клиент"}
              </th>
              <th>Изменено кем</th>

              <th>Тип изменения</th>
              <th>Дата изменения</th>
            </tr>
          </thead>
          <tbody>
            {(window.location.pathname.split("/")[2] === "application"
              ? applicationsList
              : clientsList
            ).results?.map((order: any, idx: number) => (
              <tr key={idx}>
                <td>{calculateOrderInPage(currentPage, idx, pageSize)}</td>
                <td>
                  {window.location.pathname.split("/")[2] === "application"
                    ? order.legal_order?.client?.company_name ||
                      order.physical_order?.client?.full_name
                    : order.legal_client?.company_name ||
                      order.physical_client?.full_name}
                </td>
                <td>
                  {order.legal_order?.id || order.legal_client?.id
                    ? t("monitoring.legal")
                    : t("monitoring.physical")}
                </td>
                <td>
                  {order?.changed_by.first_name +
                    " " +
                    order?.changed_by.last_name}
                </td>
                <td>{changeTypeText(order.change_type)}</td>
                <td>
                  <p style={{ width: "max-content" }}>{order?.changed_date}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </WrapperTable>
      {/* Pagination */}
      {/* <PaginationTable>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1 || pending}
        >
          <ArrowBackIosNewIcon />
        </button>
        <span>{currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={!LCClientsList.next || pending}
        >
          <ArrowForwardIosIcon />
        </button>
      </PaginationTable> */}
      <PaginationTable>
        <ItemsPerPage>
          <p>Элементов на странице:</p>
          <input
            type="number"
            min={1}
            disabled={pending}
            value={pageSize}
            onChange={handlePageSizeChange}
          />
        </ItemsPerPage>
      </PaginationTable>
      <Pagination
        sx={{
          position: "absolute",
          bottom: "30px",
          right: "14rem",
        }}
        count={
          Math.floor(
            (window.location.pathname.split("/")[2] === "application"
              ? applicationsList
              : clientsList
            )?.count / pageSize
          ) +
            ((window.location.pathname.split("/")[2] === "application"
              ? applicationsList
              : clientsList
            )?.count % pageSize && 1) || 1
        }
        page={Number(currentPage)}
        onChange={(e, value) => {
          handlePageChange(value);
        }}
        color="primary"
      />
    </WrapperClientsTable>
  );
};

export default MonitoringTable;
