import {
  Autocomplete,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "../../../globalStyle";
import manImg from "../../../assets/icons/applications/man.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import {
  createPCContactThunk,
  editPCContactThunk,
  getPCContact,
  getPCContactForCreate,
} from "../../../store/actions/PCContactActions";
import CustomButton from "../../Common/CustomButton";
import CustomInput from "../../Common/CustomInput";
import CustomSelect from "../../Common/CustomSelect";
import { AddContactInputWrapper } from "../style";
import {
  LCDataType,
  postLCDataType,
  selectT,
} from "../../../types/commonTypes";
import { FlexBetween, FlexCenter } from "../../Common/FlexWrapper";
import { nameTypes } from "../../PCApplications/data";
import { Add } from "@mui/icons-material";
import useSearch from "../../../hooks/useSearch";
import {
  getEmployees,
  getEmployeesList,
} from "../../../store/actions/employeesActions";
import UseReplace from "../../../hooks/useReplace";
import { useLocation, useNavigate } from "react-router-dom";
import CustomAutocomplete from "../../Common/CustomAutocomplete";
import {
  createLCContactThunk,
  editLCContactThunk,
  getLCContactForCreate,
} from "../../../store/actions/LCContactActions";
import { SearchInputWrapper } from "../../Process/style";
import { Error } from "../../Login/style";
import i18next from "i18next";
import CustomInputTextfield from "../../Common/CustomInputTextfield";

const AddContact: React.FC<{
  handleClose: () => void;
  setRemovedContacts: React.Dispatch<React.SetStateAction<any[] | undefined>>;
}> = ({ handleClose, setRemovedContacts }) => {
  const { Data } = useAppSelector((state) => state.settings);
  const { lcInterest } = Data;
  const { dataList: employees } = useAppSelector((state) => state.employees);
  const {
    dataForCreate: contacts,
    pending,
    editingItem,
    indicator,
  } = useAppSelector((state) => state.LCContact);
  const query = useSearch();
  const { pathname } = useLocation();

  const [data, setData] = useState<LCDataType | postLCDataType>({
    // name: "",
    // telegram: "",
    // activity: Data.activities?.[0] || null,
    // created_by:
    //   //  Data.activities?.[0] ||
    //   null,
    // person_contacts: [{ person_contact: "", phone_number: "" }],
    // name_type: null,
    interests: null,
    company_name: "",
    inn: "",
    activity: null,
    id: null,
    created_at: "",
    note: "",
    brand: [""],
    manager: null,
    contacts: [
      { phone_number: "", telegram: "", full_name: "", job_title: "" },
    ],
  });
  console.log(data, "rwrw3--3-3");

  const [checkErrors, setCheckErrors] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [finalValue, setFinalValue] = useState<string>("");

  useEffect(() => {
    const delay = 500; // Adjust the delay as needed (e.g., 500 milliseconds)
    const timeoutId = setTimeout(() => {
      setFinalValue(data.company_name);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [data.company_name]);
  useEffect(() => {
    dispatch(getLCContactForCreate(finalValue.trim()));
  }, [finalValue]);
  const onChange = (value: string | number | any[], name: string) => {
    setCheckErrors(false);
    if (name === "company_name") {
      setData({ ...data, company_name: String(value) });
    } else if (name !== "company_name") setData({ ...data, [name]: value });
  };
  const onChangeSelect = (
    e: SelectChangeEvent,
    name: string,
    customData: selectT[]
  ) => {
    const selectData = customData;
    setCheckErrors(false);
    setData({
      ...data,
      [name]: selectData.find((item) => item.id === Number(e.target.value)!)!,
    });
  };
  const onChangeSelectAutocomplete = (
    e: string,
    name: string,
    dataItem: selectT[]
  ) => {
    const selectData = dataItem;
    setCheckErrors(false);
    console.log(
      selectData.find((item) => item.id === Number(e)),
      "dsafjkdsbfjhdb"
    );

    setData({
      ...data,
      [name]: selectData.find((item) => item.id === Number(e))!,
    });
  };
  console.log(data, "dwsuew88282");

  useEffect(() => {
    if (indicator.includes("edit")) setData(editingItem);
  }, [editingItem, indicator]);
  const [nameExistAppear, setNameExistAppear] = useState(false);
  useEffect(() => {
    if (!pending) {
      if (
        !!!contacts?.results?.find(
          (contact) =>
            contact.company_name?.toLowerCase() ===
              data.company_name?.toLowerCase() &&
            (data.contacts[0]?.telegram
              ? contact.contacts[0]?.telegram?.toLowerCase() ===
                data.contacts[0]?.telegram?.toLowerCase()
              : contact.contacts[0]?.phone_number &&
                contact.contacts[0]?.phone_number ===
                  data.contacts[0]?.phone_number)
        ) ||
        editingItem.company_name?.toLowerCase() ===
          data.company_name?.toLowerCase()
      ) {
        setNameExistAppear(false);
      } else if (
        !!contacts?.results?.find(
          (contact) =>
            contact.company_name?.toLowerCase() ===
              data.company_name?.toLowerCase() &&
            (data.contacts[0]?.telegram
              ? contact.contacts[0]?.telegram?.toLowerCase() ===
                data.contacts[0]?.telegram?.toLowerCase()
              : contact.contacts[0]?.phone_number ===
                data.contacts[0]?.phone_number)
        )
      ) {
        setNameExistAppear(true);
      }
    }
  }, [
    contacts.results,
    pending,
    data.company_name,
    data.contacts[0]?.telegram,
    data.contacts[0]?.phone_number,
  ]);
  const handleSubmit = () => {
    if (
      nameExistAppear ||
      !data.company_name ||
      // !(data.inn && data.inn.length >= 9) ||
      // !data.activity ||
      !!!data.contacts.filter(
        (contact) =>
          (contact.phone_number && contact.phone_number.length >= 13) ||
          // && contact.job_title
          (contact.telegram?.trim() && contact.full_name)
      ).length
    )
      setCheckErrors(true);
    else {
      if (indicator.includes("edit"))
        dispatch(editLCContactThunk(data as LCDataType));
      else dispatch(createLCContactThunk(data as postLCDataType));
    }
  };
  // const navigate = useNavigate();
  // const handleContactSelection = (item: any) => {
  //   navigate(`/contact${UseReplace("id", String(item.id))}`);
  //   dispatch(handleClose);
  // };
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const handleInputChange = (value: string) => {
    dispatch(getEmployeesList({ name: value }));
    setInputValue(value);
    setAutoCompleteOptions(employees.results);
  };

  useEffect(() => {
    if (employees.results) {
      setAutoCompleteOptions(
        employees.results.map((value) => ({
          id: Number(value?.id),
          name: value?.first_name + " " + value?.last_name,
        }))
      );
    }
  }, [employees, dispatch]);
  useEffect(() => {
    data.manager?.first_name
      ? setInputValue(data.manager?.first_name + " " + data.manager?.last_name)
      : // @ts-ignore
        setInputValue(data.manager?.name);
  }, [data.manager]);
  const formatter = new Intl.NumberFormat("fr-FR");

  return (
    <Box
      display="flex"
      borderRadius="10px"
      overflow="auto"
      bgcolor="#fff"
      width="100%"
      height="100%"
      maxHeight="90vh"
      boxShadow="0px 4px 6px -3px rgba(0, 0, 0, 0.1)"
    >
      <Stack width={"400px"} p="16px 47px" gap="30px" alignItems={"center"}>
        <Text c="#4f4f4f" fs="24px" fw="700">
          {indicator.includes("edit") ? t("contact.edit") : t("contact.add")}
        </Text>

        <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("contact.company")}
          </Text>
          <FlexBetween width="100%" gap="10px">
            {/* <CustomSelect
              width="80px"
              value={
                data.name_type?.id ||
                nameTypes.find((type) => type.value === String(data.name_type))
                  ?.id
              }
              values={nameTypes}
              onChange={(e) => onChangeSelect(e, "name_type", nameTypes)}
              height="30px"
              showError={checkErrors && !data.name_type}
            /> */}
            <CustomInput
              fs="14px"
              name="company_name"
              showError={(checkErrors && !data.company_name) || nameExistAppear}
              errorFs="12px"
              value={data.company_name}
              onChange={onChange}
              fullWidth
              errorText={nameExistAppear ? "Этот контакт уже есть!" : ""}
              type="text"
              height="30px"
              pl="10px"
            />
          </FlexBetween>
        </AddContactInputWrapper>
        {indicator.includes("edit") && (
          <AddContactInputWrapper>
            <Text c="#757575" fs="12px" fw="400">
              {t("contact.created_by")}
            </Text>
            <CustomAutocomplete
              inputValue={inputValue}
              setInputValue={handleInputChange}
              selectedOption={data.manager}
              setSelectedOption={(e) => {
                if (onChangeSelectAutocomplete) {
                  onChangeSelectAutocomplete(
                    e,
                    "manager",
                    employees.results?.map((value) => {
                      return {
                        id: Number(value?.id),
                        name: value?.first_name + " " + value?.last_name,
                      };
                    })
                  );
                } else return undefined;
              }}
              options={autoCompleteOptions}
            />
          </AddContactInputWrapper>
        )}
        <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("contact.activity")}
          </Text>
          <CustomSelect
            width="100%"
            value={String(data.activity?.id)}
            values={Data.clientTypes}
            // showError={checkErrors && !data.activity?.id}
            onChange={(e) => onChangeSelect(e, "activity", Data.clientTypes)}
          />
        </AddContactInputWrapper>
        {/* <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            Бренд{" "}
          </Text>
          <SearchInputWrapper>
            <Autocomplete
              multiple
              value={data.brand || []}
              onChange={(event, newValue) => {
                setData({
                  ...data,
                  brand: newValue!,
                });
              }}
              className="recommendedInput"
              id="controllable-states-demo"
              freeSolo
              options={[]}
              getOptionLabel={(option) => option}
              sx={{ width: "100%", padding: 0 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Выбирать" />
              )}
            />
            {checkErrors && (!data.brand || data.brand.length === 0) && (
              <Error>*{i18next.t("validations.fill")}</Error>
            )}
          </SearchInputWrapper>
        </AddContactInputWrapper> */}

        <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("common.inn")}
          </Text>
          <CustomInput
            fs="14px"
            name="inn"
            // showError={checkErrors && !(data.inn && data.inn.length >= 9)}
            errorFs="12px"
            value={formatter.format(Number(data.inn))}
            errorText={
              !(data.inn && data.inn?.length >= 9)
                ? "Введите не менее 9 символов"
                : t("validations.fill")!
            }
            onChange={(e) =>
              onChange(String(String(e).replaceAll(/\D/g, "")), "inn")
            }
            fullWidth
            type="text"
            height="30px"
            pl="10px"
          />
        </AddContactInputWrapper>
        <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("common.interest")}
          </Text>
          <SearchInputWrapper>
            <Autocomplete
              multiple
              filterSelectedOptions
              value={(data.interests as selectT[]) || []}
              onChange={(event, newValue) => {
                console.log(newValue, event, "dewqdcaskoowq");
                onChange(newValue, "interests");
              }}
              className="recommendedInput"
              id="controllable-states-demo"
              options={
                lcInterest?.filter(
                  (item) =>
                    !(data.interests as selectT[])?.find(
                      (i: any) => i.id === item.id
                    )?.name
                ) || []
              }
              getOptionLabel={(option) => option.name}
              sx={{ width: "100%", padding: 0 }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    div: { height: "27px", padding: "0 !important" },
                    fieldset: { height: "40px", padding: "0 !important" },
                  }}
                  {...params}
                  placeholder="Выбирать"
                />
              )}
            />
            {/* {checkErrors &&
              (!data.interests || data.interests.length === 0) && (
                <Error>*{i18next.t("validations.fill")}</Error>
              )} */}
          </SearchInputWrapper>
        </AddContactInputWrapper>
        <AddContactInputWrapper>
          <Text c="#757575" fs="12px" fw="400">
            {t("common.note")}
          </Text>
          <CustomInputTextfield
            fs="14px"
            name="note"
            errorFs="12px"
            value={data.note || ""}
            onChange={onChange}
            fullWidth
            type="text"
            height="50px"
            pl="10px"
          />
        </AddContactInputWrapper>
        {data.brand?.map((item, idx) => (
          <Stack key={idx} gap={"20px"} width="100%">
            <FlexBetween gap="10px" width="100%">
              <h3>Бренд {idx + 1}</h3>
              <h4
                onClick={() => {
                  if (data.brand!.length === 1) {
                    onChange([""], "brand");
                  } else {
                    const value = data.brand!.filter(
                      (contact, id) => idx !== id
                    );
                    onChange(value, "brand");
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                X
              </h4>
            </FlexBetween>
            <AddContactInputWrapper>
              <span>Бренд</span>
              <CustomInput
                name="brand"
                value={item}
                // onChange={onChange}
                onChange={(e, name) => {
                  let value = [...data.brand!];
                  value[idx] = String(e);
                  console.log(value, name);
                  onChange(value, "brand");
                }}
                fullWidth
                type="text"
                height="33px"
                pl="10px"
              />
            </AddContactInputWrapper>
          </Stack>
        ))}
        <FlexCenter>
          <CustomButton
            bgColor="#d3d3d3"
            fs="20px"
            value={<Add />}
            color="#000"
            type="button"
            width="123px"
            height="32px"
            padding="0"
            fw="700"
            onClick={() => {
              const value = data.brand ? [...data.brand!, ""] : [""];
              onChange(value, "brand");
            }}
          />
        </FlexCenter>
        {data.contacts?.map((contact, idx) => (
          <Stack gap={"20px"} key={idx} style={{ width: "100%" }}>
            <FlexBetween gap="10px">
              <h3>Контактное лицо {idx + 1}</h3>
              <h4
                onClick={() => {
                  if (data.contacts?.length === 1) {
                    const value = [{ phone_number: "", person_contact: "" }];
                    onChange(value, "contacts");
                    const removedValue = data.contacts.filter(
                      (contact, id) => idx === id
                    );
                    setRemovedContacts((prevState) =>
                      prevState
                        ? [...prevState, removedValue[0]]
                        : [removedValue[0]]
                    );
                  } else {
                    const value = data.contacts.filter(
                      (contact, id) => idx !== id
                    );
                    const removedValue = data.contacts.filter(
                      (contact, id) => idx === id
                    );
                    setRemovedContacts((prevState) =>
                      prevState
                        ? [...prevState, removedValue[0]]
                        : [removedValue[0]]
                    );
                    onChange(value, "contacts");
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                X
              </h4>
            </FlexBetween>

            <AddContactInputWrapper>
              <Text c="#757575" fs="12px" fw="400">
                Контактное лицо
              </Text>
              <CustomInput
                name="full_name"
                showError={checkErrors && !contact.full_name}
                value={contact.full_name}
                // onChange={onChange}
                onChange={(e, name) => {
                  // if (!cannotChange) {
                  let value = [...data.contacts];
                  value[idx] = { ...value[idx], full_name: String(e) };
                  console.log(value, name);
                  onChange(value, "contacts");
                  // } else return undefined;
                }}
                fullWidth
                type="text"
                height="33px"
                pl="10px"
              />
            </AddContactInputWrapper>
            <AddContactInputWrapper>
              <Text c="#757575" fs="12px" fw="400">
                {t("common.job_title")}
              </Text>
              <CustomInput
                fs="14px"
                name="job_title"
                // showError={checkErrors && !contact.job_title}
                errorFs="12px"
                value={contact.job_title}
                errorText={t("validations.fill")!}
                onChange={(e, name) => {
                  // if (!cannotChange) {
                  let value = [...data.contacts];
                  value[idx] = { ...value[idx], job_title: String(e) };
                  console.log(value, name);
                  onChange(value, "contacts");
                  // } else return undefined;
                }}
                fullWidth
                type="text"
                height="30px"
                pl="10px"
              />
            </AddContactInputWrapper>
            <AddContactInputWrapper>
              <Text c="#757575" fs="12px" fw="400">
                Телеграм
              </Text>
              <CustomInput
                name="telegram"
                showError={
                  checkErrors &&
                  !(
                    contact.telegram?.trim() ||
                    (contact.phone_number && contact.phone_number.length >= 13)
                  )
                }
                value={contact.telegram}
                // onChange={onChange}
                onChange={(e, name) => {
                  // if (!cannotChange) {
                  let value = [...data.contacts];
                  value[idx] = { ...value[idx], telegram: String(e) };
                  console.log(value, name);
                  onChange(value, "contacts");
                  // } else return undefined;
                }}
                fullWidth
                type="text"
                height="33px"
                pl="10px"
              />
            </AddContactInputWrapper>
            <AddContactInputWrapper>
              <Text c="#757575" fs="12px" fw="400">
                Телефон
              </Text>
              <CustomInput
                name="phone_number"
                showError={
                  checkErrors &&
                  !(
                    contact.telegram.trim() ||
                    (contact.phone_number && contact.phone_number.length >= 13)
                  )
                }
                // errorText={
                // data.phone_number.length < 13 ? "went wrong" : undefined
                // !data.phone_number.startsWith("+998")
                //   ? "went wrong"
                //   : undefined
                // }
                value={contact.phone_number}
                // onChange={onChange}
                onChange={(e, name) => {
                  // if (!cannotChange) {
                  let value = [...data.contacts];
                  value[idx] = { ...value[idx], phone_number: String(e) };
                  console.log(value);

                  onChange(value, "contacts");
                  // } else return undefined;
                }}
                fullWidth
                type="number"
                height="33px"
                pl="10px"
              />
            </AddContactInputWrapper>
          </Stack>
        ))}
        <FlexCenter>
          <CustomButton
            bgColor="#d3d3d3"
            fs="20px"
            value={<Add />}
            color="#000"
            type="button"
            width="123px"
            height="32px"
            padding="0"
            fw="700"
            onClick={() => {
              const value = [
                ...data.contacts,
                { person_contact: "", phone_number: "" },
              ];
              onChange(value, "contacts");
            }}
          />
        </FlexCenter>

        {/* 
      <AddContactInputWrapper>
        <Text c="#757575" fs="12px" fw="400">
          Контактное лицо
        </Text>
        <CustomInput
          fs="14px"
          name="person_contact"
          showError={checkErrors && !data.person_contact}
          value={data.person_contact}
          onChange={onChange}
          // onChange={!editingApp ? onChange : undefined}
          fullWidth
          type="text"
          height="30px"
          pl="10px"
        />
      </AddContactInputWrapper>

      <AddContactInputWrapper>
        <Text c="#757575" fs="12px" fw="400">
          {t("common.phoneNumber")}
        </Text>
        <CustomInput
          fs="14px"
          name="phone_number"
          showError={checkErrors && !data.phone_number}
          errorFs="12px"
          value={data.phone_number}
          errorText={t("validations.fill")!}
          onChange={onChange}
          fullWidth
          type="number"
          height="30px"
          pl="10px"
        />
      </AddContactInputWrapper> */}

        <Stack
          paddingBottom="10px"
          direction={"row"}
          gap="17px"
          alignItems="center"
        >
          <Text
            c="#6C6C6C"
            fs="20px"
            fw="700"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          >
            {t("common.cancel")}
          </Text>
          <CustomButton
            bgColor="var(--primary)"
            fs="20px"
            value={
              indicator.includes("edit") ? t("common.edit") : t("common.save")
            }
            color="#fff"
            type="button"
            width="123px"
            height="32px"
            disable={nameExistAppear || checkErrors}
            padding="0"
            fw="700"
            onClick={handleSubmit}
          />
        </Stack>
      </Stack>
      <Stack
        borderLeft="1px solid #fefe"
        width={"400px"}
        p="16px 47px"
        gap="30px"
        alignItems={"center"}
      >
        {contacts.results?.length > 0 ? (
          <List sx={{ width: "100%" }}>
            {contacts.results.slice(0, 6).map((item: any) => (
              <ListItem
                disablePadding
                key={item.id}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  columnGap: "10px",
                  padding: "5px 15px",
                  marginTop: "7px",
                  width: "100%",
                  background: "#e4e4e4",
                  color: "#000",
                }}
              >
                <ListItemText
                  sx={{
                    textAlign: "left",
                    span: {
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Inter !important",
                    },
                  }}
                  primary={item.company_name}
                />
                <ListItemText
                  sx={{
                    textAlign: "left",
                    span: {
                      fontSize: "12px",
                      fontWeight: "400",
                      fontFamily: "Inter !important",
                      color: "#00000080",
                    },
                  }}
                  primary={item.contacts[0]?.phone_number || item?.telegram}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <img src={manImg} height="250px" alt="" />
        )}
      </Stack>
    </Box>
  );
};

export default AddContact;
