import styled from "styled-components";

export const RecommendedCard = styled.div`
  display: flex;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
  background: #fff;
  height: 34px;
  padding: 7px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  max-width: 300px;
  width: max-content;
  overflow: auto;
  p {
    background: #ff810e;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 4px;
    padding: 2px 7px;
    font-size: 12px;
    margin: 0 5px;
  }
`;
export const ChooseRecommended = styled.button`
  width: max-content;
  height: 34px;
  background: #ff810e;
  color: #fff;
  border: none;
  padding: 5px 7px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
`;
export const WrapperRecommended = styled.div`
  display: flex;
`;
export const SearchInputWrapper = styled.div`
  width: 100%;
  position: relative;

  .recommendedInput > div > div > div {
    height: 23px;
  }
  .recommendedInput fieldset {
    background: #ffffff;
    /* border-color: #fff !important; */
    border: 1px solid #bebebe !important;
  }
  .recommendedInput div,
  .recommendedInput input {
    min-height: 33px;
    color: #000;
    z-index: 4;
    padding: 0;
  }
  .recommendedInput input {
    padding: 0 9px !important;
  }
  .recommendedInput input::placeholder {
    opacity: 1;
    font-style: italic;
  }
`;
export const DeadlinesWrapper = styled.div`
  padding: 20px;
  .deadlinesList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    .deadline {
      &:hover {
        p {
          color: #fff;
        }
        background: var(--primary);
      }
      p {
        color: #000;
      }
      background: #fff;
      cursor: pointer;
      border: 1px solid #00000060;
      padding: 5px 10px;
      border-radius: 8px;
    }
  }
`;
export const ShortPriceWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 10px;
  width: 500px;
  max-width: 100%;
  .deadlinesList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    .deadline {
      &:hover {
        p {
          color: #fff;
        }
        background: var(--primary);
      }
      p {
        color: #000;
      }
      background: #fff;
      cursor: pointer;
      border: 1px solid #00000060;
      padding: 5px 10px;
      border-radius: 8px;
    }
  }
`;
export const ExpiredReasonsWrapper = styled.div`
  padding: 20px;
  .expiredReasonsList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    .expiredReason {
      &:hover {
        p {
          color: #fff;
        }
        background: var(--primary);
      }
      p {
        color: #000;
      }
      background: #fff;
      cursor: pointer;
      border: 1px solid #00000060;
      padding: 5px 10px;
      border-radius: 8px;
    }
  }
`;
