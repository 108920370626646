import React, { FC, useEffect, useState } from "react";
import {
  ItemsPerPage,
  PaginationTable,
  WrapperClientsTable,
  WrapperTable,
} from "./style";
import { useDispatch } from "react-redux";
import {
  getLCClientsList,
  getPCClientsList,
} from "../../../store/actions/clientsListActions";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { nameTypes } from "../../PCApplications/data";
import UseReplace from "../../../hooks/useReplace";
import { useLocation, useNavigate } from "react-router-dom";
import useSearch from "../../../hooks/useSearch";
import { calculateOrderInPage } from "../../../utils/helpers";
import { Pagination } from "@mui/material";
type Props = {
  searchValue: string;
};

const LCClientsTable: FC<Props> = ({ searchValue }) => {
  const { LCClientsList, pending } = useAppSelector(
    (state) => state.clientsList
  );
  console.log(pending);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const query = useSearch();
  const location = useLocation();
  useEffect(() => {
    !location.search && navigate("/clients-list-lc");
  }, []);
  useEffect(() => {
    dispatch(
      getLCClientsList({
        query,
        person_contact: searchValue,
        page_size: 10,
      })
    );
    setPageSize(10);
    setCurrentPage(1);
    setSortDirection("");
    setSortField("");
    setCurrentSort("");
  }, [query.get("search-name")]);
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [currentSort, setCurrentSort] = useState("");

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    dispatch(
      getLCClientsList({
        query,
        person_contact: searchValue,
        page_size: pageSize,
        page: pageNumber,
        order_by: currentSort,
      })
    );
  };
  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    setCurrentPage(1);
    dispatch(
      getLCClientsList({
        query,
        person_contact: searchValue,
        page_size: newSize,
        page: currentPage,
        order_by: currentSort,
      })
    );
  };
  const handleSortChange = (field: string) => {
    console.log(field);
    if (sortField === field) {
      // Toggle sort direction if the same field is clicked again
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Set sort field and default sort direction
      setSortField(field);
      setSortDirection("asc");
    }
    setCurrentSort(sortDirection === "asc" ? field : "reverse_" + field);

    dispatch(
      getLCClientsList({
        query,
        person_contact: searchValue,
        page_size: pageSize,
        page: currentPage,
        order_by: sortDirection === "asc" ? field : "reverse_" + field,
      })
    );
  };
  function convertToFormattedString(number: number) {
    return number?.toLocaleString("en-US");
  }
  const formatter = new Intl.NumberFormat("fr-FR");

  return (
    <WrapperClientsTable>
      <WrapperTable>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>
                <div
                  onClick={() => !pending && handleSortChange("company_name")}
                >
                  <p>Название компании</p>
                  <ArrowUpwardIcon
                    style={{
                      cursor: "pointer",
                      transform:
                        sortField === "company_name"
                          ? `rotate(${sortDirection === "asc" ? 0 : 180}deg)`
                          : "rotate(0deg)",
                    }}
                  />{" "}
                </div>
              </th>
              <th>Вид деятельности</th>
              <th>ИНН</th>
              <th>
                <p style={{ width: "max-content" }}>Бренд 1</p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}>Бренд 2</p>
              </th>
              <th>Контактное лицо ФИО;</th>
              <th>Должность</th>
              <th>Интерес</th>
              <th>Контакт</th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}>Дата создания контакта </p>
              </th>
              <th>
                {" "}
                <p style={{ width: "max-content" }}>Первая заявка</p>
              </th>
              <th>
                <p style={{ width: "max-content" }}>Ответственный менеджер</p>
              </th>
              <th>
                <div
                  onClick={() =>
                    !pending && handleSortChange("order_created_at")
                  }
                >
                  <p>Дата начала договора</p>
                  <ArrowUpwardIcon
                    style={{
                      cursor: "pointer",
                      transform:
                        sortField === "order_created_at"
                          ? `rotate(${sortDirection === "asc" ? 0 : 180}deg)`
                          : "rotate(0deg)",
                    }}
                  />{" "}
                </div>
              </th>
              <th>
                <div
                  onClick={() =>
                    !pending && handleSortChange("order_finished_at")
                  }
                >
                  <p>Дата окончания договора</p>
                  <ArrowUpwardIcon
                    style={{
                      cursor: "pointer",
                      transform:
                        sortField === "order_finished_at"
                          ? `rotate(${sortDirection === "asc" ? 0 : 180}deg)`
                          : "rotate(0deg)",
                    }}
                  />{" "}
                </div>
              </th>

              <th>Откуда о нас узнали</th>
              <th>
                <div
                  onClick={() => !pending && handleSortChange("num_of_booking")}
                >
                  <p>Кол-во продлений</p>{" "}
                  <ArrowUpwardIcon
                    style={{
                      cursor: "pointer",
                      transform:
                        sortField === "num_of_booking"
                          ? `rotate(${sortDirection === "asc" ? 0 : 180}deg)`
                          : "rotate(0deg)",
                    }}
                  />{" "}
                </div>
              </th>
              <th>Заметка</th>
              <th>
                <div onClick={() => !pending && handleSortChange("ltv")}>
                  <p>LTV</p>{" "}
                  <ArrowUpwardIcon
                    style={{
                      cursor: "pointer",
                      transform:
                        sortField === "ltv"
                          ? `rotate(${sortDirection === "asc" ? 0 : 180}deg)`
                          : "rotate(0deg)",
                    }}
                  />{" "}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {LCClientsList.results?.map((client: any, idx: number) => (
              <tr key={idx}>
                <td>{calculateOrderInPage(currentPage, idx, pageSize)}</td>
                <td
                  style={{ cursor: "pointer", textDecorationLine: "underline" }}
                  onClick={() =>
                    navigate(
                      `/contact-lc${UseReplace("id", String(client.id))}`,
                      {
                        state: { prevPath: location.pathname },
                      }
                    )
                  }
                >
                  {client.company_name}
                </td>
                <td>
                  <p style={{ width: "max-content", maxWidth: "200px" }}>
                    {client?.activity?.name}
                  </p>
                </td>
                <td>{formatter.format(Number(client.inn))}</td>
                <td>{client?.brand ? client?.brand[0] : ""}</td>
                <td>{client?.brand ? client?.brand[1] : ""}</td>
                <td>{client.contact[0]?.full_name}</td>
                <td>{client.contact[0]?.job_title}</td>
                <td>
                  {client.interests
                    ?.map((item: any) => item.name)
                    ?.slice(0, 3)
                    .join(",")}
                </td>
                <td>
                  {client.contact[0]?.phone_number ||
                    client.contact[0]?.telegram}
                </td>
                <td>{client.created_at}</td>
                <td>{client?.first_app_time}</td>
                <td>
                  <p style={{ width: "max-content" }}>{client?.last_manager}</p>
                </td>
                <td>
                  <p style={{ width: "max-content" }}>
                    {client?.order_created_at}
                  </p>
                </td>
                <td>
                  <p style={{ width: "max-content" }}>
                    {client?.order_finished_time}
                  </p>
                </td>
                <td>{client.survey}</td>
                <td style={{ textAlign: "center" }}>{client.num_of_booking}</td>
                <td style={{ textAlign: "center" }}>
                  <p style={{ width: "max-content", maxWidth: "500px" }}>
                    {client?.note}
                  </p>
                </td>
                <td style={{ textAlign: "center" }}>
                  {convertToFormattedString(client.ltv)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </WrapperTable>
      {/* Pagination */}
      {/* <PaginationTable>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1 || pending}
        >
          <ArrowBackIosNewIcon />
        </button>
        <span>{currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={!LCClientsList.next || pending}
        >
          <ArrowForwardIosIcon />
        </button>
      </PaginationTable> */}
      <PaginationTable>
        <ItemsPerPage>
          <p>Элементов на странице:</p>
          <input
            type="number"
            min={1}
            disabled={pending}
            value={pageSize}
            onChange={handlePageSizeChange}
          />
        </ItemsPerPage>
      </PaginationTable>
      <Pagination
        sx={{
          position: "absolute",
          bottom: "30px",
          right: "14rem",
        }}
        count={
          Math.floor(LCClientsList?.count / pageSize) +
            (LCClientsList?.count % pageSize && 1) || 1
        }
        page={Number(currentPage)}
        onChange={(e, value) => {
          handlePageChange(value);
        }}
        color="primary"
      />
    </WrapperClientsTable>
  );
};

export default LCClientsTable;
