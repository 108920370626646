import PCContact from "../components/PCContact";
import LCContact from "../components/LCContact";
import Employees from "../components/Employees";
import Process from "../components/Process";
import Settings from "../components/Settings";
import Statistics from "../components/Statistics";
import PCApplications from "../components/PCApplications";
import NotFound from "../pages/NotFound";
import ClientsList from "../components/ClientsList";
import Reports from "../components/Reports";
import LCApplications from "../components/LCApplications";
import Monitoring from "../components/Monitoring";

export const routes = [
  {
    path: "/statistics",
    component: <Statistics />,
    permission: [
      "director",
      "executive_director",
      "head_communication_department",
      "pr_manager_communication",
      "head_community_department",
      "community_manager",
      "events_department_manager",
    ],
  },
  {
    path: "/monitoring/client",
    component: <Monitoring />,
    permission: ["director", "executive_director"],
  },
  {
    path: "/monitoring/application",
    component: <Monitoring />,
    permission: ["director", "executive_director"],
  },
  {
    path: "/monitoring",
    component: <Monitoring />,
    permission: ["director", "executive_director"],
  },
  {
    path: "/reports",
    component: <Reports />,
    permission: [
      "director",
      "executive_director",
      "head_communication_department",
      "pr_manager_communication",
      "head_community_department",
      "community_manager",
      "events_department_manager",
    ],
  },
  {
    path: "/applications-lc",
    component: <LCApplications />,
    permission: [
      "director",
      "executive_director",
      "head_communication_department",
      "pr_manager_communication",
      "head_community_department",
      "community_manager",
      "events_department_manager",
    ],
  },
  {
    path: "/applications-pc",
    component: <PCApplications />,
    permission: [
      "director",
      "executive_director",
      "head_communication_department",
      "pr_manager_communication",
      "head_community_department",
      "community_manager",
      "events_department_manager",
    ],
  },

  {
    path: "/clients-list-lc",
    component: <ClientsList />,
    permission: [
      "director",
      "executive_director",
      "head_communication_department",
      "pr_manager_communication",
      "head_community_department",
      "community_manager",
      "events_department_manager",
    ],
  },
  {
    path: "/clients-list-pc",
    component: <ClientsList />,
    permission: [
      "director",
      "executive_director",
      "head_communication_department",
      "pr_manager_communication",
      "head_community_department",
      "community_manager",
      "events_department_manager",
    ],
  },
  {
    path: "/contact-pc",
    component: <PCContact />,
    permission: [
      "director",
      "executive_director",
      "head_communication_department",
      "pr_manager_communication",
      "head_community_department",
      "community_manager",
      "events_department_manager",
    ],
  },
  {
    path: "/contact-lc",
    component: <LCContact />,
    permission: [
      "director",
      "executive_director",
      "head_communication_department",
      "pr_manager_communication",
      "head_community_department",
      "community_manager",
      "events_department_manager",
    ],
  },
  {
    path: "/process",
    component: <Process />,
    permission: [
      "director",
      "executive_director",
      "head_communication_department",
      "pr_manager_communication",
      "head_community_department",
      "community_manager",
      "events_department_manager",
    ],
  },
  {
    path: "/employees",
    component: <Employees />,
    permission: [
      "director",
      "executive_director",
      "head_communication_department",
      "pr_manager_communication",
      "head_community_department",
      "events_department_manager",
    ],
  },
  {
    path: "/settings",
    component: <Settings />,
    permission: [
      "director",
      "executive_director",
      "head_communication_department",
      "pr_manager_communication",
      "head_community_department",
      "community_manager",
      "events_department_manager",
    ],
  },
  {
    path: "/*",
    component: <NotFound />,
  },
];
