import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createPCContactThunk,
  deletePCContactThunk,
  editPCContactThunk,
  getPCContact,
  getPCContactActions,
  getPCContactById,
  getPCContactForCreate,
} from "../actions/PCContactActions";
import {
  appT,
  dataType,
  LCDataType,
  PCDataType,
  selectT,
} from "../../types/commonTypes";
import {
  createLCContactThunk,
  deleteLCContactThunk,
  editLCContactThunk,
  getLCContact,
  getLCContactActions,
  getLCContactById,
  getLCContactForCreate,
} from "../actions/LCContactActions";

type contactDataType = { count: number; results: LCDataType[] };

type stepActionT = {
  step: string;
  created_at: string;
};

export type contactActionT = {
  id: 2;
  applications: appT[];
  telegram: string;
  created_at: string;
  steps: stepActionT[];
  survey: {
    id: 1;
    name: string;
  };
  manager: {
    id: 1;
    first_name: string;
    last_name: string;
  };
  total_price: 2000;
  step: string;
};

type selectedContactT = {
  client: LCDataType | null;

  actions: {
    count: number;
    results: contactActionT[];
    next: string;
    previous: string;
  } | null;
};

const initialState = {
  data: {} as contactDataType,
  dataForCreate: {} as contactDataType,
  contactById: null as LCDataType | null,
  pending: false,
  actionsPending: "",
  openModal: "",
  selectedItems: [] as number[],
  editingItem: {} as LCDataType,
  indicator: "",
  selectedContact: {} as selectedContactT,
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    handleOpen: (state, action: PayloadAction<string>) => {
      state.openModal = action.payload;
    },
    handleClose: (state) => {
      state.openModal = "";
      state.editingItem = {
        company_name: "",
        inn: "",
        note: "",
        activity: null,
        interests: null,
        id: null,
        created_at: "",
        brand: null,
        manager: null,
        contacts: [
          { phone_number: "", telegram: "", full_name: "", job_title: "" },
        ],
      };
    },
    setSelectedItems: (state, action: PayloadAction<number>) => {
      const selectedId = action.payload;
      let ids = state.selectedItems;

      if (ids.includes(selectedId)) ids = ids.filter((i) => i !== selectedId);
      else ids.push(selectedId);
      state.selectedItems = ids;

      if (ids.length > 0) {
        if (ids.length > 1) state.indicator = "delete";
        else state.indicator = "delete edit";
      } else state.indicator = "";
    },
    setEditingItem: (state, action: PayloadAction<LCDataType>) => {
      state.editingItem = action.payload;
    },
    emptyCheckedItems: (state) => {
      state.selectedItems = [];
    },
    setIndicator: (state, action: PayloadAction<string>) => {
      state.indicator = action.payload;
    },
    emptyContactById: (state) => {
      state.contactById = null;
    },
  },
  extraReducers: {
    [(getLCContact as any).pending]: (state) => {
      state.pending = true;
    },
    [(getLCContact as any).fulfilled]: (
      state,
      action: PayloadAction<contactDataType>
    ) => {
      state.data = action.payload;
      state.openModal = "";
      state.pending = false;
    },
    [(getLCContactForCreate as any).pending]: (state) => {
      state.pending = true;
    },
    [(getLCContactForCreate as any).fulfilled]: (
      state,
      action: PayloadAction<contactDataType>
    ) => {
      state.dataForCreate = action.payload;
      state.pending = false;
    },

    [(getLCContactById as any).fulfilled]: (
      state,
      action: PayloadAction<LCDataType>
    ) => {
      state.contactById = action.payload;
      state.selectedContact.client = action.payload;
    },

    [(getLCContactActions as any).pending]: (state) => {
      state.actionsPending = "contact-actions";
    },
    [(getLCContactActions as any).fulfilled]: (
      state,
      action: PayloadAction<{
        count: number;
        results: contactActionT[];
        next: string;
        previous: string;
      }>
    ) => {
      state.selectedContact.actions = action.payload;
      state.actionsPending = "";
    },
    [(createLCContactThunk as any).pending]: (state) => {
      state.actionsPending = "adding";
    },
    [(createLCContactThunk as any).fulfilled]: (
      state,
      action: PayloadAction<LCDataType>
    ) => {
      const data = action.payload;

      if (data.id) {
        state.data.results.unshift(data);
        state.data.count++;
      }

      state.actionsPending = "";
      state.openModal = "";
    },

    [(deleteLCContactThunk as any).pending]: (state) => {
      state.actionsPending = "delete";
    },
    [(deleteLCContactThunk as any).fulfilled]: (
      state,
      action: PayloadAction<{ status: string }>
    ) => {
      if (action?.payload?.status === "success") {
        let ids = state.selectedItems;
        let items = state.data.results;

        ids.forEach((selectedId) => {
          items = items.filter((item) => item.id !== selectedId);
        });

        state.data = { results: items, count: state.data.count - ids.length };
        state.selectedItems = [];
      }

      state.actionsPending = "";
    },

    [(editLCContactThunk as any).pending]: (state) => {
      state.actionsPending = "edit";
    },
    [(editLCContactThunk as any).fulfilled]: (
      state,
      action: PayloadAction<LCDataType>
    ) => {
      const data = action.payload;

      if (data?.id) {
        const editingItem = data;
        const items = state.data.results;

        const index = items.findIndex((item) => item.id === editingItem.id);
        items.splice(index, 1, editingItem);
        state.data = { ...state.data, results: items };

        state.editingItem = {
          company_name: "",
          note: "",
          inn: "",
          activity: null,
          interests: null,
          id: null,
          created_at: "",
          manager: null,
          brand: null,
          contacts: [
            { phone_number: "", telegram: "", full_name: "", job_title: "" },
          ],
        };
        state.actionsPending = "";
        state.openModal = "";
      }

      state.actionsPending = "";
    },
  },
});

export const {
  handleOpen,
  handleClose,
  setSelectedItems,
  setEditingItem,
  emptyCheckedItems,
  setIndicator,
  emptyContactById,
} = contactSlice.actions;
export default contactSlice.reducer;
