import { Box, Stack } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import CustomInput from "../Common/CustomInput";
import { useLocation, useNavigate } from "react-router";
import filter from "../../assets/icons/filter.svg";
import UseReplace from "../../hooks/useReplace";
import searchIcon from "../../assets/icons/search.svg";
import { useTranslation } from "react-i18next";
import { Text } from "../../globalStyle";
import { FlexBetween } from "../Common/FlexWrapper";
import LCClientsTable from "./LCClientsTable";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { nameTypes } from "../PCApplications/data";
import SaveIcon from "@mui/icons-material/Save";
import CustomButton from "../Common/CustomButton";
import { handleClose, handleOpen } from "../../store/slices/clientsListSlice";
import CommonModal from "../Common/CustomModal";
import Filter from "./Filter";
import PCClientsTable from "./PCClientsTable";
import {
  downloadReportLCClients,
  downloadReportPCClients,
} from "../../store/actions/clientsListActions";
import useSearch from "../../hooks/useSearch";

type Props = {};

const ClientsList = (props: Props) => {
  const { LCClientsList, PCClientsList, openModal } = useAppSelector(
    (state) => state.clientsList
  );
  const [searchValue, setSearchValue] = useState("");
  const [finalValue, setFinalValue] = useState<string>("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  useEffect(() => {
    const delay = 500; // Adjust the delay as needed (e.g., 500 milliseconds)
    const timeoutId = setTimeout(() => {
      setFinalValue(searchValue);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);
  useEffect(() => {
    navigate(
      `${pathname}${UseReplace("search-name", String(finalValue.trim()))}`
    );
  }, [finalValue]);
  const onChange = (value: string | number, name: string) => {
    setSearchValue(String(value));
  };
  let loadingExcelReport = false;

  const [anchorElSelector, setAnchorElSelector] =
    React.useState<null | HTMLElement>(null);
  const openSelector = Boolean(anchorElSelector);
  const dispatch = useAppDispatch();
  const query = useSearch();

  const downloadExcel = () => {
    loadingExcelReport = true;

    dispatch(
      (window.location.pathname === "/clients-list-pc"
        ? downloadReportPCClients
        : downloadReportLCClients)(query)
    )
      .then(() => {
        loadingExcelReport = false;
      })
      .catch((err) => {
        loadingExcelReport = false;
      });
  };
  return (
    <div>
      <Stack gap="14px" width="100%">
        <FlexBetween width="100%">
          <Text fw="600" fs="24px" style={{ marginBottom: "15px" }}>
            {window.location.pathname === "/clients-list-pc"
              ? "Общая база ЧЛ"
              : "Общая база ЮР"}
          </Text>
        </FlexBetween>
      </Stack>
      <FlexBetween>
        <Box position={"relative"} width="100%" marginRight={"10px"}>
          <img
            src={searchIcon}
            alt=""
            style={{ position: "absolute", right: "10px", top: "10px" }}
          />

          <CustomInput
            name="search"
            showError={false}
            value={searchValue}
            errorText={t("validations.fill")!}
            onChange={onChange}
            fullWidth
            height="41px"
            placeholder="Введите данные поиска..."
          />
        </Box>
        <CustomButton
          fs="19px"
          value={<SaveIcon />}
          bgColor="#e1e1e1"
          width="50px"
          height="43px"
          onClick={downloadExcel}
        />

        <div style={{ marginLeft: "10px" }}>
          <CustomButton
            bgColor="#e1e1e1"
            value={<img src={filter} alt="" />}
            fs="19px"
            type="button"
            aria-controls={openSelector ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openSelector ? "true" : undefined}
            loading={false}
            height="43px"
            width="50px"
            onClick={() => {
              dispatch(handleOpen("filter"));
              setAnchorElSelector(null);
            }}
          />
        </div>
        <CommonModal
          open={openModal === "filter"}
          handleClose={() => dispatch(handleClose())}
        >
          <Filter handleClose={() => dispatch(handleClose())} />
        </CommonModal>
      </FlexBetween>
      <h3 style={{ marginTop: "1rem" }}>
        Общее количество{" "}
        {window.location.pathname === "/clients-list-pc" ? "ЧЛ" : "ЮР"}{" "}
        клиентов:{" "}
        {window.location.pathname === "/clients-list-pc"
          ? PCClientsList.count
          : LCClientsList.count}
      </h3>
      {window.location.pathname === "/clients-list-pc" ? (
        <PCClientsTable searchValue={finalValue} />
      ) : (
        <LCClientsTable searchValue={finalValue} />
      )}
    </div>
  );
};

export default ClientsList;
