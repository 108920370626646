import { useState } from "react";
import { FlexBetween, FlexWrapper } from "../../Common/FlexWrapper";
import { Checkbox, Chip, Stack } from "@mui/material";
import { Text } from "../../../globalStyle";
import { Accordions, WrapperTable } from "../style";
import filter from "../../../assets/icons/filter.svg";
import { handleClose, handleOpen } from "../../../store/slices/LCContactSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import ContactAction from "./ContactAction";
import MainLoading from "../../MainLoading";
import ToBack from "../../Common/ToBack";
import { useLocation } from "react-router-dom";
import { nameTypes } from "../../PCApplications/data";
import CustomButton from "../../Common/CustomButton";
import CommonModal from "../../Common/CustomModal";
import UpdateOrderManager from "./UpdateOrderManager";
import { PCDataType } from "../../../types/commonTypes";
import moment from "moment";
import { prettierNum } from "../../../utils/helpers";

const SelectedContact = () => {
  const { selectedContact, actionsPending, openModal } = useAppSelector(
    (state) => state.LCContact
  );
  const { actions, client } = selectedContact;
  const [expanded, setExpanded] = useState<number | false>(false);
  const dispatch = useAppDispatch();

  console.log(client);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedIds((prevIds) => [...prevIds, id]);
    } else {
      setSelectedIds((prevIds) =>
        prevIds.filter((selectedId) => selectedId !== id)
      );
    }
  };

  const handleParentCheckboxChange = (isChecked: boolean) => {
    setSelectAll(isChecked);
    if (isChecked) {
      // Select all child checkboxes
      const allIds = actions?.results?.map((item) => item.id) || [];
      setSelectedIds(allIds);
    } else {
      // Deselect all child checkboxes
      setSelectedIds([]);
    }
  };
  const handleChange = (id: number, isExpanded: boolean) =>
    setExpanded(isExpanded ? id : false);
  const location = useLocation();
  moment.locale("ru");
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "max-content max-content 1fr",
        position: "relative",
        height: "calc(100% - 30px)",
      }}
    >
      <ToBack
        to={location.state?.prevPath ? location.state.prevPath : -1}
        top="-50px"
        left="230px"
      />
      <Stack
        p={"18px"}
        bgcolor="#fff"
        borderRadius={"10px"}
        mb={"1rem"}
        boxShadow="0px 4px 6px -3px rgba(0, 0, 0, 0.1)"
      >
        <FlexBetween alignItems="center" mb="10px" mt="10px">
          <div>
            <Text fs="26px" fw="500">
              {client?.company_name}
            </Text>
            <Text fs="16px" c="#6c6c6c" fw="500">
              {client?.activity?.name}
            </Text>
            <FlexBetween mt="5px">
              <Text fs="16px" c="green" fw="500">
                {client?.manager &&
                  client?.manager.first_name + " " + client?.manager.last_name}
              </Text>
            </FlexBetween>
          </div>

          <Stack gap="15px">
            <Text fs="16px" fw="400" c="#2D9CDB">
              {prettierNum(+String(client?.inn))}
            </Text>
            <Text fs="16px" fw="400" c="green">
              {client?.created_at}
            </Text>
            {/* <Text fs="16px" fw="400" c="#2D9CDB">
              {nameTypes.find((type) => type.value === name_type)?.name}
            </Text> */}
          </Stack>
          <Stack alignItems={"flex-end"} gap="7px">
            <img
              src={filter}
              alt=""
              onClick={() => dispatch(handleOpen("company-filter"))}
              style={{ cursor: "pointer" }}
            />
          </Stack>
        </FlexBetween>
        <h4 style={{ margin: "10px 0" }}>{client?.note}</h4>
        <FlexWrapper
          gap="10px"
          flexWrap="wrap"
          alignItems="center"
          // mb="10px"
          mt="10px"
        >
          {client?.statuses?.map((item) => (
            <Chip label={item[0]} />
          ))}
        </FlexWrapper>
        <FlexWrapper
          gap="10px"
          flexWrap="wrap"
          alignItems="center"
          // mb="10px"
          mt="10px"
        >
          {client?.brand?.map((item) => (
            <Chip color="success" variant="outlined" label={item} />
          ))}
        </FlexWrapper>
        {client?.contacts && (
          <WrapperTable>
            <table>
              <thead>
                <tr>
                  <th>Контактное лицо</th>
                  <th>Должность</th>
                  <th>Телеграм</th>
                  <th>Телефон</th>
                </tr>
              </thead>
              <tbody>
                {client?.contacts.map((client: any, idx: number) => (
                  <tr key={idx}>
                    <td>{client?.full_name}</td>
                    <td>{client?.job_title}</td>
                    <td>{client?.telegram}</td>
                    <td>{client?.phone_number}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </WrapperTable>
        )}
      </Stack>

      {actionsPending === "contact-actions" ? (
        <MainLoading />
      ) : (
        actions && (
          <Accordions>
            <Stack gap="10px">
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #00000060"
                width="100%"
                paddingBottom="10px"
                position="sticky"
                top="0"
                zIndex={2}
                sx={{ background: "#f2f2f2" }}
              >
                <FlexBetween>
                  <Checkbox
                    checked={selectAll}
                    onChange={(e) =>
                      handleParentCheckboxChange(e.target.checked)
                    }
                  />
                  <div>Выбрать все</div>
                </FlexBetween>
                <FlexBetween>
                  <CustomButton
                    value="Изменить ответственного менеджера"
                    fs="18px"
                    color="#fff"
                    fw="700"
                    type="button"
                    loading={false}
                    padding="12px 15px"
                    width="fit-content"
                    height="41px"
                    bgColor="var(--primary)"
                    disable={selectedIds.length === 0}
                    onClick={() => dispatch(handleOpen("change-contact"))}
                  />
                </FlexBetween>
              </Stack>
              {actions?.results?.map((item) => (
                <ContactAction
                  expanded={expanded}
                  item={item}
                  key={item.id}
                  handleChange={handleChange}
                  selectedIds={selectedIds}
                  handleCheckboxChange={handleCheckboxChange}
                />
              ))}
            </Stack>
          </Accordions>
        )
      )}
      <CommonModal
        open={openModal === "change-contact"}
        handleClose={() => dispatch(handleClose())}
      >
        <UpdateOrderManager
          selectedOrders={selectedIds}
          handleClose={() => dispatch(handleClose())}
        />
      </CommonModal>
    </div>
  );
};

export default SelectedContact;
