export const defaultListItem = {
  work: null,
  job: null,
  price: "",
  price2: "",
  sum: "",
  number: "",
  total_price: "",
};

export const defaultPCClientData = {
  company_name: "",
  full_name: "",
  job_title: "",
  note:"",
  interests: null,
  telegram: "",
  manager: null,
  person_contacts: [{ phone_number: "" }],
};

export const nameTypes = [
  { name: "ООО", value: "ooo", id: 1 },
  { name: "ЧП", value: "chp", id: 2 },
  { name: "ИП", value: "ip", id: 3 },
  { name: "СП", value: "sp", id: 4 },
  { name: "ННО", value: "nno", id: 5 },
  { name: "АО", value: "ao", id: 6 },
  { name: "ЧЛ", value: "chl", id: 7 },
  { name: "Гос", value: "гос", id: 8 },
  { name: "Ин.пр.", value: "ин_пр", id: 9 },
];
