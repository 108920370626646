import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getLegalStatusFinishedStatistics,
  getManagerStatistics,
  getPhysicalOrdersStatistics,
  getPhysicalInterestsStatistics,
  getLegalOrdersStatistics,
  getLegalPartnersStatistics,
  getManagerActivities,
} from "../actions/statisticsActions";

export type workT = {
  work__name: string;
  count: number;
};
export type jobT = {
  job__name: string;
  count: number;
};

export type jobWorkT = {
  works: workT[];
  jobs: jobT[];
};

const initialState = {
  pending: false,
  legalStatusFinishedStatistics: [] as { title: string; value: number }[],
  legalPartnersStatistics: [] as { title: string; value: number }[],
  managerStatistics: [] as { title: string; value: number }[],
  managerActivities: [] as { title: string; total_activity: number }[],
  physicalOrdersStatistics: [] as { title: string; value: number }[],
  legalOrdersStatistics: [] as { title: string; value: number }[],
  physicalInterestsStatistics: [] as { title: string; value: number }[],
  types: { jobs: [], works: [] } as jobWorkT,
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: {
    [(getLegalPartnersStatistics as any).pending]: (state) => {
      state.pending = true;
    },
    [(getLegalPartnersStatistics as any).fulfilled]: (state, action) => {
      state.pending = false;
      // const data = [];
      // for (let key in action.payload) {
      //   if (key !== "total") data.push(action.payload[key]);
      // }
      state.legalPartnersStatistics = action.payload;
    },
    [(getLegalStatusFinishedStatistics as any).pending]: (state) => {
      state.pending = true;
    },
    [(getLegalStatusFinishedStatistics as any).fulfilled]: (state, action) => {
      state.pending = false;
      // const data = [];
      // for (let key in action.payload) {
      //   if (key !== "total") data.push(action.payload[key]);
      // }
      state.legalStatusFinishedStatistics = action.payload;
    },
    [(getManagerStatistics as any).pending]: (state) => {
      state.pending = true;
    },
    [(getManagerStatistics as any).fulfilled]: (state, action) => {
      state.pending = false;
      // const data = [];
      // for (let key in action.payload) {
      //   if (key !== "total") data.push(action.payload[key]);
      // }
      state.managerStatistics = action.payload;
    },
    [(getManagerActivities as any).pending]: (state) => {
      state.pending = true;
    },
    [(getManagerActivities as any).fulfilled]: (state, action) => {
      state.pending = false;
      // const data = [];
      // for (let key in action.payload) {
      //   if (key !== "total") data.push(action.payload[key]);
      // }
      state.managerActivities = action.payload;
    },
    [(getPhysicalOrdersStatistics as any).pending]: (state) => {
      state.pending = true;
    },
    [(getPhysicalOrdersStatistics as any).fulfilled]: (state, action) => {
      state.pending = false;
      // const data = [];
      // for (let key in action.payload) {
      //   if (key !== "total") data.push(action.payload[key]);
      // }
      state.physicalOrdersStatistics = action.payload;
    },
    [(getLegalOrdersStatistics as any).pending]: (state) => {
      state.pending = true;
    },
    [(getLegalOrdersStatistics as any).fulfilled]: (state, action) => {
      state.pending = false;
      // const data = [];
      // for (let key in action.payload) {
      //   if (key !== "total") data.push(action.payload[key]);
      // }
      state.legalOrdersStatistics = action.payload;
    },
    [(getPhysicalInterestsStatistics as any).pending]: (state) => {
      state.pending = true;
    },
    [(getPhysicalInterestsStatistics as any).fulfilled]: (state, action) => {
      state.pending = false;
      // const data = [];
      // for (let key in action.payload) {
      //   if (key !== "total") data.push(action.payload[key]);
      // }
      state.physicalInterestsStatistics = action.payload;
    },
  },
});

// export const { } = statisticsSlice.actions;
export default statisticsSlice.reducer;
