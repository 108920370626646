import { Checkbox, Divider, Stack } from "@mui/material";
import React from "react";
import { FlexBetween } from "../../Common/FlexWrapper";
import { Text } from "../../../globalStyle";
import UseReplace from "../../../hooks/useReplace";
import { useNavigate } from "react-router-dom";
import { setSelectedItems } from "../../../store/slices/PCContactSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { CheckboxIcon, CheckedIcon } from "../style";
import { PCDataType, dataType } from "../../../types/commonTypes";

const Card: React.FC<{ data: PCDataType }> = ({ data }) => {
  const {
    id,
    company_name,
    telegram,
    created_at,
    person_contacts,
    manager,
    full_name,
  } = data;
  const { selectedItems } = useAppSelector((state) => state.PCContact);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Stack
      width={"277px"}
      p="10px"
      bgcolor={"#FCFCFC"}
      boxShadow="0px 4px 6px -2px rgba(0, 0, 0, 0.1)"
      borderRadius={"3px"}
      position="relative"
    >
      <Checkbox
        sx={{ position: "absolute", top: "0", right: "0" }}
        checked={selectedItems.includes(id!)}
        icon={<CheckboxIcon />}
        onClick={() => dispatch(setSelectedItems(id!))}
        checkedIcon={
          <CheckboxIcon>
            <CheckedIcon />
          </CheckboxIcon>
        }
      />

      <Stack height="100%" width="100%" gap="15px" mb={"8px"}>
        <Text
          fs="14px"
          fw="500"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/contact-pc${UseReplace("id", String(id))}`)}
        >
          {full_name}
        </Text>
        <Text
          fs="13px"
          fw="500"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/contact-pc${UseReplace("id", String(id))}`)}
        >
          {company_name}
        </Text>

        <FlexBetween height={"100%"}>
          {/* {total_orders !== 0 && (
            <Text fs="10px" fw="400" c="#828282">
              {total_orders}
            </Text>
          )} */}
          <Text fs="10px" fw="400">
            {person_contacts && person_contacts[0]?.phone_number}
          </Text>
          <Text fs="10px" fw="400" c="#2D9CDB">
            {telegram}
          </Text>
        </FlexBetween>
        <Text
          fs="13px"
          fw="500"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/contact-pc${UseReplace("id", String(id))}`)}
        >
          {manager?.first_name + " " + manager?.last_name}
        </Text>
      </Stack>

      <Divider />

      <FlexBetween mt={"5px"}>
        {/* <Text fs="10px" fw="400">
          {person_contact}
        </Text> */}
        <Text fs="10px" fw="400" c="#828282">
          {created_at}
        </Text>
      </FlexBetween>
    </Stack>
  );
};

export default Card;
