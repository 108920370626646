import { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import Header from "./components/Header";
import Card from "./components/Card";
import { getManagersThunk } from "../../store/actions/mainActions";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { getTypes } from "../../store/actions/settingsActions";
import { getPCContact } from "../../store/actions/PCContactActions";
import useSearch from "../../hooks/useSearch";
import { Title } from "@mui/icons-material";

const Reports = () => {
  const data = {
    lcReports: [
      {
        title: "Клиентская база ЮР",
        url: "excels/legal/client/contact/excel/",
      },
      {
        title: "Общий отчёт по ЮР заявкам и менеджерам",
        url: "excels/legal/manager/by/order/excel/",
      },
      {
        title: "Общий отчёт по ЮР статусам ",
        url: "excels/legal/order/statuses/excel/",
      },
      {
        title: "Общий отчёт по CRM(ЮР)  ",
        url: "excels/legal/survey/excel/",
      },
      {
        title: "Успешно реализованные ЮР контракты",
        url: "excels/legal/successfully/finished/orders/excel/",
      },
    ],
    pcReports: [
      {
        title: "Общий отчёт по ЧЛ заявкам и менеджерам",
        url: "excels/physical/manager/by/order/excel/",
      },
      {
        title: "Общий отчёт по ЧЛ статусам ",
        url: "excels/physical/order/statuses/excel/",
      },
      {
        title: "Общий отчёт по CRM(ЧЛ)  ",
        url: "excels/physical/survey/excel/",
      },
    ],
  };

  const dispatch = useAppDispatch();
  const query = useSearch();

  useEffect(() => {
    dispatch(getPCContact(query));
    dispatch(getManagersThunk("")); 
    dispatch(getTypes({ url: "legal-settings", dataType: "lsStatuses" }));
    dispatch(getTypes({ url: "physical-settings", dataType: "pcStatuses" }));
  }, [dispatch]);

  return (
    <Stack gap="14px">
      <Header />
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        columnGap="24px"
        rowGap="17px"
        mt="20px"
      >
        <Stack gap="20px">
          <h3>ЮР Отчёты</h3>
          {data.lcReports.map((item) => (
            <Card key={item.url} item={item} isLegal />
          ))}
        </Stack>
        <Stack gap="20px">
          <h3>ЧЛ Отчёты</h3>
          {data.pcReports.map((item) => (
            <Card key={item.url} item={item} />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

export default Reports;
