import styled from "styled-components";
export const WrapperClientsTable = styled.div``;
export const WrapperTable = styled.div`
  margin-top: 2rem;
  height: calc(100vh - 280px);
  overflow: auto;

  thead tr th {
    position: sticky;
    top: 0;
  }

  /* If we use border,
we must use table-collapse to avoid
a slight movement of the header row */
  table {
    border-collapse: collapse;
  }

  /* Because we must set sticky on th,
 we have to apply background styles here
 rather than on thead */
  th {
    padding: 5px;
    padding-left: 15px;
    border-left: 1px dotted rgba(200, 209, 224, 0.6);
    border-bottom: 1px solid #e8e8e8;
    background: #ffc491;
    text-align: left;
    /* With border-collapse, we must use box-shadow or psuedo elements
  for the header borders */
    box-shadow: 0px 0px 0 2px #e8e8e8;
  }

  /* Basic Demo styling */
  table {
    width: 100%;
    font-family: sans-serif;
  }
  table td {
    padding: 16px;
  }
  table th div {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-right: 16px;
    }
  }
  tbody tr {
    border-bottom: 2px solid #e8e8e8;
  }
  thead {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
  tbody tr:hover {
    background: #e6f7ff;
  }
`;
export const PaginationTable = styled.div`
  display: flex;
  justify-content: end;
  position: sticky;
  bottom: 0;
  align-items: center;
  margin-top: 20px;

  button {
    border: none;
    background-color: #f5f5f5;
    color: #333;
    padding: 8px 16px;
    cursor: pointer;
  }

  button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  span {
    background-color: #ea7d0e;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 10px;
    font-weight: bold;
  }
`;
export const ItemsPerPage = styled.div`
  input {
    border: 1px solid #ea7d0e;
    width: 100%;
    padding: 4px;
    border-radius: 7px;
    background: "#f9f9f9";
    margin-top: 5px;
  }
`;
