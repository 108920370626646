import { t } from "i18next";
import { useDrop } from "react-dnd";
import {
  handleOpen,
  setPCAppsByStepPending,
  setCancelReason,
  setEditingApp,
  setFillPricingData,
} from "../../../store/slices/PCApplicationsSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { getTypes } from "../../../store/actions/settingsActions";
import CustomButton from "../../Common/CustomButton";
import { Stack } from "@mui/material";
import {
  appT,
  PCAppsDataT,
  PCOnDropT,
  showMoreT,
  statusT,
} from "../../../types/commonTypes";
import { getPCApplicationsByStep } from "../../../store/actions/PCApplicationsActions";
import MainLoading from "../../MainLoading";
import { useLocation } from "react-router-dom";
import useSearch from "../../../hooks/useSearch";

type T = {
  onDrop: (data: PCOnDropT) => void;
  children: any;
  step: string;
  setShowMore: (arg: showMoreT[]) => void;
  showMore: showMoreT[];
};

// const checkPrice = (list: appT[]) => {
//   let isFilled = false;
//   list.forEach((item) => {
//     if (+item?.price !== 0) {
//       isFilled = true;
//       return;
//     }
//   });

//   return isFilled;
// };
const checkPrice = (
  list: appT[],
  designers: { id: number; name: string }[],
  designer_price: number | string
) => {
  let isFilled = true;
  console.log(list, designer_price, "checkpriceLog");

  list.forEach((item) => {
    if (
      designers.length > 0
        ? +designer_price === 0 ||
          +item.price2 - +designer_price <= 0 ||
          ((+item?.price === 0 ||
            isNaN(+item?.price) ||
            item?.price === "Infinity") &&
            (+item?.total_price === 0 ||
              isNaN(+item?.total_price) ||
              item?.total_price === "Infinity") &&
            (!item?.number ||
              +item?.number === 0 ||
              isNaN(+item?.number) ||
              item?.number === "Infinity"))
        : (+item?.price === 0 ||
            isNaN(+item?.price) ||
            item?.price === "Infinity") &&
          (+item?.total_price === 0 ||
            isNaN(+item?.total_price) ||
            item?.total_price === "Infinity") &&
          (!item?.number ||
            +item?.number === 0 ||
            isNaN(+item?.number) ||
            item?.number === "Infinity")
    ) {
      isFilled = false;
      return;
    }
  });

  return isFilled;
};
const DropWrapper = ({ children, step, onDrop, setShowMore, showMore }: T) => {
  const { profile } = useAppSelector((state) => state.settings);
  const { applicationsByStepPending } = useAppSelector(
    (state) => state.PCApplications
  );
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const query = useSearch();
  // const [{ isOver }, drop] = useDrop({
  const [, drop] = useDrop({
    accept: "app",
    canDrop: (item: PCAppsDataT, monitor) => {
      const items = t("PCApplications.applicationsTypes", {
        returnObjects: true,
      }) as statusT[];

      const itemIndex = items.findIndex((si) => si.step === item.step);
      const statusIndex = items.findIndex((si) => si.step === step);

      if ((itemIndex === 6 && statusIndex === 6) || itemIndex === statusIndex)
        return false;
      else if (
        profile.role === "community_manager" &&
        statusIndex > itemIndex &&
        statusIndex === 5
      )
        return true;
      else if (
        profile.role === "director" ||
        profile.role === "executive_director" ||
        profile.role === "head_community_department"
      )
        return true;
      else return [itemIndex + 1, 6].includes(statusIndex);
    },
    drop: (item, monitor) => {
      console.log(item, step, "iwanttosee");
      if (
        ["paying", "implementation", "finish"].includes(step) &&
        (!item.status_list || item.status_list.length === 0)
      ) {
        dispatch(setFillPricingData({ item, monitor, step: step }));
        dispatch(setEditingApp(item));
        dispatch(handleOpen("setPricing"));
      } else if (step === "cancelled") {
        dispatch(setCancelReason({ item, monitor, step }));
        dispatch(handleOpen("cancel-alert"));
        dispatch(getTypes({ url: "reasons", dataType: "cancel" }));
      } else onDrop({ item, monitor, step });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const colItem = showMore.find((item) => item.step === step)!;
  console.log(colItem, step, showMore, "stepcheckerror");

  return (
    <>
      <div
        ref={drop}
        style={{ minHeight: "180px", height: "100%", transition: "1s" }}
      >
        <div>{children}</div>

        {applicationsByStepPending.includes(step) ? (
          <MainLoading />
        ) : (
          // !search &&
          showMore.map((item) => item.step).includes(step) &&
          colItem?.maxPage > colItem?.page && (
            <Stack alignItems={"center"} mt="10px">
              <CustomButton
                bgColor="var(--primary)"
                fs="17px"
                value={"Показать ещё"}
                color="#fff"
                type="button"
                width="170px"
                height="32px"
                padding="0"
                fw="500"
                onClick={() => {
                  dispatch(setPCAppsByStepPending(colItem.step));
                  dispatch(
                    getPCApplicationsByStep({
                      api: step,
                      query,
                      page:
                        (showMore.find((item) => item.step === step)?.page ??
                          1) + 1,
                    })
                  );

                  setShowMore(
                    showMore.map((item) => {
                      if (item.step === step)
                        return { ...item, page: item.page + 1 };
                      else return item;
                    })
                  );
                }}
                // onClick={() =>
                //   showMore.includes(step)
                //     ? setShowMore(showMore.filter((item) => item !== step))
                //     : setShowMore([...showMore, step])
                // }
              />
            </Stack>
          )
        )}
      </div>
    </>
  );
};

export default DropWrapper;
