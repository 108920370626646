import { Add } from "@mui/icons-material";
import { Box, Pagination, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Text } from "../../globalStyle";
import CustomButton from "../Common/CustomButton";
import CustomInput from "../Common/CustomInput";
import { FlexBetween } from "../Common/FlexWrapper";
import Card from "./components/Card";
import Filter from "./components/Filter";
import filter from "../../assets/icons/filter.svg";
import filter2 from "../../assets/icons/settings/contact/filter.svg";
import trash from "../../assets/icons/settings/dark-trash.svg";
import edit from "../../assets/icons/settings/dark-edit.svg";
import searchIcon from "../../assets/icons/search.svg";
import CommonModal from "../Common/CustomModal";

import useSearch from "../../hooks/useSearch";
import SelectedContact from "./components/SelectedContact";
import Popover from "./components/Popover";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  emptyCheckedItems,
  handleClose,
  handleOpen,
  setEditingItem,
  setIndicator,
} from "../../store/slices/PCContactSlice";
import AddContact from "./components/AddContact";
import {
  deletePCContactThunk,
  getPCContact,
  getPCContactActions,
  getPCContactById,
} from "../../store/actions/PCContactActions";
import { getTypes } from "../../store/actions/settingsActions";
import MainLoading from "../MainLoading";
import Loading from "../Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UseReplace from "../../hooks/useReplace";
import { PCDataType, dataType } from "../../types/commonTypes";
import { getEmployeesList } from "../../store/actions/employeesActions";
import RemoveContactModal from "./RemoveContactModal";

const Contact = () => {
  const {
    openModal,
    data,
    pending,
    actionsPending,
    indicator,
    selectedItems,
    selectedContact,
  } = useAppSelector((state) => state.PCContact);
  const query = useSearch();
  const { profile } = useAppSelector((state) => state.settings);
  const [searchValue, setSearchValue] = useState(
    query.get("search-name") || ""
  );
  const [finalValue, setFinalValue] = useState<string>("");

  const [removedContacts, setRemovedContacts] = useState<any[]>();
  const { data: employees } = useAppSelector((state) => state.employees);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    const delay = 500; // Adjust the delay as needed (e.g., 500 milliseconds)
    const timeoutId = setTimeout(() => {
      setFinalValue(searchValue);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);
  useEffect(() => {
    navigate(
      `${pathname}${UseReplace("search-name", String(finalValue.trim()))}`
    );
  }, [finalValue]);
  const onChange = (value: string | number, name: string) => {
    setSearchValue(String(value));
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  useEffect(() => {
    dispatch(getPCContact(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    query.get("ordering"),
    query.get("page"),
    query.get("search-name"),
  ]);

  useEffect(() => {
    dispatch(getTypes({ url: "activity", dataType: "clientTypes" }));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getEmployeesList({}));
  }, [dispatch]);
  const { search } = useLocation();
  console.log(search);

  useEffect(() => {
    if (pathname === "/contact-pc" && !search && !query.get("search-name"))
      dispatch(getPCContact(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch]);

  useEffect(() => {
    if (query.get("id")) {
      dispatch(getPCContactById(query.get("id")!));
      dispatch(getPCContactActions(query.get("id")!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get("id")]);
  const handleRemoveContact = () => {
    dispatch(deletePCContactThunk());
  };
  console.log(selectedContact.client, query.get("id"));

  return (
    <Stack position="relative" height="100%" spacing="14px">
      <FlexBetween>
        <Text c="#6C6C6C" fs="24px" fw="600">
          ЧЛ Карточка
        </Text>
        <Stack direction={"row"} gap="20px">
          {profile?.role === "director" && (
            <CustomButton
              bgColor="#fff"
              fs="18px"
              value={
                actionsPending === "delete" ? (
                  <Loading color="dark" />
                ) : (
                  <img src={trash} alt="" />
                )
              }
              width="fit-content"
              padding="12px"
              fw="600"
              height="41px"
              shadow
              onClick={() => dispatch(handleOpen("remove-contact"))}
              disable={!indicator.includes("delete")}
            />
          )}
          <CustomButton
            bgColor="#fff"
            fs="18px"
            value={<img src={edit} alt="" />}
            width="fit-content"
            padding="12px"
            fw="600"
            height="41px"
            shadow
            onClick={() => {
              dispatch(handleOpen("add-contact"));
              // find selected one item
              const item = data.results.find(
                (item: PCDataType) => selectedItems[0] === item.id
              );
              dispatch(setEditingItem({ ...item! }));
            }}
            disable={!indicator.includes("edit")}
          />
          <CustomButton
            color="#fff"
            bgColor="var(--primary)"
            value={
              <FlexBetween gap="19px">
                {t("contact.add")} <Add />
              </FlexBetween>
            }
            fs="18px"
            fw="700"
            type="button"
            loading={false}
            padding="12px 15px"
            width="fit-content"
            height="41px"
            onClick={() => {
              dispatch(handleOpen("add-contact"));
              dispatch(emptyCheckedItems());
              dispatch(setIndicator(""));
            }}
          />
        </Stack>
      </FlexBetween>

      {selectedContact && query.get("id") ? (
        actionsPending === "contact-actions" ? (
          <MainLoading />
        ) : (
          <SelectedContact />
        )
      ) : (
        <>
          <Stack gap="11px" direction={"row"}>
            <Box position={"relative"} width="100%">
              <img
                src={searchIcon}
                alt=""
                style={{ position: "absolute", right: "10px", top: "10px" }}
              />

              <CustomInput
                name="search"
                showError={false}
                value={searchValue}
                errorText={t("validations.fill")!}
                onChange={onChange}
                fullWidth
                height="41px"
                placeholder="Введите наименование клиента…"
              />
            </Box>
            <CustomButton
              bgColor="#fff"
              value={<img src={filter2} alt="" />}
              fs="18px"
              type="button"
              loading={false}
              border="1px solid #e0e0e0"
              width="45px"
              height="41px"
              onClick={(e: any) => handleClick(e)}
            />
            <Popover anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

            <CustomButton
              bgColor="#fff"
              value={<img src={filter} alt="" />}
              fs="18px"
              type="button"
              loading={false}
              border="1px solid #e0e0e0"
              width="45px"
              height="41px"
              onClick={() => dispatch(handleOpen("filter"))}
            />
          </Stack>
          <h3 style={{ marginTop: "1rem" }}>
            Общее количество ЧЛ карточки: {data.count}
          </h3>
          <Pagination
            sx={{
              position: "absolute",
              bottom: "15px",
              right: "15px",
            }}
            count={Math.floor(data?.count / 10) + (data?.count % 10 && 1) || 1}
            page={Number(query.get("page")) || 1}
            onChange={(e, value) => {
              navigate(`${pathname}${UseReplace("page", String(value))}`);
            }}
            color="primary"
          />

          {pending ? (
            <MainLoading />
          ) : (
            <Stack direction={"row"} flexWrap="wrap" gap="10px">
              {data?.results?.map((item, index) => (
                <Card key={index} data={item} />
              ))}
            </Stack>
          )}
        </>
      )}

      {/* modal */}
      <CommonModal
        open={openModal === "filter"}
        handleClose={() => dispatch(handleClose())}
      >
        <Filter handleClose={() => dispatch(handleClose())} />
      </CommonModal>

      <CommonModal
        open={openModal === "company-filter"}
        handleClose={() => dispatch(handleClose())}
      >
        <Filter handleClose={() => dispatch(handleClose())} isCompany />
      </CommonModal>

      <CommonModal
        open={openModal === "add-contact"}
        handleClose={() => dispatch(handleClose())}
      >
        <AddContact
          setRemovedContacts={setRemovedContacts}
          handleClose={() => dispatch(handleClose())}
        />
      </CommonModal>
      <CommonModal
        open={openModal === "remove-contact"}
        handleClose={() => dispatch(handleClose())}
      >
        <RemoveContactModal
          isOpen={openModal === "remove-contact"}
          onClose={() => dispatch(handleClose())}
          onRemove={handleRemoveContact}
        />
      </CommonModal>
    </Stack>
  );
};

export default Contact;
