import { configureStore } from "@reduxjs/toolkit";
import PCApplicationsSlice from "./slices/PCApplicationsSlice";
import PCContactSlice from "./slices/PCContactSlice";
import employeesSlice from "./slices/employeesSlice";
import mainSlice from "./slices/mainSlice";
import settingsSlice from "./slices/settingsSlice";
import processSlice from "./slices/processSlice";
import statisticsSlice from "./slices/statisticsSlice";
import LtvSlice from "./slices/clientsListSlice";
import LCContactSlice from "./slices/LCContactSlice";
import LCApplicationsSlice from "./slices/LCApplicationsSlice";
import monitoringSlice from "./slices/monitoringSlice";

const store = configureStore({
  reducer: {
    main: mainSlice,
    monitoring: monitoringSlice,
    settings: settingsSlice,
    PCContact: PCContactSlice,
    LCContact: LCContactSlice,
    employees: employeesSlice,
    PCApplications: PCApplicationsSlice,
    LCApplications: LCApplicationsSlice,
    process: processSlice,
    statistics: statisticsSlice,
    clientsList: LtvSlice,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
