import { Routes, Route, Navigate } from "react-router-dom";
import Snackbar from "./components/Common/Snackbar";
import { useAppSelector } from "./hooks/reduxHooks";
import Login from "./pages/LoginPage";
import NotFound from "./pages/NotFound";
import { routes } from "./routes/routes";
import Layout from "./components/Layout";
import SecurityPage from "./pages/SecurityPage";

function App() {
  const { snackbar } = useAppSelector((state) => state.main);

  return (
    <>
      <Snackbar
        message={snackbar.message}
        open={snackbar.open}
        status={snackbar.status as "success" | "error"}
      />
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/"
          element={
            <SecurityPage>
              <Layout />
            </SecurityPage>
          }
        >
          {localStorage.getItem("siymoToken") ? (
            routes.map(({ path, component, permission }) =>
              permission ? (
                permission.includes(
                  String(localStorage.getItem("siymoRole"))
                ) ? (
                  <Route path={path} key={path} element={component} />
                ) : (
                  <Route path={path} key={path} element={<Navigate to="/" />} />
                )
              ) : (
                <Route path={path} key={path} element={component} />
              )
            )
          ) : (
            <Route
              path="/*"
              key="navigate"
              element={<Navigate to="/login" />}
            />
          )}
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
