import { createAsyncThunk } from "@reduxjs/toolkit";
import i18next from "i18next";
import { httpRequest } from "../../utils/request";
import { openSnackbar } from "../slices/mainSlice";
import {
  LCDataType,
  dataType,
  postDataType,
  postLCDataType,
  selectT,
} from "../../types/commonTypes";
import { nameTypes } from "../../components/PCApplications/data";

export const getLCContact = createAsyncThunk(
  "LCContact/getLCContact",
  async (query: any, thunkAPI): Promise<LCDataType[]> => {
    const ordering = query?.get("ordering");
    const searchName =
      query?.get("search-client-name") ||
      query?.get("search-recommended-name") ||
      query?.get("search-recommended-client-name");
    // const name = query?.get("search-name");
    const activity = query?.get("activity");
    const manager = query?.get("manager");
    const from = query?.get("from");
    const to = query?.get("to");
    const page = query?.get("page");
    const status = JSON.parse(query?.get("status") || "[]")?.map(
      (item: any) => item?.id
    );
    const interest = JSON.parse(query?.get("interest") || "[]")?.map(
      (item: any) => item?.id
    );
    return await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/list/?${ordering ? `ordering=${ordering}` : ""}${
          activity ? `&activity=${activity}` : ""
        }${manager ? `&manager=${manager}` : ""}${
          from ? `&date_from=${from}` : ``
        }${to ? `&date_to=${to}` : ``}${
          searchName ? `&name=${searchName}` : ``
        }${page ? `&page=${page}` : ``}${
          status.length ? `&status=${status}` : ``
        }${interest.length ? `&interest=${interest}` : ``}`,
      })
    );
  }
);
export const getLCContactForCreate = createAsyncThunk(
  "LCContact/getLCContactForCreate",
  async (name: any, thunkAPI): Promise<LCDataType[]> => {
    return await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/list/?${name ? `name=${name}` : ``}`,
      })
    );
  }
);
export const getLCContactById = createAsyncThunk(
  "LCContact/getLCContactById",
  async (id: string, thunkAPI) =>
    await thunkAPI.dispatch(httpRequest({ url: `legal-client/detail/${id}/` }))
);

export const getLCContactActions = createAsyncThunk(
  "LCContact/getLCContactActions",
  async (id: string, thunkAPI) =>
    await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/order/filter/client/${id}/`,
      })
    )
);

export const createLCContactThunk = createAsyncThunk(
  "LCContact/createLCContactThunk",
  async (data: postLCDataType, thunkAPI) => {
    const order = {
      company_name: data.company_name,
      inn: data.inn,
      note: data.note,
      interests: (data.interests as selectT[])?.map((item) => item?.id),
      activity: data.activity?.id,
      brand: !!data.brand?.filter((item) => item?.trim()).length
        ? data.brand
        : undefined,
      contacts: data.contacts.filter(
        (contact) =>
          (contact.telegram || contact.phone_number) && contact.full_name
      ),
    };

    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/create/`,
        method: "POST",
        body: order,
        // haveImg: true,
      })
    );

    if (res.result.id) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.created"),
        })
      );

      return res.result;
    }
  }
);

export const deleteLCContactThunk = createAsyncThunk(
  "LCContact/deleteLCContactThunk",
  async (_, thunkAPI) => {
    const ids = (thunkAPI.getState() as any).LCContact.selectedItems;
    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/delete/`,
        method: "POST",
        body: { ids },
        noJson: true,
      })
    );

    if (res.status === 204 || res.status === 200) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.deleted"),
        })
      );
      return { status: "success" };
    }
  }
);

export const editLCContactThunk = createAsyncThunk(
  "LCContact/editLCContactThunk",
  async (data: LCDataType, thunkAPI) => {
    const order = {
      company_name: data.company_name,
      inn: data.inn,
      note: data.note,
      activity: data.activity?.id,
      manager: data.manager?.id,
      interests: data.interests?.map((item) => item.id),
      brand: !!data.brand?.filter((item) => item?.trim()).length
        ? data.brand
        : undefined,
      contacts: data.contacts.filter(
        (contact) =>
          (contact.telegram || contact.phone_number) &&
          contact.full_name &&
          contact.job_title
      ),
    };
    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/update/${data.id}/`,
        method: "PUT",
        body: order,
        // haveImg: true,
      })
    );

    if (res.data.id) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.updated"),
        })
      );
      return res.data;
    }
  }
);
