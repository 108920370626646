import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getLCClientsList,
  getPCClientsList,
} from "../actions/clientsListActions";
import { getMonitoringAppList, getMonitoringClientList } from "../actions/monitoringActions";

const initialState = {
  pending: false,
  cannotEditApp: false,
  openModal: "",
  clientsList: [] as any,
  applicationsList: [] as any,
};

export const monitoringSlice = createSlice({
  name: "monitoring",
  initialState,
  reducers: {
    handleOpen: (state, action: PayloadAction<string>) => {
      state.openModal = action.payload;
    },
    handleClose: (state) => {
      state.openModal = "";
      state.cannotEditApp = false;
    },
  },
  extraReducers: {
    [(getMonitoringClientList as any).pending]: (state) => {
      state.pending = true;
    },
    [(getMonitoringClientList as any).fulfilled]: (state, action) => {
      state.pending = false;
      state.clientsList = action.payload as any;
    },
    [(getMonitoringAppList as any).pending]: (state) => {
      state.pending = true;
    },
    [(getMonitoringAppList as any).fulfilled]: (state, action) => {
      state.pending = false;
      state.applicationsList = action.payload as any;
    },
  },
});

export const { handleOpen, handleClose } = monitoringSlice.actions;
export default monitoringSlice.reducer;
