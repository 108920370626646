import React, { useEffect, useState } from "react";
import { Box, Menu, MenuItem, Stack } from "@mui/material";
import { Text } from "../../../../globalStyle";
import { FlexBetween, FlexWrapper } from "../../../Common/FlexWrapper";
import searchIcon from "../../../../assets/icons/search.svg";
import filter from "../../../../assets/icons/filter.svg";
import filter2 from "../../../../assets/icons/settings/contact/filter.svg";
import { Add, NotificationsNone } from "@mui/icons-material";
import CommonModal from "../../../Common/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import {
  handleClose,
  handleOpen,
  setPCAppsByStepPending,
  setAppsData,
  setCancelReason,
} from "../../../../store/slices/PCApplicationsSlice";
import CustomButton from "../../../Common/CustomButton";
import CustomInput from "../../../Common/CustomInput";
import Popover from "./Popover";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UseReplace from "../../../../hooks/useReplace";
import Filter from "../Filter";
import { NotificationWrapper } from "./style";
import Loading from "../../../Loading";
import trash from "../../../../assets/icons/settings/dark-trash.svg";
import {
  deletePCApplicationThunk,
  getPCApplicationsByStep,
} from "../../../../store/actions/PCApplicationsActions";
import { PCAppsDataT, PCStatusT, statusT } from "../../../../types/commonTypes";
import { useDrop } from "react-dnd";
import { getTypes } from "../../../../store/actions/settingsActions";
import { sum } from "../../../../utils/helpers";

const Header = () => {
  const { openModal, notifications, actionsPending, indicator, PCAppsData } =
    useAppSelector((state) => state.PCApplications);
  const { profile, Data } = useAppSelector((state) => state.settings);
  const { pcStatuses } = Data;
  const [searchValue, setSearchValue] = useState("");
  const [finalValue, setFinalValue] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorElSelector, setAnchorElSelector] =
    React.useState<null | HTMLElement>(null);
  const openSelector = Boolean(anchorElSelector);

  const hasPermission = [
    "director",
    "executive_director",
    "head_community_department",
  ];

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const appTypes = pcStatuses.map((item) => {
    return { title: item.name, step: String(item?.id) };
  }) as PCStatusT[];

  console.log(PCAppsData.type,'PCAppdata types');
  useEffect(() => {
    const delay = 500; // Adjust the delay as needed (e.g., 500 milliseconds)
    const timeoutId = setTimeout(() => {
      setFinalValue(searchValue);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);
  useEffect(() => {
    navigate(
      `${pathname}${UseReplace("search-name", String(finalValue.trim()))}`
    );
  }, [finalValue]);
  const onChange = (value: string | number, name: string) => {
    setSearchValue(String(value) as string);
    console.log(value);
    if (value === "") {
      dispatch(setAppsData([]));
      appTypes.forEach((item) => {
        dispatch(setPCAppsByStepPending(item.step));
        dispatch(getPCApplicationsByStep({ api: item.step }));
      });
    }
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "app",
    // drop: onDrop,
    canDrop: (item: PCAppsDataT, monitor) => {
      const itemIndex = item.step_id;
      if (itemIndex === 6) return false;
      else if (
        profile.role === "crm_manager" ||
        profile.role === "account_manager"
      )
        return true;
      else return false;
    },
    drop: (item, monitor) => {
      if (item.step !== "cancelled") {
        dispatch(setCancelReason({ item, monitor, step: "cancelled" }));
        dispatch(handleOpen("cancel-alert"));
        dispatch(getTypes({ url: "reasons", dataType: "cancel" }));
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  console.log(profile.role);

  return (
    <Stack gap="20px" width="100%" bgcolor={"#fff"} p="20px 10px 9px">
      <FlexBetween width="100%">
        <FlexBetween width="max-content" columnGap="10px">
          <Text fw="600" fs="24px">
            {t("PCApplications.header.list")}
          </Text>
          {/* <CustomButton
            bgColor="var(--primary)"
            fs="18px"
            value={
              <FlexBetween gap="41px">
                {t("PCApplications.header.archive")}
              </FlexBetween>
            }
            color="#fff"
            type="button"
            width="150px"
            height="35px"
            padding="7px 15px"
            // disable={profile.role === "crm_manager"}
            fw="500"
            onClick={() => navigate("/applications/archive")}
          /> */}
        </FlexBetween>

        {canDrop && (
          <div
            ref={drop}
            style={{
              alignSelf: "stretch",
              flexGrow: 1,
              margin: "0 20px",
              backgroundColor: "#FFD585",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Не реализованные
          </div>
        )}

        <FlexWrapper gap="13px" alignItems="center">
          {hasPermission.includes(profile.role) && (
            <CustomButton
              bgColor="#fff"
              fs="18px"
              value={
                actionsPending === "delete-process" ? (
                  <Loading color="dark" />
                ) : (
                  <img src={trash} alt="" />
                )
              }
              width="fit-content"
              padding="12px"
              fw="600"
              height="41px"
              shadow
              onClick={() => dispatch(deletePCApplicationThunk())}
              disable={
                !indicator.includes("delete") ||
                actionsPending === "delete-process"
              }
            />
          )}
          {profile.role === "account_manager" && (
            <NotificationWrapper
              onClick={() => dispatch(handleOpen("notification"))}
            >
              {notifications.length > 0 && <div className="badge"></div>}
              <NotificationsNone fontSize="medium" />
            </NotificationWrapper>
          )}
          {[...hasPermission, "community_manager"].includes(profile.role) && (
            <CustomButton
              bgColor="var(--primary)"
              fs="18px"
              value={
                <FlexBetween gap="41px">
                  {t("PCApplications.header.add")}
                  <Add fontSize="large" />
                </FlexBetween>
              }
              color="#fff"
              type="button"
              height="35px"
              padding="7px 15px"
              // disable={profile.role === "crm_manager"}
              fw="500"
              onClick={() => dispatch(handleOpen("add-application"))}
            />
          )}
        </FlexWrapper>
      </FlexBetween>

      <Stack gap="11px" direction={"row"}>
        <Box position={"relative"} width="100%">
          <img
            src={searchIcon}
            alt=""
            style={{ position: "absolute", right: "10px", top: "10px" }}
          />

          <CustomInput
            name="search"
            showError={false}
            value={searchValue}
            onChange={onChange}
            fullWidth
            height="41px"
          />
        </Box>
        <CustomButton
          bgColor="#fff"
          value={<img src={filter2} alt="" />}
          fs="18px"
          type="button"
          loading={false}
          border="1px solid #e0e0e0"
          width="45px"
          height="41px"
          onClick={(e: any) => setAnchorEl(e.currentTarget)}
        />
        <Popover anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        <div>
          <CustomButton
            bgColor="#fff"
            value={<img src={filter} alt="" />}
            fs="18px"
            type="button"
            aria-controls={openSelector ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openSelector ? "true" : undefined}
            loading={false}
            border="1px solid #e0e0e0"
            width="45px"
            height="41px"
            onClick={() => {
              dispatch(handleOpen("filter"));
              setAnchorElSelector(null);
            }}
          />
        </div>
      </Stack>

      <CommonModal
        open={openModal === "filter"}
        handleClose={() => dispatch(handleClose())}
      >
        <Filter handleClose={() => dispatch(handleClose())} />
      </CommonModal>
      <h3 style={{ marginBottom: "1rem" }}>
        Общее количество ЧЛ заявок:{" "}
        {sum(PCAppsData.type.map((item) => item.count))}
      </h3>
    </Stack>
  );
};

export default Header;
