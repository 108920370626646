import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createPCApplicationThunk,
  createPCApplicationWithClientThunk,
  deletePCApplicationThunk,
  editPCApplicationThunk,
  getPCApplications,
  getPCApplicationsArchives,
  getPCNotifications,
} from "../actions/PCApplicationsActions";
import {
  PCAppsDataT,
  LCAppsDataT,
  LCOnDropT,
  notificationT,
  PCOnDropT,
  showMoreT,
} from "../../types/commonTypes";
import {
  createLCApplicationThunk,
  createLCApplicationWithClientThunk,
  deleteLCApplicationThunk,
  editLCApplicationThunk,
  getLCApplications,
  getLCApplicationsByStep,
} from "../actions/LCApplicationsActions";

const initialState = {
  LCAppsData: {
    type: [] as { step: string; count: number }[],
    results: [] as LCAppsDataT[],
  },
  archivedAppData: {},
  openModal: "",
  pending: false,
  applicationsPending: false,
  applicationsByStepPending: [] as string[],
  notificationsPending: false,
  actionsPending: "",
  cancelReason: {} as LCOnDropT,
  fillPricingData: null as LCOnDropT | null,
  notifications: [] as notificationT[],
  notificationId: null as number | null,
  exceptedNotification: null as notificationT | null,
  editingApp: null as LCAppsDataT | null,
  copiedApp: null as LCAppsDataT | null,
  indicator: "",
  selectedItems: [] as number[],
  isChangedAppObject: false,
  cannotEditApp: false,
  showMoreDefault: [] as showMoreT[],
};

export const LCApplicationsSlice = createSlice({
  name: "LCApplications",
  initialState,
  reducers: {
    handleOpen: (state, action: PayloadAction<string>) => {
      state.openModal = action.payload;
    },
    handleClose: (state) => {
      state.openModal = "";
      state.cannotEditApp = false;
      state.fillPricingData = null;
    },
    setAppsData: (state, action: PayloadAction<LCAppsDataT[]>) => {
      state.LCAppsData.results = action.payload;
      state.LCAppsData.type = [];
      state.showMoreDefault = [];
    },
    setCancelReason: (state, action: PayloadAction<LCOnDropT>) => {
      state.cancelReason = action.payload;
    },
    setFillPricingData: (state, action: PayloadAction<LCOnDropT>) => {
      state.fillPricingData = action.payload;
    },
    setNotification: (state, action: PayloadAction<notificationT | null>) => {
      state.exceptedNotification = action.payload;
    },

    setEditingApp: (state, action: PayloadAction<LCAppsDataT | null>) => {
      state.editingApp = action.payload;
      if (
        action.payload?.step === "cancelled" ||
        action.payload?.step === "finish"
      )
        state.cannotEditApp = true;
    },
    setCopiedApp: (state, action: PayloadAction<LCAppsDataT | null>) => {
      state.copiedApp = action.payload;
    },

    setSelectedItems: (state, action: PayloadAction<number>) => {
      const selectedId = action.payload;
      let ids = state.selectedItems;

      if (ids.includes(selectedId)) ids = ids.filter((i) => i !== selectedId);
      else ids.push(selectedId);
      state.selectedItems = ids;

      if (ids.length > 0) {
        if (ids.length > 1) state.indicator = "delete";
        else state.indicator = "delete edit";
      } else state.indicator = "";
    },

    setIsChangedAppObject: (state) => {
      state.isChangedAppObject = false;
    },

    setAppsByStepPending: (state, action: PayloadAction<string>) => {
      state.applicationsByStepPending.push(action.payload);
    },
  },
  extraReducers: {
    [(getLCApplications as any).pending]: (state) => {
      state.pending = true;
      state.applicationsPending = true;
    },
    [(getLCApplications as any).rejected]: (state) => {
      state.pending = false;
      state.applicationsPending = false;
    },
    [(getLCApplications as any).fulfilled]: (
      state,
      action: PayloadAction<{ results: LCAppsDataT[] }>
    ) => {
      state.pending = false;
      state.LCAppsData.results = action.payload.results;
      state.applicationsPending = false;
    },
    [(getPCApplicationsArchives as any).pending]: (state) => {
      state.pending = true;
    },
    [(getPCApplicationsArchives as any).rejected]: (state) => {
      state.pending = false;
    },
    [(getPCApplicationsArchives as any).fulfilled]: (
      state,
      action: PayloadAction<LCAppsDataT[]>
    ) => {
      state.pending = false;
      state.archivedAppData = action.payload;
    },
    [(getLCApplicationsByStep as any).fulfilled]: (
      state,
      action: PayloadAction<{
        data: { results: LCAppsDataT[]; count: number };
        step: string;
        page: number;
        refetch?: boolean;
      }>
    ) => {
      const { data, step, page, refetch } = action.payload;
      console.log("sasasasasdedwe");
      state.pending = false;
      let customData = [] as LCAppsDataT[];
      if (refetch) {
        customData = state.LCAppsData.results.filter(
          (item) => item.step !== step
        );
      }
      // const combinedArray = refetch
      //   ? [...customData, ...data?.results]
      //   : [...state.appsData, ...data?.results];
      // // Function to generate a unique identifier for each object
      // const getIdentifier = (obj: any) => obj.id; // Assuming objects have an "id" property

      // // Remove duplicates based on the identifier and get the unique objects
      // const filteredArray = Array.from(
      //   new Set(combinedArray.map(getIdentifier))
      // ).map((id) => {
      //   return combinedArray.find((obj) => getIdentifier(obj) === id);
      // });
      // // @ts-ignore
      state.LCAppsData.results = refetch
        ? [...customData, ...data?.results]
        : [...state.LCAppsData.results, ...data?.results];
      state.LCAppsData.type = [
        ...state.LCAppsData.type,
        { step: step, count: data.count },
      ];
      // state.appsData.type.count = data.count;
      // state.appsData.type.step = step;
      if (!page || page === 1 || refetch)
        state.showMoreDefault = [
          ...state.showMoreDefault,
          {
            step,
            page: 1,
            maxPage:
              data.count % 10 > 0
                ? Math.floor(data.count / 10) + 1
                : data.count / 10,
          },
        ];

      state.applicationsByStepPending = state.applicationsByStepPending.filter(
        (item) => item !== step
      );
    },

    [(getPCNotifications as any).pending]: (state) => {
      state.notificationsPending = true;
    },
    [(getPCNotifications as any).rejected]: (state) => {
      state.notificationsPending = false;
    },
    [(getPCNotifications as any).fulfilled]: (
      state,
      action: PayloadAction<notificationT[]>
    ) => {
      state.notifications = action.payload;
      state.notificationsPending = false;
    },

    [(createLCApplicationThunk as any).pending]: (state) => {
      state.actionsPending = "post-application";
    },
    [(createLCApplicationThunk as any).rejected]: (state) => {
      state.actionsPending = "";
    },
    [(createLCApplicationThunk as any).fulfilled]: (state) => {
      state.actionsPending = "";
      state.openModal = "";
    },

    [(createLCApplicationWithClientThunk as any).pending]: (state) => {
      state.actionsPending = "post-application";
    },
    [(createLCApplicationWithClientThunk as any).rejected]: (state) => {
      state.actionsPending = "";
    },
    [(createLCApplicationWithClientThunk as any).fulfilled]: (state) => {
      state.actionsPending = "";
      state.openModal = "";
    },

    [(editLCApplicationThunk as any).pending]: (state) => {
      state.actionsPending = "post-application";
    },
    [(editLCApplicationThunk as any).rejected]: (state) => {
      state.actionsPending = "";
      state.pending = false;
    },
    [(editLCApplicationThunk as any).fulfilled]: (state) => {
      // const data = action.payload;

      // if (data.id) {
      // const editingItem = data;
      // const items = state.appsData;

      // const index = items.findIndex((item) => item.id === editingItem.id);
      // items.splice(index, 1, editingItem);
      // state.appsData = items;
      // }
      state.editingApp = null;
      state.openModal = "";
      state.pending = false;
      state.actionsPending = "";
    },

    [(deleteLCApplicationThunk as any).pending]: (state) => {
      state.actionsPending = "delete-process";
    },
    [(deleteLCApplicationThunk as any).rejected]: (state) => {
      state.actionsPending = "";
    },
    [(deleteLCApplicationThunk as any).fulfilled]: (
      state,
      action: PayloadAction<{ status: string }>
    ) => {
      if (action?.payload?.status === "success") {
        let ids = state.selectedItems;
        let items = state.LCAppsData.results;

        ids.forEach((selectedId) => {
          items = items.filter((item) => item.id !== selectedId);
        });

        console.log(items, state.LCAppsData.results);
        state.LCAppsData.results = items;
        state.selectedItems = [];
        state.indicator = "";
      }

      state.actionsPending = "";
    },
  },
});

export const {
  handleOpen,
  handleClose,
  setAppsData,
  setCancelReason,
  setEditingApp,
  setNotification,
  setSelectedItems,
  setIsChangedAppObject,
  setFillPricingData,
  setAppsByStepPending,
  setCopiedApp,
} = LCApplicationsSlice.actions;
export default LCApplicationsSlice.reducer;
