import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardTop, InputWrapper } from "../Header/style";
import CustomInput from "../../../Common/CustomInput";
import CustomSelect from "../../../Common/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import {
  Autocomplete,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { PCClientDataT } from "../../types";
import {
  PCDataType,
  PCAppsDataT,
  dataType,
  selectT,
} from "../../../../types/commonTypes";
import { FlexBetween, FlexCenter } from "../../../Common/FlexWrapper";
import { nameTypes } from "../../data";
import CustomButton from "../../../Common/CustomButton";
import { Add } from "@mui/icons-material";
import { getEmployeesList } from "../../../../store/actions/employeesActions";
import CustomAutocomplete from "../../../Common/CustomAutocomplete";
import { t } from "i18next";
import { SearchInputWrapper } from "./style";
import { Error } from "../../../Login/style";
import i18next from "i18next";
import CustomInputTextfield from "../../../Common/CustomInputTextfield";

type T = {
  checkErrors: boolean;
  clientData: PCClientDataT;
  editingApp: PCAppsDataT | null;
  onChangeSelect: (e: SelectChangeEvent, name: string, data: selectT[]) => void;
  onChangeSelectAutocomplete?: (
    e: string,
    name: string,
    data: selectT[]
  ) => void;
  setRemovedContacts: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  selectedContact: PCDataType | null;
  onChange: (value: string | number | any[], name: string) => void;
  cannotChange: boolean;
  nameExistAppear: boolean;
};

const ClientCard: React.FC<T> = ({
  checkErrors,
  clientData,
  editingApp,
  onChangeSelect,
  onChangeSelectAutocomplete,
  selectedContact,
  onChange,
  setRemovedContacts,
  cannotChange,
  nameExistAppear,
}) => {
  const dispatch = useAppDispatch();
  const { Data } = useAppSelector((state) => state.settings);
  const { pcInterest } = Data;
  const { dataList: employees } = useAppSelector((state) => state.employees);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const handleInputChange = (value: string) => {
    if (!cannotChange) {
      dispatch(getEmployeesList({ name: value }));
      setInputValue(value);
      setAutoCompleteOptions(employees?.results);
    } else return undefined;
  };

  useEffect(() => {
    if (employees.results) {
      setAutoCompleteOptions(
        employees.results.map((value) => ({
          id: Number(value?.id),
          name: value?.first_name + " " + value?.last_name,
        }))
      );
    }
  }, [employees, dispatch]);
  // useEffect(() => {
  //   clientData.manager?.first_name
  //     ? setInputValue(
  //         clientData.manager?.first_name +
  //           " " +
  //           clientData.manager?.last_name
  //       )
  //     : // @ts-ignore
  //       setInputValue(clientData.manager?.name);
  // }, [clientData.manager]);
  console.log(clientData, "dwedew");

  return (
    <Card fullWidth={!!editingApp}>
      <CardTop>{editingApp ? "Клиент" : "Создать нового клиента"}</CardTop>
      <CardBody>
        <InputWrapper>
          <span>{t("common.full_name")}</span>
          <FlexBetween gap="10px">
            <CustomInput
              name="full_name"
              showError={
                checkErrors && !selectedContact && !clientData.full_name
              }
              value={clientData.full_name}
              onChange={!cannotChange ? onChange : undefined}
              fullWidth
              errorText={t("validations.fill")!}
              type="text"
              height="33px"
              pl="10px"
            />
          </FlexBetween>
        </InputWrapper>
        <InputWrapper>
          <span>{t("common.company_name")}</span>
          <FlexBetween gap="10px">
            <CustomInput
              name="company_name"
              showError={
                (checkErrors && !selectedContact && !clientData.company_name) ||
                nameExistAppear
              }
              value={clientData.company_name}
              onChange={!cannotChange ? onChange : undefined}
              fullWidth
              errorText={nameExistAppear ? "Этот контакт уже есть!" : ""}
              type="text"
              height="33px"
              pl="10px"
            />
          </FlexBetween>
        </InputWrapper>
        {/* {editingApp && (
          <InputWrapper>
            <span>Менеджер создавший контакт</span>
            <Autocomplete
              inputValue={inputValue}
              setInputValue={handleInputChange}
              selectedOption={clientData.manager}
              setSelectedOption={async (e) => {
                if (!cannotChange && onChangeSelectAutocomplete) {
                  await onChangeSelectAutocomplete(
                    e,
                    "manager",
                    employees.results?.map((value) => {
                      return {
                        id: Number(value?.id),
                        name: value?.first_name + " " + value?.last_name,
                      };
                    })
                  );
                  dispatch(getEmployeesList({}));
                } else return undefined;
              }}
              options={autoCompleteOptions}
            />
          </InputWrapper>
        )} */}
        <InputWrapper>
          <span>{t("common.job_title")}</span>
          <FlexBetween gap="10px">
            <CustomInput
              name="job_title"
              showError={
                checkErrors && !selectedContact && !clientData.job_title
              }
              value={clientData.job_title}
              onChange={!cannotChange ? onChange : undefined}
              fullWidth
              errorText={t("validations.fill")!}
              type="text"
              height="33px"
              pl="10px"
            />
          </FlexBetween>
        </InputWrapper>
        <InputWrapper>
          <span>{t("common.interest")}</span>
          <SearchInputWrapper>
            <Autocomplete
              multiple
              filterSelectedOptions
              value={clientData.interests || []}
              onChange={(event, newValue) => {
                console.log(newValue, event, "dewqdcaskoowq");
                onChange(newValue, "interests");
              }}
              className="recommendedInput"
              id="controllable-states-demo"
              options={
                pcInterest?.filter(
                  (item) =>
                    !clientData.interests?.find((i: any) => i.id === item.id)
                      ?.name
                ) || []
              }
              getOptionLabel={(option) => option.name}
              sx={{ width: "100%", padding: 0 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Выбирать" />
              )}
            />
            {checkErrors &&
              (!clientData.interests || clientData.interests.length === 0) && (
                <Error>*{i18next.t("validations.fill")}</Error>
              )}
          </SearchInputWrapper>
        </InputWrapper>
        <InputWrapper>
          <span>{t("common.telegram")}</span>
          <FlexBetween gap="10px">
            <CustomInput
              name="telegram"
              showError={
                checkErrors &&
                !selectedContact &&
                !(
                  clientData.telegram.trim() ||
                  !!clientData.person_contacts.filter(
                    (contact) => contact.phone_number
                  ).length
                )
              }
              value={clientData.telegram}
              onChange={!cannotChange ? onChange : undefined}
              fullWidth
              errorText={t("validations.fill")!}
              type="text"
              height="33px"
              pl="10px"
            />
          </FlexBetween>
        </InputWrapper>
        <InputWrapper>
          <span>{t("common.note")}</span>
          <CustomInputTextfield
            fs="14px"
            name="note"
            errorFs="12px"
            value={clientData.note || ""}
            onChange={onChange}
            fullWidth
            type="text"
            height="50px"
            pl="10px"
          />
        </InputWrapper>

        {clientData.person_contacts.map((contact, idx) => (
          <Stack key={idx} gap={"20px"}>
            <FlexBetween gap="10px">
              <h3>Контактное лицо {idx + 1}</h3>
              <h4
                onClick={() => {
                  if (clientData.person_contacts.length === 1) {
                    const value = [{ phone_number: "" }];
                    onChange(
                      selectedContact ? clientData.person_contacts : value,
                      "person_contacts"
                    );
                    const removedValue = clientData.person_contacts.filter(
                      (contact, id) => idx === id
                    );
                    setRemovedContacts((prevState) =>
                      prevState
                        ? [...prevState, removedValue[0]]
                        : [removedValue[0]]
                    );
                  } else {
                    const value = clientData.person_contacts.filter(
                      (contact, id) => idx !== id
                    );
                    onChange(value, "person_contacts");
                    const removedValue = clientData.person_contacts.filter(
                      (contact, id) => idx === id
                    );
                    setRemovedContacts((prevState) =>
                      prevState
                        ? [...prevState, removedValue[0]]
                        : [removedValue[0]]
                    );
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                X
              </h4>
            </FlexBetween>

            <InputWrapper>
              <span>Телефон</span>
              <CustomInput
                name="phone_number"
                showError={
                  checkErrors &&
                  !selectedContact &&
                  !(
                    clientData.telegram.trim() ||
                    (contact.phone_number && contact.phone_number.length >= 13)
                  )
                }
                // errorText={
                // clientData.phone_number.length < 13 ? "went wrong" : undefined
                // !clientData.phone_number.startsWith("+998")
                //   ? "went wrong"
                //   : undefined
                // }
                value={contact.phone_number}
                // onChange={onChange}
                onChange={(e, name) => {
                  if (!cannotChange) {
                    let value = [...clientData.person_contacts];
                    value[idx] = {
                      ...value[idx],
                      phone_number: String(e) === "undefined" ? "" : String(e),
                    };
                    console.log(value);

                    onChange(value, "person_contacts");
                  } else return undefined;
                }}
                fullWidth
                type="number"
                height="33px"
                pl="10px"
              />
            </InputWrapper>
          </Stack>
        ))}
        <FlexCenter>
          <CustomButton
            bgColor="#fff"
            fs="20px"
            value={<Add />}
            color="#000"
            type="button"
            width="123px"
            height="32px"
            padding="0"
            fw="700"
            onClick={() => {
              const value = [
                ...clientData.person_contacts,
                { phone_number: "" },
              ];
              onChange(value, "person_contacts");
            }}
          />
        </FlexCenter>
      </CardBody>
    </Card>
  );
};

export default ClientCard;
// UseReplace("search-client-name", String(e))
