const { REACT_APP_BASE_URL, REACT_APP_BASE_URL_TEST } = process.env;

export const refreshToken = async () => {
  const baseURL =
    window.location.host === "test.siymo-crm.uz" ||
    window.location.host === "localhost:3003"
      ? REACT_APP_BASE_URL_TEST
      : REACT_APP_BASE_URL;
  if (localStorage.getItem("siymoRefreshToken")) {
    const res = await fetch(`${baseURL}/account/auth/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh: localStorage.getItem("siymoRefreshToken"),
      }),
    });

    const resData = await res.json();

    if (res.status === 401) {
      localStorage.removeItem("siymoToken");
      localStorage.removeItem("siymoRefreshToken");
      // window.location.reload();
    } else {
      localStorage.setItem("siymoToken", resData.access);
    }
  } else {
    // window.location.reload();
    localStorage.removeItem("siymoToken");
    localStorage.removeItem("siymoRefreshToken");
  }
};
