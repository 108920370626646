import {
  Autocomplete,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Text } from "../../../globalStyle";
import CustomButton from "../../Common/CustomButton";
import CustomSelect from "../../Common/CustomSelect";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getTypes } from "../../../store/actions/settingsActions";
import useSearch from "../../../hooks/useSearch";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { useLocation, useNavigate } from "react-router-dom";
import UseReplace from "../../../hooks/useReplace";
import { useTranslation } from "react-i18next";
import DatePicker from "../../Common/DatePicker";
import { FilterWrapper, SumWrapper } from "../style";
import "dayjs/locale/en-gb";
import { FlexWrapper } from "../../Common/FlexWrapper";
import { Replay } from "@mui/icons-material";
import CustomInput from "../../Common/CustomInput";
import { getManagersThunk } from "../../../store/actions/mainActions";
import {
  setAppsByStepPending,
  setAppsData,
} from "../../../store/slices/LCApplicationsSlice";
import { selectT, statusT } from "../../../types/commonTypes";
import { prettierNum } from "../../../utils/helpers";
import { getEmployeesList } from "../../../store/actions/employeesActions";
import {
  getLCApplications,
  getLCApplicationsByStep,
} from "../../../store/actions/LCApplicationsActions";
import { SearchInputWrapper } from "./AddApplication/style";

const Filter: React.FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  const { Data, profile } = useAppSelector((state) => state.settings);
  const { dataList: employees } = useAppSelector((state) => state.employees);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);

  const [inputValue, setInputValue] = React.useState("");
  const { managers } = useAppSelector((state) => state.main);

  const { ourCommitments, lsStatuses, lcInterest, about, activities } = Data;
  const query = useSearch();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const handleInputChange = (value: string) => {
    dispatch(getEmployeesList({ name: value }));
    setInputValue(value);
    setAutoCompleteOptions(employees?.results);
  };

  const appTypes = t("LCApplications.applicationsTypes", {
    returnObjects: true,
  }) as statusT[];

  const onChange = (event: SelectChangeEvent, name: string) => {
    navigate(`${pathname}${UseReplace(name, event.target.value)}`);
  };
  console.log(Data);

  const onChangeSum = (value: string | number, name: string) =>
    navigate(
      `${pathname}${UseReplace(
        name,
        String(String(value).replaceAll(/\D/g, ""))
      )}`
    );

  const onChangePicker = (value: Dayjs | null, keyPicker: string) => {
    const date = `${value?.year()}-${
      value?.month()! + 1 > 9
        ? value?.month()! + 1
        : "0" + (value?.month()! + 1)
    }-${value?.date()}`;
    navigate(`${pathname}${UseReplace(keyPicker, date)}`);
  };
  useEffect(() => {
    if (employees.results) {
      setAutoCompleteOptions(
        employees.results.map((value) => ({
          id: Number(value?.id),
          name: value?.first_name + " " + value?.last_name,
        }))
      );
    }
  }, [employees, dispatch]);
  useEffect(() => {
    dispatch(getTypes({ url: "legal-settings", dataType: "lsStatuses" }));
    dispatch(getTypes({ url: "legal/interest", dataType: "lcInterest" }));
    dispatch(getTypes({ url: "job-commitments", dataType: "ourCommitments" }));
    dispatch(getTypes({ url: "type-design", dataType: "typeServices" }));
    dispatch(getManagersThunk(""));
  }, []);
  const formatter = new Intl.NumberFormat("fr-FR");
  const hasPermission = [
    "director",
    "executive_director",
    "head_community_department",
  ];
  return (
    <Stack
      gap="26px"
      bgcolor={"#FCFCFC"}
      borderRadius="5px"
      boxShadow="0px 4px 6px -3px rgba(0, 0, 0, 0.1)"
      padding="15px 37px 20px 37px"
      alignItems="center"
      position="relative"
      height="max-content"
    >
      <Text c="#4f4f4f" fs="24px" fw="700">
        {t("filter.filter")}
      </Text>

      <Stack gap="50px" direction="row">
        <Stack gap="20px" width="50%">
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              Наши обязательства
            </Text>
            <CustomSelect
              width="261px"
              value={query.get("job_type")!}
              values={ourCommitments}
              onChange={(e) => onChange(e, "job_type")}
            />
          </FilterWrapper>
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              Интерес
            </Text>

            <SearchInputWrapper>
              <Autocomplete
                multiple
                filterSelectedOptions
                value={JSON.parse(query.get("interest") || "[]") || null}
                onChange={(event, newValue) => {
                  console.log(newValue, event, "dewqdcaskoowq");
                  navigate(
                    `${pathname}${UseReplace(
                      "interest",
                      newValue.length ? JSON.stringify(newValue) : ""
                    )}`
                  );
                }}
                className="recommendedInput"
                id="controllable-states-demo"
                options={lcInterest || []}
                getOptionLabel={(option) => option.name}
                sx={{ width: "100%", padding: 0 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Выбирать" />
                )}
              />
            </SearchInputWrapper>
          </FilterWrapper>
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              По Статусам заявки
            </Text>

            <SearchInputWrapper>
              <Autocomplete
                multiple
                filterSelectedOptions
                value={JSON.parse(query.get("status") || "[]") || null}
                onChange={(event, newValue) => {
                  console.log(newValue, event, "dewqdcaskoowq");
                  navigate(
                    `${pathname}${UseReplace(
                      "status",
                      newValue.length ? JSON.stringify(newValue) : ""
                    )}`
                  );
                }}
                className="recommendedInput"
                id="controllable-states-demo"
                options={lsStatuses || []}
                getOptionLabel={(option) => option.name}
                sx={{ width: "100%", padding: 0 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Выбирать" />
                )}
              />
            </SearchInputWrapper>
          </FilterWrapper>

          {hasPermission.includes(profile.role) && (
            <FilterWrapper>
              <Text c="#828282" fs="9px" fw="400">
                Ответственный менеджер
              </Text>
              <CustomSelect
                width="261px"
                value={query.get("manager") || ""}
                values={managers.map((item) => ({
                  id: item.id,
                  name: `${item.first_name} ${item.last_name}`,
                }))}
                onChange={(e) => onChange(e, "manager")}
              />
            </FilterWrapper>
          )}
        </Stack>

        <Stack gap="20px" width="50%">
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              Источник информации
            </Text>
            <CustomSelect
              width="290px"
              value={query.get("survey")!}
              values={about}
              onChange={(e) => onChange(e, "survey")}
            />
          </FilterWrapper>
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              {t("common.activity")}
            </Text>
            <CustomSelect
              width="100%"
              value={query.get("activity") || ""}
              values={activities}
              onChange={(e) => onChange(e, "activity")}
            />
          </FilterWrapper>
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              {t("filter.time")}
            </Text>
            <Stack direction={"row"} gap="15px">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  keyPicker="date_from"
                  title={t("filter.from")}
                  value={dayjs(query.get("date_from")) || dayjs("2023-01-01")}
                  onChangePicker={onChangePicker}
                />

                <DatePicker
                  keyPicker="date_to"
                  title={t("filter.to")}
                  value={dayjs(query.get("date_to")) || dayjs("2023-01-01")}
                  onChangePicker={onChangePicker}
                />
              </LocalizationProvider>
            </Stack>
          </FilterWrapper>
        </Stack>
      </Stack>

      <Stack direction={"row"} gap="17px" alignItems="center">
        <Text
          c="#6C6C6C"
          fs="20px"
          fw="700"
          onClick={handleClose}
          style={{ cursor: "pointer" }}
        >
          {t("common.cancel")}
        </Text>
        <CustomButton
          bgColor="var(--primary)"
          fs="20px"
          value="Поиск"
          color="#fff"
          type="button"
          width="123px"
          height="32px"
          padding="0"
          fw="700"
          onClick={() => {
            dispatch(setAppsData([]));
            // dispatch(getLCApplications({ query }));
            appTypes.forEach((item) => {
              dispatch(setAppsByStepPending(item.step));
              dispatch(getLCApplicationsByStep({ api: item.step, query }));
            });
            handleClose();
          }}
        />
      </Stack>

      <FlexWrapper
        position="absolute"
        bottom="25px"
        right="46px"
        color="#828282"
        alignItems="center"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(setAppsData([]));
          appTypes.forEach((item) => {
            dispatch(setAppsByStepPending(item.step));
            dispatch(getLCApplicationsByStep({ api: item.step }));
          });
          navigate(`/applications-lc`);
          handleClose();
        }}
      >
        <Replay fontSize="small" />
        <Text c="#828282" fs="14px" fw="400">
          Сбросить
        </Text>
      </FlexWrapper>
    </Stack>
  );
};

export default Filter;
