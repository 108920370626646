import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FlexBetween, FlexWrapper } from "../../Common/FlexWrapper";
import { Box, Checkbox, Divider, Stack } from "@mui/material";
import { Text } from "../../../globalStyle";
import { Expand, TextWrapper } from "../style";
import user from "../../../assets/icons/settings/contact/user.svg";
import dateIcon from "../../../assets/icons/settings/contact/date.svg";
import { ArrowRight } from "@mui/icons-material";
import React from "react";
import { contactActionT } from "../../../store/slices/PCContactSlice";
import { useTranslation } from "react-i18next";
import ProcessLine from "../../Common/ProcessLine";
import coin from "../../../assets/icons/settings/contact/coin.svg";
import { PCAppsDataT, PCStatusT, statusT } from "../../../types/commonTypes";
import { prettierNum } from "../../../utils/helpers";
import { useAppSelector } from "../../../hooks/reduxHooks";

type T = {
  item: PCAppsDataT;
  handleChange: (number: number, isExpanded: boolean) => void;
  handleCheckboxChange: (id: number, isChecked: boolean) => void;
  expanded: number | false;
  selectedIds: number[];
};

const ContactAction: React.FC<T> = ({
  item,
  handleChange,
  handleCheckboxChange,
  expanded,
  selectedIds,
}) => {
  const { manager, created_at, survey, applications, status_list } = item;
  const { first_name, last_name } = manager;
  const isSelected = selectedIds.includes(item.id);

  const { Data } = useAppSelector((state) => state.settings);
  const { cancel, pcStatuses } = Data;
  const { t } = useTranslation();

  return (
    <FlexBetween display="flex" width="100%">
      <Stack alignItems="center" spacing={2}>
        <Checkbox
          checked={isSelected}
          onChange={(e) => {
            e.stopPropagation();
            handleCheckboxChange(item.id, e.target.checked);
          }}
        />
      </Stack>
      <Accordion
        expanded={item.id === expanded}
        onChange={(event: React.SyntheticEvent, isExpanded: boolean) =>
          handleChange(item.id, isExpanded)
        }
        key={item.id}
        style={{
          borderRadius: "10px",

          boxShadow: "0px 4px 6px -3px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          height: "fit-content",
          position: "relative",
          width: "100%",
        }}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{ paddingLeft: "30px" }}
        >
          <ArrowRight />

          <TextWrapper>
            <img src={user} alt="" />
            <Text
              c="#282C2D"
              fs="16px"
              fw="400"
              style={{ minWidth: "fit-content" }}
            >
              {first_name} {last_name}
            </Text>
          </TextWrapper>

          <FlexWrapper width="50%" gap="5px">
            О нас узнали:
            <Text c="#282C2D" fs="16px" fw="700">
              {survey && survey[0]?.name?.length > 30
                ? survey && survey[0]?.name?.substring(0, 30) + "..."
                : survey && survey[0]?.name}
            </Text>
          </FlexWrapper>

          <TextWrapper>
            <img src={coin} alt="" />
            <Text c="#282C2D" fs="16px" fw="400">
              {prettierNum(+item.price)}
            </Text>
          </TextWrapper>

          <TextWrapper>
            <img src={dateIcon} alt="" />
            <Text c="#282C2D" fs="16px" fw="400">
              {created_at}
            </Text>
          </TextWrapper>
        </AccordionSummary>

        <AccordionDetails>
          <Stack width={"100%"} alignItems="center" gap="26px">
            <FlexWrapper
              style={{
                width: "97%",
                borderTop: "1px solid #e0e0e0",
                paddingTop: "15px",
                gap: "5px",
              }}
            >
              {status_list.map((status) => (
                <p
                  style={{
                    background: "#fefe",
                    border: "1px solid #e8e8e8",
                    width: "max-content",
                    padding: "4px 6px",
                    borderRadius: "25px",
                  }}
                >
                  {status.name}
                </p>
              ))}
            </FlexWrapper>
            {/* <Box width="97%" pt="5px">
              <ProcessLine stepLength={steps?.length} />
              <Box
                display="grid"
                gridTemplateColumns="repeat(7, 1fr)"
                width="100%"
              >
                {steps?.map((action, index) => (
                  <Stack key={index} ml={index === 0 ? "0" : "0"}>
                    <Text c="#282C2D" fs="12px" fw="700">
                      {
                        (
                          (
                            pcStatuses.map((item) => {
                              return {
                                title: item.name,
                                step: item?.status_type,
                                id: item.id,
                              };
                            }) as PCStatusT[]
                          )?.find(
                            (item: PCStatusT) => item.step === action.step
                          )! as PCStatusT
                        )?.title
                      }
                    </Text>
                    <Text c="#282C2D" fs="12px" fw="400">
                      {action.created_at}
                    </Text>
                  </Stack>
                ))}

                {"1234567"
                  .substring(0, 7 - steps?.length)
                  .split("")
                  .map((item) => (
                    <div key={item}></div>
                  ))}
              </Box>
            </Box> */}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </FlexBetween>
  );
};

export default ContactAction;
