import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import img from "../../../../assets/images/login.png";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import CustomSelect from "../../../Common/CustomSelect";
import { Card, CardBody, InputTitle, InputWrapper } from "../Header/style";
import "dayjs/locale/en-gb";
import { Text } from "../../../../globalStyle";
import CustomButton from "../../../Common/CustomButton";
import {
  getPCContact,
  getPCContactById,
} from "../../../../store/actions/PCContactActions";
import useSearch from "../../../../hooks/useSearch";
import { useLocation, useNavigate } from "react-router-dom";
import UseReplace from "../../../../hooks/useReplace";
import {
  createPCApplicationThunk,
  createPCApplicationWithClientThunk,
  deletePCContactsThunk,
  editPCApplicationThunk,
} from "../../../../store/actions/PCApplicationsActions";
import { PCAppType, PCClientDataT, onChangeAppType } from "../../types";
import ClientCard from "./ClientCard";
import SearchClientCard from "./SearchClientCard";
import { defaultPCClientData, defaultListItem, nameTypes } from "../../data";
import {
  PCDataType,
  appT,
  PCAppsDataT,
  dataType,
  PCOnDropT,
  selectT,
} from "../../../../types/commonTypes";
import { ContentCopyOutlined, ContentPasteOutlined } from "@mui/icons-material";
import { FlexCenter } from "../../../Common/FlexWrapper";
import { setCopiedApp } from "../../../../store/slices/PCApplicationsSlice";
import { openSnackbar } from "../../../../store/slices/mainSlice";
import {
  ChooseRecommended,
  RecommendedCard,
  SearchInputWrapper,
  WrapperRecommended,
} from "./style";
import CommonModal from "../../../Common/CustomModal";
import { getTypes } from "../../../../store/actions/settingsActions";
import {
  getEmployees,
  getEmployeesList,
} from "../../../../store/actions/employeesActions";
import CustomAutocomplete from "../../../Common/CustomAutocomplete";
import ExpiredForm from "./ExpiredForm";
import i18next from "i18next";
import CustomInput from "../../../Common/CustomInput";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DatePicker from "../../../Common/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import CustomInputTextfield from "../../../Common/CustomInputTextfield";
import { useTranslation } from "react-i18next";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { convertDateFormat } from "../../../../utils/helpers";
import styled from "styled-components";
dayjs.extend(customParseFormat);
const AddApplication: React.FC<{
  handleClose: () => void;
  isFillPricing?: boolean;
  onDrop?: (data: PCOnDropT) => void;
}> = ({ handleClose, isFillPricing, onDrop }) => {
  const { data: contacts, pending } = useAppSelector(
    (state) => state.PCContact
  );
  const {
    actionsPending,
    editingApp,
    copiedApp,
    fillPricingData,
    cannotEditApp,
    exceptedNotification,
  } = useAppSelector((state) => state.PCApplications);
  const { t } = useTranslation();
  const { dataList: employees } = useAppSelector((state) => state.employees);
  const dispatch = useAppDispatch();

  const { Data, profile } = useAppSelector((state) => state.settings);
  const { pcStatuses, about } = Data;
  const [isAddClicked, setIsAddClicked] = useState(false);
  const [clientData, setClientData] =
    useState<PCClientDataT>(defaultPCClientData);
  const [recommendedData, setRecommendedData] = useState<
    { id: number; name: string }[]
  >([]);
  const [removedContacts, setRemovedContacts] = useState<any[]>();
  const [applicationData, setApplicationData] = useState<PCAppType>({
    client: null,
    status: null,
    note: null,
    about: null,
    manager: null,
    price: null,
    started_at: null,
    is_come: false,
  });
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const handleInputChange = (value: string) => {
    dispatch(getEmployeesList({ name: value }));
    setInputValue(value);
    setAutoCompleteOptions(employees.results);
  };
  const [openRecommendedModal, setOpenRecommendedModal] = useState(false);
  const handleOpenRecommendedModal = () => {
    setOpenRecommendedModal(true);
  };
  const handleCloseRecommendedModal = () => {
    setOpenRecommendedModal(false);
  };
  const [list, setList] = useState<appT[]>([]);
  const [designerData, setDesignerData] = useState<any>({
    type_of_designs: [],
    designers: [],
    designer_price: 0,
  });
  const [listItem, setListItem] = useState<appT>(defaultListItem);
  const [manager, setManager] = useState("");
  const [editingAppComp, setEditingAppComp] = useState<null | number>(null);

  const [selectedContact, setSelectedContact] = useState<PCDataType | null>(
    null
  );
  const [selectedContactRecommended, setSelectedContactRecommended] = useState<
    number[]
  >([]);
  const [checkErrors, setCheckErrors] = useState(false);

  console.log(editingApp);

  const query = useSearch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (employees.results) {
      setAutoCompleteOptions(
        employees.results.map((value) => ({
          id: Number(value?.id),
          name: value?.first_name + " " + value?.last_name,
        }))
      );
    }
  }, [employees, dispatch]);
  useEffect(() => {
    applicationData.manager?.first_name
      ? setInputValue(
          applicationData.manager?.first_name +
            " " +
            applicationData.manager?.last_name
        )
      : // @ts-ignore
        setInputValue(applicationData.manager?.name);
  }, [applicationData.manager]);
  useEffect(() => {
    dispatch(getTypes({ url: "physical-settings", dataType: "pcStatuses" }));
    dispatch(getEmployeesList({}));
    dispatch(getTypes({ url: "physical/interest", dataType: "pcInterest" }));
  }, [dispatch]);
  // useEffect(() => {
  //   if (exceptedNotification) {
  //     const { activity, name, work, telegram, client, name_type } =
  //       exceptedNotification;

  //     setApplicationData({ ...applicationData, work });
  //     if (client) dispatch(getPCContactById(client!));
  //     else
  //       setClientData({
  //         ...clientData,
  //         name_type,
  //         name,
  //         telegram,
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [exceptedNotification]);

  // useEffect(() => {
  //   if (contactById?.id) {
  //     const { company_name, telegram } = contactById;
  //     setClientData({
  //       ...clientData,
  //       // activity,
  //       // company_name,
  //       telegram,
  //     });

  //     navigate(`${pathname}${UseReplace("search-client-name", company_name)}`);
  //     setSelectedContact(contactById);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [contactById]);
  const [finalValue, setFinalValue] = useState<string>("");

  useEffect(() => {
    const delay = 500; // Adjust the delay as needed (e.g., 500 milliseconds)
    const timeoutId = setTimeout(() => {
      setFinalValue(clientData.company_name);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [clientData.company_name]);
  useEffect(() => {
    navigate(
      `${pathname}${UseReplace("search-client-name", String(finalValue))}`
    );
  }, [finalValue]);
  const onChange = (value: string | number | any[], name: string) => {
    setClientData({
      ...clientData,
      [name === "company_name" ? "company_name" : name]:
        name === "company_name" ? String(value) : value,
    });
  };
  const [nameExistAppear, setNameExistAppear] = useState(false);
  useEffect(() => {
    if (!pending) {
      console.log(selectedContact, "apperas false");
      if (
        (selectedContact?.company_name.toLowerCase() ===
          clientData.company_name.toLowerCase() ||
          editingApp?.client?.company_name.toLowerCase() ===
            clientData.company_name.toLowerCase()) &&
        (clientData.telegram
          ? selectedContact?.telegram.toLowerCase() ===
              clientData.telegram.toLowerCase() ||
            editingApp?.client?.telegram.toLowerCase() ===
              clientData.telegram.toLowerCase()
          : selectedContact?.person_contacts[0]?.phone_number ===
              clientData.person_contacts[0]?.phone_number ||
            editingApp?.client?.person_contacts[0]?.phone_number ===
              clientData.person_contacts[0]?.phone_number)
      ) {
        setNameExistAppear(false);
      } else {
        if (
          !!!contacts?.results?.find(
            (contact) =>
              contact.company_name.toLowerCase() ===
                clientData.company_name.toLowerCase() &&
              (clientData.telegram
                ? contact.telegram.toLowerCase() ===
                  clientData.telegram.toLowerCase()
                : contact.person_contacts[0]?.phone_number.toLowerCase() ===
                  clientData.person_contacts[0]?.phone_number.toLowerCase())
          )
        ) {
          setNameExistAppear(false);
        }
        if (
          !!contacts?.results?.find(
            (contact) =>
              contact.company_name.toLowerCase() ===
                clientData.company_name.toLowerCase() &&
              (clientData.telegram
                ? contact.telegram.toLowerCase() ===
                  clientData.telegram.toLowerCase()
                : contact.person_contacts[0]?.phone_number.toLowerCase() ===
                  clientData.person_contacts[0]?.phone_number.toLowerCase())
          )
        ) {
          setNameExistAppear(true);
        }
      }
    }
  }, [contacts.results, pending, clientData, selectedContact?.id]);

  console.log(editingApp, "editingapplog");
  const onChangeSelect = (
    e: SelectChangeEvent,
    name: string,
    data: selectT[]
  ) => {
    const selectData = data;
    !e.target.value && setClientData(defaultPCClientData);
    setClientData({
      ...clientData,
      [name]: selectData.find((item) => item.id === Number(e.target.value))!,
    });
  };
  const onChangeSelectAutocomplete = (
    e: string,
    name: string,
    data: selectT[]
  ) => {
    const selectData = data;
    console.log(
      selectData.find((item) => item.id === Number(e)),
      "dsafjkdsbfjhdb"
    );

    !e && setClientData(defaultPCClientData);
    setClientData({
      ...clientData,
      [name]: selectData.find((item) => item.id === Number(e))!,
    });
  };
  const onChangeSelectApplication = (
    e: string,
    name: string,
    dataItem: selectT[]
  ) => {
    const selectData = dataItem;

    setApplicationData({
      ...applicationData,
      [name]: selectData.find((item) => item.id === Number(e))!,
    });
  };

  const selectContact = (id: number) => {
    const contact = contacts.results.find((item) => item.id === id);
    setSelectedContact(contact!);
    setClientData(defaultPCClientData);
    console.log("nima gaolade");
  };
  // const selectContactRecommended = (id: number) => {
  //   const contact = contacts.results.find((item) => item.id === id);
  //   setSelectedContactRecommended(contact!);
  //   setRecommendedData(defaultClientData);
  // };
  const changeObject = (listData?: appT[]) => {
    const customList = listData ? listData : list;
    console.log(listItem);

    if (listItem.job?.id) customList.push(listItem);
    return customList.map(({ job, number, price, work, sum }) => ({
      work_id: +work?.id!,
      job_id: +job?.id!,
      price: +price / +number || 0,
      price2: (+sum - +price) / +number || 0,
      number: +number,
    }));
  };
  console.log(applicationData, "salomlarmi");
  const clientSend = {
    telegram: clientData?.telegram!,
    person_contacts:
      clientData?.person_contacts.length > 2 &&
      !clientData?.person_contacts[0].phone_number
        ? []
        : clientData.person_contacts,
    interests: clientData.interests?.map((interest) => interest.id),
    manager: clientData.manager?.id || undefined,
    company_name: clientData.company_name,
    note: clientData.note,
    full_name: clientData.full_name,
    job_title: clientData.job_title,
  };
  const order = {
    manager: applicationData.manager?.id || undefined,
    note: applicationData.note,
    is_come: applicationData.is_come,
    client: +selectedContact?.id! || applicationData.client,
    status: applicationData.status?.map((item) => item.id),
    survey: applicationData.about?.map((item) => item.id),
    price: applicationData.price,
    started_at: applicationData.started_at
      ? moment(
          applicationData.started_at.split("-").length === 3
            ? convertDateFormat(applicationData.started_at)
            : applicationData.started_at
        ).format("YYYY-MM-DD")
      : undefined,
  };

  console.log(
    dayjs(applicationData.started_at, "DD-MM-YYYY").format("YYYY-MM-DD"),
    "loplop030doe"
  );
  const handleEdit = () => {
    if (editingApp) {
      let access = false;
      if (selectedContact) {
        if (
          !clientData.company_name ||
          // !applicationData.started_at ||
          !clientData.full_name ||
          !clientData.job_title ||
          !clientData.interests ||
          clientData.interests?.length === 0 ||
          (["paying", "implementation", "finish"].includes(
            String(editingApp?.step)
          ) &&
            (!applicationData.status ||
              applicationData.status?.length === 0)) ||
          // !applicationData.price ||
          !applicationData.about ||
          applicationData.about?.length === 0 ||
          !(
            clientData.telegram ||
            !!clientData.person_contacts.filter(
              (contact) =>
                contact.phone_number && contact.phone_number.length >= 13
            ).length
          )
        )
          setCheckErrors(true);
        else access = true;
      } else {
        if (
          nameExistAppear ||
          !clientData.company_name ||
          // !applicationData.started_at ||
          !clientData.full_name ||
          !clientData.job_title ||
          !clientData.interests ||
          clientData.interests?.length === 0 ||
          (["paying", "implementation", "finish"].includes(
            String(editingApp?.step)
          ) &&
            (!applicationData.status ||
              applicationData.status?.length === 0)) ||
          // !applicationData.price ||
          !applicationData.about ||
          applicationData.about?.length === 0 ||
          !(
            clientData.telegram ||
            !!clientData.person_contacts.filter(
              (contact) =>
                contact.phone_number && contact.phone_number.length >= 13
            ).length
          )
        )
          setCheckErrors(true);
        else access = true;
      }
      if (access) {
        console.log("lodewij4h9");
        dispatch(
          editPCApplicationThunk({
            orderId: editingApp.id,
            query,
            order: order,
            client: clientSend,
            clientId: editingApp.client.id,
          })
        );
      }
    }
  };
  const handleSubmit = () => {
    let access = false;
    if (selectedContact) {
      if (
        !clientData.company_name ||
        !clientData.full_name ||
        // !applicationData.started_at ||
        !clientData.job_title ||
        !clientData.interests ||
        clientData.interests?.length === 0 ||
        // !applicationData.price ||
        !applicationData.about ||
        applicationData.about?.length === 0 ||
        !(
          clientData.telegram ||
          !!clientData.person_contacts.filter(
            (contact) =>
              contact.phone_number && contact.phone_number.length >= 13
          ).length
        )
      )
        setCheckErrors(true);
      else access = true;
    } else {
      if (
        nameExistAppear ||
        !clientData.company_name ||
        !clientData.full_name ||
        // !applicationData.started_at ||
        !clientData.job_title ||
        !clientData.interests ||
        clientData.interests?.length === 0 ||
        !applicationData.about ||
        applicationData?.about?.length === 0 ||
        // !applicationData?.price ||
        !(
          clientData.telegram ||
          !!clientData.person_contacts.filter(
            (contact) =>
              contact.phone_number && contact.phone_number.length >= 13
          ).length
        )
      )
        setCheckErrors(true);
      else access = true;
    }

    if (access) {
      console.log(order);

      dispatch(
        selectedContact
          ? createPCApplicationThunk({
              order,
              user: clientSend,
              objects: changeObject(),
              clientId: selectedContact.id,
            })
          : createPCApplicationWithClientThunk({
              user: {
                ...clientData,
                interests: clientData.interests?.map((item) => item.id) || null,
              },
              appData: { order, objects: changeObject() },
            })
      );
      // navigate(`${pathname}${UseReplace("search-client-name", "")}`);
    }
  };
  console.log(applicationData.started_at, "dwdedw23232");

  const fillPricingSubmit = () => {
    console.log("HELLO GUYS--------------");
    const fillPriceData = {
      monitor: fillPricingData?.monitor,
      step: String(fillPricingData?.step),
      // @ts-ignore
      item: {
        ...fillPricingData?.item,
        ...order,
        client: applicationData?.client,
      } as PCAppsDataT,
    };
    // if (checkedJobTypes.length === doneJobTypes.length) {

    const hasErrors = (contactCheck = true) => {
      const checkCommonConditions = () =>
        ["paying", "implementation", "finish"].includes(
          String(fillPricingData?.step)
        ) &&
        (!applicationData.status ||
          applicationData.status?.length === 0 ||
          !clientData.company_name ||
          !clientData.full_name ||
          !clientData.job_title ||
          !applicationData.about ||
          applicationData.about?.length === 0 ||
          !clientData.interests ||
          clientData.interests?.length === 0 ||
          !(
            clientData.telegram ||
            !!clientData.person_contacts.filter(
              (contact) =>
                contact.phone_number && contact.phone_number.length >= 13
            ).length
          ));

      return (
        (contactCheck && selectedContact && checkCommonConditions()) ||
        (!selectedContact && (nameExistAppear || checkCommonConditions()))
      );
    };

    const access = !hasErrors();

    if (hasErrors(true)) {
      setCheckErrors(true);
    }
    console.log(access, "accesslogcheck");

    if (access) {
      dispatch(
        editPCApplicationThunk({
          orderId: editingApp?.id,
          // objects: changeObject(list.filter((item) => !item.id)),
          query,
          client: clientSend,
          order,
          clientId: editingApp?.client.id,
          step: editingApp?.step,
        })
      ).then(() => {
        onDrop!(fillPriceData!);
      });
    }

    // }
  };

  useEffect(() => {
    if (query.get("search-client-name")) dispatch(getPCContact(query));
    // if (!query.get("search-client-name")) setSelectedContact(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query.get("search-client-name")]);
  // useEffect(() => {
  //   if (query.get("search-recommended-name")) dispatch(getPCContact(query));
  //   if (!query.get("search-recommended-name")) setSelectedContact(null);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, query.get("search-recommended-name")]);

  useEffect(() => {
    if (editingApp) {
      const {
        client,
        status_list,
        applications,
        is_archived,
        is_expired,
        deadline,
        expired_reason,
        survey,
        price,
        is_come,
        note: noteApp,
        manager: appManager,
        client_friends,
        designers,
        designer_price,
        type_of_designs,
        started_at,
      } = editingApp;
      const {
        company_name,
        full_name,
        telegram,
        note,
        job_title,
        interests,
        person_contacts,
        manager,
      } = client;
      console.log(type_of_designs, "slaoe");

      setManager(`${appManager.first_name} ${appManager.last_name}`);
      setApplicationData({
        client: +String(client?.id),
        status: status_list,
        about: survey,
        note: noteApp,
        is_come,
        manager: appManager,
        started_at,
        price,
      });
      setClientData({
        company_name,
        full_name,
        job_title,
        note,
        interests,
        telegram,
        manager,
        person_contacts,
      });
      setRecommendedData(
        client_friends?.map(
          (item: {
            id: number;
            client_friend: { id: number; name: string };
          }) => {
            return { id: item.client_friend.id, name: item.client_friend.name };
          }
        )
      );
      setSelectedContactRecommended(
        client_friends?.map(
          (item: { id: number; client_friend: { id: number; name: string } }) =>
            item.client_friend.id
        )
      );
      setDesignerData({
        type_of_designs: type_of_designs?.map((item: any) => {
          return {
            id: item.type_of_design.id,
            price_id: item?.price?.id,
          };
        }),
        designers,
        designer_price: designer_price,
      });
      setList(applications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingApp]);
  const handleCopy = () => {
    dispatch(setCopiedApp(editingApp));
    dispatch(openSnackbar({ status: "success", message: "Copied" }));
  };
  const handlePaste = () => {
    if (copiedApp) {
      const {
        client,
        status_list,
        applications,
        price,
        is_come,
        started_at,
        note: noteApp,
        survey,
        manager: appManager,
      } = copiedApp;
      const {
        company_name,
        full_name,
        telegram,
        job_title,
        person_contacts,
        manager,
      } = client;

      setManager(`${appManager.first_name} ${appManager.last_name}`);
      setApplicationData({
        client: +String(client?.id),
        status: status_list,
        about: survey,
        is_come,
        note: noteApp,
        manager: appManager,
        started_at,
        price,
      });
      setClientData({
        ...clientData,
        company_name,
        full_name,
        telegram,
        job_title,
        person_contacts,
        manager,
      });
      setList(applications);
    }
  };
  console.log(applicationData);

  useEffect(() => {
    if (isFillPricing) setCheckErrors(true);
  }, [isFillPricing]);
  // useEffect(() => {
  //   console.log(clientData);

  //   const aboutValue =
  //     typeof clientData.name_type === "string"
  //       ? about.find((item) => item.name === "Работали ранее")
  //       : null;

  //   !editingApp &&
  //     setApplicationData({
  //       ...applicationData,
  //       about: Data["about"].find(
  //         (item) =>
  //           item.id === Number(aboutValue?.id || applicationData.about?.id)
  //       )!,
  //     });
  // }, [clientData]);
  console.log(Data["about"], "datadigiabout");

  const [isExpiredApplication, setIsExpiredApplication] = useState(false);
  // useEffect(() => {
  //   setIsExpiredApplication(clientData.is_expired && !editingApp?.deadline);
  //   console.log(clientData.is_expired, clientData.deadline);
  // }, [clientData.is_expired, clientData.deadline]);
  // console.log(listItem, list, "saloao,dew");
  const formatter = new Intl.NumberFormat("fr-FR");
  const Error = styled.div`
    color: red;
    margin-top: 5px;
    font-size: 16px;
  `;
  return (
    <Box borderRadius="10px" overflow="auto" width="70vw" height="95vh">
      <Stack bgcolor="#FCFCFC" p="20px 45px" gap="31px" height="auto">
        <>
          <FlexCenter gap="20px" color="var(--primary)">
            <Text
              c="#828282"
              fs="24px"
              fw="700"
              style={{ textAlign: "center" }}
            >
              {editingApp
                ? editingApp?.step === "application" ||
                  editingApp?.step === "cancelled"
                  ? "Заявка"
                  : "Проект"
                : "Создать заявку"}
            </Text>
            {editingApp && (
              <Tooltip title="Copy">
                <IconButton color="inherit" onClick={handleCopy}>
                  <ContentCopyOutlined />
                </IconButton>
              </Tooltip>
            )}
            {copiedApp && !editingApp && (
              <Tooltip title="Paste copied data">
                <IconButton color="inherit" onClick={handlePaste}>
                  <ContentPasteOutlined />
                </IconButton>
              </Tooltip>
            )}
          </FlexCenter>
          {editingApp && (
            <Text
              c="var(--primary)"
              fs="18px"
              fw="700"
              style={{ textAlign: "center" }}
            >
              {manager}
            </Text>
          )}
          <Stack
            width="100%"
            direction="row"
            gap="37px"
            justifyContent="space-around"
          >
            <Stack style={editingApp ? { width: "40%" } : {}}>
              <ClientCard
                checkErrors={checkErrors}
                clientData={clientData}
                editingApp={editingApp}
                nameExistAppear={nameExistAppear}
                setRemovedContacts={setRemovedContacts}
                onChange={onChange}
                onChangeSelect={onChangeSelect}
                onChangeSelectAutocomplete={onChangeSelectAutocomplete}
                selectedContact={selectedContact}
                cannotChange={
                  profile.role === "crm_manager"
                    ? false
                    : editingApp?.step === "finish" ||
                      editingApp?.step === "cancelled"
                }
              />
            </Stack>
            {/* {!editingApp && (
              <SearchClientCard
                editingApp={editingApp}
                selectContact={selectContact}
                selectedContact={selectedContact}
                setCheckErrors={setCheckErrors}
                setClientData={setClientData}
              />
            )} */}
            {(editingApp &&
              contacts.results?.length > 0 &&
              editingApp?.client?.company_name !== clientData.company_name) ||
            !editingApp ? (
              <SearchClientCard
                editingApp={editingApp}
                selectContact={selectContact}
                selectedContact={selectedContact}
                setCheckErrors={setCheckErrors}
                setClientData={setClientData}
              />
            ) : (
              <FlexCenter
                pt={{ xs: "20px", d400: "30px", d800: "0" }}
                width="60%"
                borderRadius="9px"
                boxShadow=" 0px 4px 6px rgba(0,0,0,0.1)"
                bgcolor={{ xs: "none", d800: "var(--primary)" }}
                sx={{
                  img: {
                    width: { xs: "70%", d800: "100%" },
                  },
                }}
              >
                <img src={img} alt="" />
              </FlexCenter>
              // <SearchClientCard
              //   editingApp={editingApp}
              //   selectContact={selectContact}
              //   selectedContact={selectedContact}
              //   setCheckErrors={setCheckErrors}
              //   setClientData={setClientData}
              // />
              // <SearchClientCard
              //   editingApp={editingApp}
              //   selectContact={selectContact}
              //   selectedContact={selectedContact}
              //   setCheckErrors={setCheckErrors}
              //   setClientData={setClientData}
              // />
            )}
          </Stack>
        </>

        <Card fullWidth>
          <CardBody padding="18px">
            <Text
              c="#282C2D"
              fs="24px"
              fw="700"
              style={{ textAlign: "center" }}
            >
              Заполните заявку
            </Text>
            <Stack
              direction="row"
              flexWrap={"wrap"}
              alignItems={"flex-start"}
              gap="14px"
            >
              <InputWrapper>
                <InputTitle>Источник информации </InputTitle>
                <SearchInputWrapper>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    value={applicationData.about || []}
                    onChange={(event, newValue) => {
                      console.log(newValue, event, "dewqdcaskoowq");

                      setApplicationData({
                        ...applicationData,
                        about: newValue,
                      });
                    }}
                    className="recommendedInput"
                    id="controllable-states-demo"
                    options={about || []}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: "100%", padding: 0 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Выбирать" />
                    )}
                  />
                  {checkErrors &&
                    (!applicationData.about ||
                      applicationData.about.length === 0) && (
                      <Error>*{i18next.t("validations.fill")}</Error>
                    )}
                </SearchInputWrapper>
              </InputWrapper>
              <InputWrapper>
                <InputTitle>Выбор статуса клиента</InputTitle>
                <SearchInputWrapper>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    value={applicationData.status || []}
                    onChange={(event, newValue) => {
                      console.log(newValue, event, "dewqdcaskoowq");

                      setApplicationData({
                        ...applicationData,
                        status: newValue,
                      });
                    }}
                    className="recommendedInput"
                    id="controllable-states-demo"
                    options={pcStatuses || []}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: "100%", padding: 0 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Выбирать" />
                    )}
                  />
                  {checkErrors &&
                    ((!!fillPricingData &&
                      ["paying", "implementation", "finish"].includes(
                        String(fillPricingData?.step)
                      )) ||
                      (!!editingApp &&
                        ["paying", "implementation", "finish"].includes(
                          String(fillPricingData?.step)
                        ))) &&
                    (!applicationData.status ||
                      applicationData.status.length === 0) && (
                      <Error>* {i18next.t("validations.fill")}</Error>
                    )}
                </SearchInputWrapper>
              </InputWrapper>
              {editingApp && (
                <InputWrapper>
                  <InputTitle>Oтветственный менеджер по заявке</InputTitle>
                  <CustomAutocomplete
                    inputValue={inputValue}
                    setInputValue={handleInputChange}
                    selectedOption={applicationData.manager}
                    height="40px"
                    setSelectedOption={(e) => {
                      if (onChangeSelectApplication) {
                        onChangeSelectApplication(
                          e,
                          "manager",
                          employees.results?.map((value) => {
                            return {
                              id: Number(value?.id),
                              name: value?.first_name + " " + value?.last_name,
                            };
                          })
                        );
                      } else return undefined;
                    }}
                    options={autoCompleteOptions}
                  />
                </InputWrapper>
              )}
              <InputWrapper>
                <InputTitle>Сумма оплаты </InputTitle>
                <SearchInputWrapper>
                  <CustomInput
                    name="price"
                    // showError={checkErrors && !applicationData.price}
                    value={
                      applicationData?.price && applicationData?.price !== "0"
                        ? formatter.format(Number(applicationData?.price))
                        : ""
                    }
                    // onChange={onChange}
                    onChange={(e) => {
                      setApplicationData({
                        ...applicationData,
                        price: String(String(e).replaceAll(/\D/g, "")),
                      });
                    }}
                    type="text"
                    fullWidth
                    height="40px"
                    p="10px"
                    pl="10px"
                  />
                </SearchInputWrapper>
              </InputWrapper>
              <InputWrapper>
                <InputTitle>Дата создания договора </InputTitle>
                <SearchInputWrapper>
                  <LocalizationProvider
                    adapterLocale="en-gb"
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      title="Дата"
                      keyPicker="date_from"
                      value={
                        applicationData.started_at
                          ? applicationData.started_at.split("-").length === 3
                            ? dayjs(
                                convertDateFormat(applicationData.started_at)
                              )
                            : dayjs(applicationData.started_at)
                          : null
                      }
                      onChangePicker={(e) =>
                        setApplicationData({
                          ...applicationData,
                          started_at: String(e),
                        })
                      }
                    />
                  </LocalizationProvider>
                  {/* {checkErrors && !applicationData.started_at && (
                    <Error>* {i18next.t("validations.fill")}</Error>
                  )} */}
                </SearchInputWrapper>
              </InputWrapper>
              <InputWrapper>
                <InputTitle>{t("common.note")}</InputTitle>
                <SearchInputWrapper>
                  <CustomInputTextfield
                    fs="14px"
                    name="note"
                    errorFs="12px"
                    value={applicationData.note || ""}
                    onChange={(e) => {
                      setApplicationData({
                        ...applicationData,
                        note: String(e),
                      });
                    }}
                    fullWidth
                    type="text"
                    height="40px"
                    pl="10px"
                  />
                </SearchInputWrapper>
              </InputWrapper>
              <InputWrapper>
                <FormControl sx={{ marginLeft: "2rem" }}>
                  <RadioGroup
                    // row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={applicationData.is_come}
                    onChange={(e) =>
                      setApplicationData({
                        ...applicationData,
                        is_come: e.target.value === "true",
                      })
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#EA7D0E",
                            },
                          }}
                        />
                      }
                      label="пришёл"
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#EA7D0E",
                            },
                          }}
                        />
                      }
                      label="не пришёл"
                    />
                  </RadioGroup>
                </FormControl>
              </InputWrapper>
            </Stack>
          </CardBody>
        </Card>

        <Stack direction={"row"} gap="17px" justifyContent="center">
          <Text
            c="#6C6C6C"
            fs="20px"
            fw="700"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          >
            Отменить
          </Text>
          <CustomButton
            bgColor="var(--primary)"
            fs="20px"
            value={"Сохранить"}
            color="#fff"
            type="button"
            width="123px"
            height="32px"
            padding="0"
            fw="700"
            loading={actionsPending === "post-application"}
            disable={
              !(!cannotEditApp || profile.role === "crm_manager") ||
              !!editingAppComp ||
              actionsPending === "post-application" ||
              nameExistAppear
            }
            onClick={() =>
              isFillPricing
                ? fillPricingSubmit()
                : editingApp
                ? handleEdit()
                : handleSubmit()
            }
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default AddApplication;
