import { createAsyncThunk } from "@reduxjs/toolkit";
import i18next from "i18next";
import { httpRequest } from "../../utils/request";
import { openSnackbar } from "../slices/mainSlice";
import {
  dataTypeT,
  employeePostType,
  employeeType,
} from "../../types/commonTypes";

const deleteUrls = [
  { key: "activity/delete", value: "delete_customer_activities" },
  { key: "survey/delete", value: "delete_surveys" },
  { key: "legal-status/delete", value: "delete_legal_status" },
  { key: "physical-status/delete", value: "delete_physical_status" },
  { key: "job-commitments/delete", value: "delete_job_commitments" },
  { key: "reason", value: "delete_reasons" }, 
];

export const getProfileThunk = createAsyncThunk(
  "settings/getProfileThunk",
  async (_, thunkAPI): Promise<employeeType[]> =>
    await thunkAPI.dispatch(
      httpRequest({
        url: `account/profile/`,
      })
    )
);

export const editProfileThunk = createAsyncThunk(
  "settings/editProfileThunk",
  async (data: employeePostType, thunkAPI) => {
    const formData = new FormData();
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("password2", data.password2);
    formData.append("phone_number", data.phone_number);
    formData.append("role", data.role);
    formData.append("username", data.username);

    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `account/profile/`,
        method: "PATCH",
        body: formData,
        haveImg: true,
      })
    );

    if (res.id) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.updated"),
        })
      );
      return res;
    }
  }
);

export const getTypes = createAsyncThunk(
  "settings/getTypes",
  async (
    { url, dataType }: { url: string; dataType?: dataTypeT },
    thunkAPI
  ) => {
    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `settings/${url}/list/`,
      })
    );

    return { data: res, dataType };
  }
);

export const createTypeThunk = createAsyncThunk(
  "settings/createTypeThunk",
  async (
    { data, url, dataType }: { data: any; url: string; dataType?: dataTypeT },
    thunkAPI
  ) => {
    const { newTypeName } = (thunkAPI.getState() as any).settings;

    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `settings/${url}/create/`,
        method: "POST",
        body: data.name
          ? data
          : dataType === "shortPrice"
          ? { price: data.price }
          : { name: newTypeName! },
      })
    );

    if (res.id) {
      console.log(res);

      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.created"),
        })
      );
      return { data: res, dataType };
    }
  }
);

export const editTypeThunk = createAsyncThunk(
  "settings/editTypeThunk",
  async (
    {
      url,
      dataType,
      workTypeId,
    }: { url: string; dataType?: dataTypeT; workTypeId?: number },
    thunkAPI
  ) => {
    const { editingItem, newTypeName } = (thunkAPI.getState() as any).settings;

    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `settings/${url}/update/${editingItem.id}/`,
        method: "PUT",
        body:
          dataType === "workTypes"
            ? { name: newTypeName, type_of_job: workTypeId }
            : dataType === "shortPrice"
            ? { price: newTypeName }
            : { name: newTypeName },
      })
    );

    if (res.id) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.updated"),
        })
      );
      return {
        data:
          dataType === "workTypes"
            ? {
                ...editingItem,
                name: newTypeName,
                type_of_job: workTypeId,
              }
            : { ...editingItem, name: newTypeName },
        dataType,
      };
    }
  }
);

export const deleteTypeThunk = createAsyncThunk(
  "settings/deleteType",
  async (
    { url, dataType }: { url: string; dataType?: dataTypeT },
    thunkAPI
  ) => {
    // const deleteSomeUrl = deleteUrls.find((item) => item.key === url)?.value;
    const ids = (thunkAPI.getState() as any).settings.selectedItems;
    const res = await thunkAPI.dispatch(
      // ids.length > 1
      //   ?
      httpRequest({
        url: `settings/${url}/delete/`,
        method: "POST",
        body: { ids },
        noJson: true,
      })
      // : httpRequest({
      //     url: `settings/api/${url}/${ids[0]}/`,
      //     method: "DELETE",
      //     noJson: true,
      //   })
    );

    if (res.status === 204 || res.status === 200) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.deleted"),
        })
      );
      return { status: true, dataType };
    }
  }
);
