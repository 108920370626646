import { Text } from "../../../globalStyle";
import { Expand, TextWrapper } from "../style";
import user from "../../../assets/icons/settings/contact/user.svg";
import dateIcon from "../../../assets/icons/settings/contact/date.svg";
import { ArrowRight } from "@mui/icons-material";
import React from "react";
import {
  handleOpen,
  processT,
  setEditingItem,
  setIndicator,
  setSelectedItems,
} from "../../../store/slices/processSlice";
import { IconButton, Stack } from "@mui/material";
import { FlexBetween } from "../../Common/FlexWrapper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteProcessThunk } from "../../../store/actions/processActions";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

type T = {
  item: processT;
  handleChange: (number: number, isExpanded: boolean) => void;
  expanded: number | false;
};

const ProcessAction: React.FC<T> = ({ item, handleChange, expanded }) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);
  const handleDeleteConfirmationOpen = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };
  const dispatch = useAppDispatch();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(null);
  };

  const handleEdit = () => {
    setMenuAnchorEl(null);
    dispatch(setSelectedItems(item.id!));
    dispatch(handleOpen("add-process"));
    console.log(item);

    // @ts-ignore
    dispatch(setEditingItem(item));
    dispatch(setIndicator("edit"));
  };
  const handleDelete = () => {
    setMenuAnchorEl(null);
    dispatch(setSelectedItems(item.id!));
    handleDeleteConfirmationOpen();
  };
  const { activity, name, work, created_at, is_accepted } = item;

  return (
    <Stack
      key={item.id}
      style={{
        borderRadius: "10px",
        border: item.id === expanded ? "1px solid green" : "none",
        boxShadow: "0px 4px 6px -3px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        height: "fit-content",
        position: "relative",
        background: "#fff",
        padding: "12px 0",
      }}
    >
      <Expand isPayed={is_accepted}>
        <ArrowRight />
      </Expand>
      <FlexBetween ml="20px">
        <Text c="#282C2D" fs="16px" fw="700" style={{ width: "25%" }}>
          {activity.name}
        </Text>
        <TextWrapper>
          <img src={user} alt="" />
          <Text c="#282C2D" fs="16px" fw="400">
            {name}
          </Text>
        </TextWrapper>

        <TextWrapper>
          <Text c="#282C2D" fs="16px" fw="400">
            {work.name}
          </Text>
        </TextWrapper>

        <TextWrapper>
          <img src={dateIcon} alt="" />
          <Text c="#282C2D" fs="16px" fw="400">
            {created_at}
          </Text>
        </TextWrapper>
        {!is_accepted && (
          <Stack>
            <IconButton
              aria-label="more"
              aria-controls="process-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="process-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleEdit()} sx={{ color: "#1976D2" }}>
                <EditIcon sx={{ marginRight: 1 }} /> Редактировать
              </MenuItem>
              <MenuItem
                onClick={() => handleDelete()}
                sx={{ color: "#F44336" }}
              >
                <DeleteIcon sx={{ marginRight: 1 }} /> Удалить
              </MenuItem>
            </Menu>
          </Stack>
        )}
      </FlexBetween>
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteConfirmationClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Подтвердите удаление</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Вы уверены, что хотите удалить этот элемент?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmationClose} color="primary">
            Отмена
          </Button>
          <Button
            onClick={() => {
              handleDeleteConfirmationClose();
              dispatch(deleteProcessThunk());
            }}
            color="primary"
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default ProcessAction;
