import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardTop, InputWrapper } from "../Header/style";
import CustomInput from "../../../Common/CustomInput";
import CustomSelect from "../../../Common/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import {
  Autocomplete,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { LCClientDataT } from "../../types";
import {
  LCAppsDataT,
  LCDataType,
  PCDataType,
  PCAppsDataT,
  dataType,
  selectT,
  LCOnDropT,
} from "../../../../types/commonTypes";
import { FlexBetween, FlexCenter } from "../../../Common/FlexWrapper";
import { nameTypes } from "../../data";
import CustomButton from "../../../Common/CustomButton";
import { Add } from "@mui/icons-material";
import { getEmployeesList } from "../../../../store/actions/employeesActions";
import i18next, { t } from "i18next";
import { prettierNum } from "../../../../utils/helpers";
import { SearchInputWrapper } from "./style";
import CustomInputTextfield from "../../../Common/CustomInputTextfield";
import styled from "styled-components";

type T = {
  checkErrors: boolean;
  clientData: LCClientDataT;
  editingApp: LCAppsDataT | null;
  onChangeSelect: (e: SelectChangeEvent, name: string, data: selectT[]) => void;
  onChangeSelectAutocomplete: (e: any, name: string) => void;
  setRemovedContacts: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  selectedContact: LCDataType | null;
  onChange: (value: string | number | any[], name: string) => void;
  cannotChange: boolean;
  nameExistAppear: boolean;
  fillPricingData: LCOnDropT | null;
};
const Error = styled.div<{ errorFs?: string }>`
  color: red;
  margin-top: 5px;
  font-size: ${({ errorFs }) => (errorFs ? errorFs : "16px")};
`;
const ClientCard: React.FC<T> = ({
  checkErrors,
  clientData,
  editingApp,
  onChangeSelect,
  onChangeSelectAutocomplete,
  selectedContact,
  onChange,
  setRemovedContacts,
  fillPricingData,
  cannotChange,
  nameExistAppear,
}) => {
  const dispatch = useAppDispatch();
  const { Data } = useAppSelector((state) => state.settings);
  const { lcInterest } = Data;
  const { dataList: employees } = useAppSelector((state) => state.employees);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const handleInputChange = (value: string) => {
    if (!cannotChange) {
      dispatch(getEmployeesList({ name: value }));
      setInputValue(value);
      setAutoCompleteOptions(employees?.results);
    } else return undefined;
  };

  useEffect(() => {
    if (employees.results) {
      setAutoCompleteOptions(
        employees.results.map((value) => ({
          id: Number(value?.id),
          name: value?.first_name + " " + value?.last_name,
        }))
      );
    }
  }, [employees, dispatch]);
  useEffect(() => {
    clientData.manager?.first_name
      ? setInputValue(
          clientData.manager?.first_name + " " + clientData.manager?.last_name
        )
      : // @ts-ignore
        setInputValue(clientData.manager?.name);
  }, [clientData.manager]);
  console.log(cannotChange, "dwedewclientData");
  const formatter = new Intl.NumberFormat("fr-FR");

  return (
    <Card fullWidth={!!editingApp}>
      <CardTop>{editingApp ? "Клиент" : "Создать нового клиента"}</CardTop>
      <CardBody>
        <InputWrapper>
          <span>Название компании</span>
          <FlexBetween gap="10px">
            <CustomInput
              name="company_name"
              showError={
                (checkErrors && !selectedContact && !clientData.company_name) ||
                nameExistAppear
              }
              value={clientData.company_name}
              onChange={!cannotChange ? onChange : undefined}
              fullWidth
              errorText={nameExistAppear ? "Этот контакт уже есть!" : ""}
              type="text"
              height="33px"
              pl="10px"
            />
          </FlexBetween>
        </InputWrapper>
        <InputWrapper>
          <span>Вид деятельности</span>
          <CustomSelect
            width="100%"
            value={clientData.activity?.id}
            values={
              // !editingApp
              //   ?
              Data?.activities
              // : [
              //     Data?.activities?.find(
              //       (item: any) => item.id === clientData.activity?.id
              //     )!,
              //   ]
            }
            onChange={(e) =>
              !cannotChange
                ? onChangeSelect(
                    e,
                    "activity",
                    // !editingApp
                    //   ?
                    Data?.activities
                    // : [
                    //     Data?.activities?.find(
                    //       (item: any) => item.id === clientData.activity?.id
                    //     )!,
                    //   ]
                  )
                : undefined
            }
            height="33px"
            showError={
              checkErrors &&
              !selectedContact &&
              !clientData.activity &&
              ((!!fillPricingData &&
                fillPricingData?.step !== "introduction") ||
                (!!editingApp && editingApp?.step !== "introduction"))
            }
          />
        </InputWrapper>
        <InputWrapper>
          <span>{t("common.inn")}</span>
          <CustomInput
            name="inn"
            showError={
              checkErrors &&
              !selectedContact &&
              !(clientData.inn.trim() && clientData.inn.length >= 9) &&
              ((!!fillPricingData &&
                !["introduction", "choice_package"].includes(
                  fillPricingData?.step
                )) ||
                (!!editingApp &&
                  !["introduction", "choice_package"].includes(
                    String(editingApp?.step)
                  )))
            }
            errorText={
              !(clientData.inn && clientData.inn.length >= 9)
                ? "Введите не менее 9 символов"
                : t("validations.fill")! || "sasda"
            }
            value={formatter.format(Number(clientData.inn))}
            // onChange={onChange}
            onChange={(e) =>
              !cannotChange
                ? onChange(String(String(e).replaceAll(/\D/g, "")), "inn")
                : undefined
            }
            fullWidth
            type="text"
            height="33px"
            pl="10px"
          />
        </InputWrapper>
        <InputWrapper>
          <span>{t("common.interest")}</span>
          <SearchInputWrapper>
            <Autocomplete
              multiple
              filterSelectedOptions
              value={clientData.interests || []}
              onChange={(event, newValue) => {
                console.log(newValue, event, "dewqdcaskoowq");
                onChange(newValue, "interests");
              }}
              className="recommendedInput"
              id="controllable-states-demo"
              options={
                lcInterest?.filter(
                  (item) =>
                    !clientData.interests?.find((i: any) => i.id === item.id)
                      ?.name
                ) || []
              }
              getOptionLabel={(option) => option.name}
              sx={{ width: "100%", padding: 0 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Выбирать" />
              )}
            />
            {/* {checkErrors &&
              (!clientData.interests || clientData.interests.length === 0) && (
                <Error>*{i18next.t("validations.fill")}</Error>
              )} */}
          </SearchInputWrapper>
        </InputWrapper>
        <InputWrapper>
          <span>{t("common.note")}</span>
          <CustomInputTextfield
            fs="14px"
            name="note"
            errorFs="12px"
            value={clientData.note || ""}
            onChange={onChange}
            fullWidth
            type="text"
            height="50px"
            pl="10px"
          />
        </InputWrapper>
        {/* <InputWrapper>
          <span>Бренд </span>
          <SearchInputWrapper>
            <Autocomplete
              multiple
              value={clientData.brand || []}
              onChange={(event, newValue) => {
                onChangeSelectAutocomplete(newValue, "brand");
              }}
              className="recommendedInput"
              id="controllable-states-demo"
              freeSolo
              options={[]}
              getOptionLabel={(option) => option}
              sx={{ width: "100%", padding: 0 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Выбирать" />
              )}
            />
            {checkErrors &&
              (!clientData.brand || clientData.brand.length === 0) && (
                <Error>*{i18next.t("validations.fill")}</Error>
              )}
          </SearchInputWrapper>
        </InputWrapper> */}
        {(clientData.brand || [""])?.map((item, idx) => (
          <Stack key={idx} gap={"20px"}>
            <FlexBetween gap="10px">
              <h3>Бренд {idx + 1}</h3>
              <h4
                onClick={() => {
                  if (clientData.brand!.length === 1) {
                    onChange(
                      selectedContact ? clientData.brand! : [""],
                      "brand"
                    );
                  } else {
                    const value = clientData.brand!.filter(
                      (contact, id) => idx !== id
                    );
                    onChange(value, "brand");
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                X
              </h4>
            </FlexBetween>
            <InputWrapper>
              <span>Бренд</span>
              <CustomInput
                name="brand"
                value={item}
                // onChange={onChange}
                onChange={(e, name) => {
                  if (!cannotChange) {
                    let value = [...(clientData.brand! || "")];
                    value[idx] = String(e);
                    console.log(value, name);
                    onChange(value, "brand");
                  } else return undefined;
                }}
                fullWidth
                type="text"
                height="33px"
                pl="10px"
              />
            </InputWrapper>
            {checkErrors &&
              item === "" &&
              ((!!fillPricingData &&
                fillPricingData?.step !== "introduction") ||
                (!!editingApp && editingApp?.step !== "introduction")) && (
                <Error>*{i18next.t("validations.fill")}</Error>
              )}
          </Stack>
        ))}
        <FlexCenter>
          <CustomButton
            bgColor="#fff"
            fs="20px"
            value={<Add />}
            color="#000"
            type="button"
            width="123px"
            height="32px"
            padding="0"
            fw="700"
            onClick={() => {
              const value = clientData.brand
                ? [...clientData.brand!, ""]
                : [""];
              onChange(value, "brand");
            }}
          />
        </FlexCenter>

        {clientData.contacts.map((contact, idx) => (
          <Stack key={idx} gap={"20px"}>
            <FlexBetween gap="10px">
              <h3>Контактное лицо {idx + 1}</h3>
              <h4
                onClick={() => {
                  if (clientData.contacts.length === 1) {
                    const value = [{ phone_number: "", person_contact: "" }];
                    onChange(
                      selectedContact ? clientData.contacts : value,
                      "contacts"
                    );
                    const removedValue = clientData.contacts.filter(
                      (contact, id) => idx === id
                    );
                    setRemovedContacts((prevState) =>
                      prevState
                        ? [...prevState, removedValue[0]]
                        : [removedValue[0]]
                    );
                  } else {
                    const value = clientData.contacts.filter(
                      (contact, id) => idx !== id
                    );
                    onChange(value, "contacts");
                    const removedValue = clientData.contacts.filter(
                      (contact, id) => idx === id
                    );
                    setRemovedContacts((prevState) =>
                      prevState
                        ? [...prevState, removedValue[0]]
                        : [removedValue[0]]
                    );
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                X
              </h4>
            </FlexBetween>

            <InputWrapper>
              <span>Контактное лицо</span>
              <CustomInput
                name="full_name"
                showError={checkErrors && !contact.full_name.trim()}
                value={contact.full_name}
                // onChange={onChange}
                onChange={(e, name) => {
                  if (!cannotChange) {
                    let value = [...clientData.contacts];
                    value[idx] = {
                      ...value[idx],
                      full_name: String(e),
                    };
                    console.log(value, name);
                    onChange(value, "contacts");
                  } else return undefined;
                }}
                fullWidth
                type="text"
                height="33px"
                pl="10px"
              />
            </InputWrapper>
            <InputWrapper>
              <span> {t("common.job_title")}</span>
              <CustomInput
                name="job_title"
                showError={
                  checkErrors &&
                  !contact.job_title.trim() &&
                  ((!!fillPricingData &&
                    fillPricingData?.step !== "introduction") ||
                    (!!editingApp && editingApp?.step !== "introduction"))
                }
                value={contact.job_title}
                // onChange={onChange}
                onChange={(e, name) => {
                  if (!cannotChange) {
                    let value = [...clientData.contacts];
                    value[idx] = {
                      ...value[idx],
                      job_title: String(e),
                    };
                    console.log(value, name);
                    onChange(value, "contacts");
                  } else return undefined;
                }}
                fullWidth
                type="text"
                height="33px"
                pl="10px"
              />
            </InputWrapper>
            <InputWrapper>
              <span> Телеграм</span>
              <CustomInput
                name="telegram"
                showError={
                  checkErrors &&
                  !(
                    contact.telegram.trim() ||
                    (contact.phone_number && contact.phone_number.length >= 13)
                  )
                }
                value={contact.telegram}
                // onChange={onChange}
                onChange={(e, name) => {
                  if (!cannotChange) {
                    let value = [...clientData.contacts];
                    value[idx] = {
                      ...value[idx],
                      telegram: String(e),
                    };
                    console.log(value, name);
                    onChange(value, "contacts");
                  } else return undefined;
                }}
                fullWidth
                type="text"
                height="33px"
                pl="10px"
              />
            </InputWrapper>
            <InputWrapper>
              <span>Телефон</span>
              <CustomInput
                name="phone_number"
                showError={
                  checkErrors &&
                  !selectedContact &&
                  !(
                    contact.telegram ||
                    (contact.phone_number && contact.phone_number.length >= 13)
                  )
                }
                value={contact.phone_number}
                // onChange={onChange}
                onChange={(e, name) => {
                  if (!cannotChange) {
                    let value = [...clientData.contacts];
                    value[idx] = {
                      ...value[idx],
                      phone_number: String(e) === "undefined" ? "" : String(e),
                    };
                    console.log(value);

                    onChange(value, "contacts");
                  } else return undefined;
                }}
                fullWidth
                type="number"
                height="33px"
                pl="10px"
              />
            </InputWrapper>
          </Stack>
        ))}
        <FlexCenter>
          <CustomButton
            bgColor="#fff"
            fs="20px"
            value={<Add />}
            color="#000"
            type="button"
            width="123px"
            height="32px"
            padding="0"
            fw="700"
            onClick={() => {
              const value = [
                ...clientData.contacts,
                { person_contact: "", phone_number: "" },
              ];
              onChange(value, "contacts");
            }}
          />
        </FlexCenter>
      </CardBody>
    </Card>
  );
};

export default ClientCard;
// UseReplace("search-client-name", String(e))
