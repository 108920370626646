import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpRequest } from "../../utils/request";
import { managerT } from "../../types/commonTypes";

export const getManagersThunk = createAsyncThunk(
  "main/getManagersThunk",
  async (searchEmployee: string, thunkAPI): Promise<managerT[]> =>
    await thunkAPI.dispatch(
      httpRequest({
        url: `employee/managers/list/?${
          searchEmployee ? `name=${searchEmployee}` : ""
        }`,
      })
    )
);
