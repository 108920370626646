import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { Text } from "../../../../globalStyle";
import {
  FlexBetween,
  FlexCenter,
  FlexWrapper,
} from "../../../Common/FlexWrapper";
import Chart from "react-apexcharts";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import {
  downloadStatistics,
  downloadTopWorkStatistics,
  downloadTypeOfWorkStatistics,
  getLegalOrdersStatistics,
  getLegalPartnersStatistics,
  getLegalStatusFinishedStatistics,
  getManagerActivities,
  getManagerStatistics,
  getPhysicalInterestsStatistics,
  getPhysicalOrdersStatistics,
} from "../../../../store/actions/statisticsActions";
import "dayjs/locale/en-gb";
import CustomButton from "../../../Common/CustomButton";
import { ChartWrapper, Col, ColItem, VerticalDivider } from "./style";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "../../../Common/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import useSearch from "../../../../hooks/useSearch";
import { useLocation, useNavigate } from "react-router-dom";
import UseReplace from "../../../../hooks/useReplace";
import { prettierNum } from "../../../../utils/helpers";

const Main = () => {
  const query = useSearch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    types,
    legalStatusFinishedStatistics,
    managerStatistics,
    managerActivities,
    physicalOrdersStatistics,
    legalOrdersStatistics,
    legalPartnersStatistics,
    physicalInterestsStatistics,
  } = useAppSelector((state) => state.statistics);
  const { profile } = useAppSelector((state) => state.settings);
  const [legalPartnersStatisticsColors, setLegalPartnersStatisticsColors] =
    useState<string[]>();
  const [
    legalStatusFinishedStatisticsColors,
    setLegalStatusFinishedStatisticsColors,
  ] = useState<string[]>();
  const [managerStatisticsColors, setManagerStatisticsColors] =
    useState<string[]>();
  const [managerActivitiesColors, setManagerActivitiesColors] =
    useState<string[]>();
  const [physicalOrdersStatisticsColors, setPhysicalOrdersStatisticsColors] =
    useState<string[]>();
  const [legalOrdersStatisticsColors, setLegalOrdersStatisticsColors] =
    useState<string[]>();
  const [
    physicalInterestsStatisticsColors,
    setPhysicalInterestsStatisticsColors,
  ] = useState<string[]>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    navigate("/statistics");
    dispatch(getLegalPartnersStatistics({}));
    dispatch(getLegalStatusFinishedStatistics({}));
    dispatch(getManagerStatistics({}));
    dispatch(getManagerActivities({}));
    dispatch(getPhysicalOrdersStatistics({}));
    dispatch(getLegalOrdersStatistics({}));
    dispatch(getPhysicalInterestsStatistics({}));
  }, [dispatch]);
  function generateRandomColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgb(${red}, ${green}, ${blue})`;
  }
  const hasPermission = ["director", "executive_director"];
  useEffect(() => {
    if (legalPartnersStatistics.length > 0 && !legalPartnersStatisticsColors) {
      const updatedColors = legalPartnersStatistics
        .filter((status) => status.title !== "total")
        .map((item) => generateRandomColor());
      setLegalPartnersStatisticsColors(updatedColors);
    }
  }, [legalPartnersStatistics]);
  useEffect(() => {
    if (
      legalStatusFinishedStatistics.length > 0 &&
      !legalStatusFinishedStatisticsColors
    ) {
      const updatedColors = legalStatusFinishedStatistics
        .filter((status) => status.title !== "total")
        .map((item) => generateRandomColor());
      setLegalStatusFinishedStatisticsColors(updatedColors);
    }
  }, [legalStatusFinishedStatistics]);
  useEffect(() => {
    if (managerStatistics.length > 0 && !managerStatisticsColors) {
      const updatedColors = managerStatistics
        .filter((status) => status.title !== "total")
        .map((item) => generateRandomColor());
      setManagerStatisticsColors(updatedColors);
    }
  }, [managerStatistics]);
  useEffect(() => {
    if (managerActivities.length > 0 && !managerActivitiesColors) {
      const updatedColors = managerActivities
        .filter((status) => status.title !== "total")
        .map((item) => generateRandomColor());
      setManagerActivitiesColors(updatedColors);
    }
  }, [managerActivities]);
  useEffect(() => {
    if (
      physicalOrdersStatistics.length > 0 &&
      !physicalOrdersStatisticsColors
    ) {
      const updatedColors = physicalOrdersStatistics
        .filter((status) => status.title !== "total")
        .map((item) => generateRandomColor());
      setPhysicalOrdersStatisticsColors(updatedColors);
    }
  }, [physicalOrdersStatistics]);
  useEffect(() => {
    if (legalOrdersStatistics.length > 0 && !legalOrdersStatisticsColors) {
      const updatedColors = legalOrdersStatistics
        .filter((status) => status.title !== "total")
        .map((item) => generateRandomColor());
      setLegalOrdersStatisticsColors(updatedColors);
    }
  }, [legalOrdersStatistics]);
  useEffect(() => {
    if (
      physicalInterestsStatistics.length > 0 &&
      !physicalInterestsStatisticsColors
    ) {
      const updatedColors = physicalInterestsStatistics
        .filter((status) => status.title !== "total")
        .map((item) => generateRandomColor());
      setPhysicalInterestsStatisticsColors(updatedColors);
    }
  }, [physicalInterestsStatistics]);

  const chartList = [
    {
      chartTitle: "Партнёры ассоциации",
      labels: legalPartnersStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.title),
      colors: legalPartnersStatisticsColors,
      series: legalPartnersStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.value),
      filterDispatchFunction: () =>
        dispatch(getLegalPartnersStatistics({ query })),
      // total:
      //   legalPartnersStatistics?.find((item: any) => item.title === "total")
      //     ?.value || 0,
    },
    {
      chartTitle: "Статусы Партнёров МАУ",
      labels: legalOrdersStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.title),
      colors: legalOrdersStatisticsColors,
      series: legalOrdersStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.value),
      filterDispatchFunction: () =>
        dispatch(getLegalOrdersStatistics({ query })),
      // total: legalOrdersStatistics?.find((item: any) => item.title === "total")
      //   ?.value,
    },
    {
      chartTitle: "Успешно реализованные по менеджерам",
      labels: legalStatusFinishedStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.title),
      colors: legalStatusFinishedStatisticsColors,
      series: legalStatusFinishedStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.value),
      filterDispatchFunction: () =>
        dispatch(getLegalStatusFinishedStatistics({ query })),
      // total: legalStatusFinishedStatistics?.find(
      //   (item: any) => item.title === "total"
      // )?.value,
    },
    {
      chartTitle: "Интересы ЧЛ",
      labels: physicalInterestsStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.title),
      colors: physicalInterestsStatisticsColors,
      series: physicalInterestsStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.value),
      filterDispatchFunction: () =>
        dispatch(getPhysicalInterestsStatistics({ query })),
      // total: physicalInterestsStatistics?.find(
      //   (item: any) => item.title === "total"
      // )?.value,
    },
    {
      chartTitle: "Активность менеджеров",
      labels: managerStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.title),
      colors: managerStatisticsColors,
      series: managerStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.value),
      filterDispatchFunction: () => dispatch(getManagerStatistics({ query })),
      // total: managerStatistics?.find((item: any) => item.title === "total")
      //   ?.value,
    },

    {
      chartTitle: "Статусы ЧЛ",
      labels: physicalOrdersStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.title),
      colors: physicalOrdersStatisticsColors,
      series: physicalOrdersStatistics
        .filter((status) => status.title !== "total")
        .map((status) => status.value),
      filterDispatchFunction: () =>
        dispatch(getPhysicalOrdersStatistics({ query })),
      // total: physicalOrdersStatistics?.find(
      //   (item: any) => item.title === "total"
      // )?.value,
    },
  ];
  console.log(managerActivities, "chartlistLog");

  return (
    <>
      <Stack
        p="17px 28px"
        bgcolor="#fcfcfc"
        borderRadius="9px"
        position="relative"
      >
        {hasPermission.includes(profile.role) && (
          <Stack>
            <Text fs="24px" fw="600" c="var(--primary)">
              Линейный график по активности менеджеров
            </Text>
            <Chart
              options={{
                // series: [
                //   {
                //     data: [21, 22, 10, 28, 16, 21, 13, 30],
                //   },
                // ],
                chart: {
                  height: 250,
                  type: "bar",
                  events: {
                    click: function (chart, w, e) {
                      // console.log(chart, w, e)
                    },
                  },
                },
                // colors: colors,
                plotOptions: {
                  bar: {
                    columnWidth: "45%",
                    distributed: true,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                legend: {
                  show: false,
                },
                tooltip: {
                  y: {
                    //   formatter: function (val) {
                    //     return "";
                    //   },
                    title: {
                      formatter: function (seriesName) {
                        return "";
                      },
                    },
                  },
                },
                xaxis: {
                  categories: managerActivities.map((item) => [item.title]),
                  labels: {
                    style: {
                      colors: managerActivitiesColors,
                      fontSize: "12px",
                    },
                  },
                },
              }}
              series={[
                {
                  data: managerActivities.map((item) => item.total_activity),
                },
              ]}
              type="bar"
              // width={"100%"}
              height={350}
            />
            <FlexBetween gap="2rem" ml="auto" pr={"30px"} mt="auto" mb="10px">
              <LocalizationProvider
                adapterLocale="en-gb"
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  color="#E0E0E0"
                  keyPicker="date_from"
                  title="От"
                  value={
                    query.get("date_from")
                      ? dayjs(query.get("date_from"))
                      : null
                  }
                  onChangePicker={(e) =>
                    navigate(
                      `${pathname}${UseReplace(
                        "date_from",
                        String(e?.format().split("T")[0])
                      )}`
                    )
                  }
                  width="85px"
                  pl="15px"
                />
                <DatePicker
                  color="#E0E0E0"
                  keyPicker="date_to"
                  title="До"
                  value={
                    query.get("date_to") ? dayjs(query.get("date_to")) : null
                  }
                  onChangePicker={(e) =>
                    navigate(
                      `${pathname}${UseReplace(
                        "date_to",
                        String(e?.format().split("T")[0])
                      )}`
                    )
                  }
                  width="85px"
                  pl="15px"
                />
              </LocalizationProvider>
              <CustomButton
                color="#fff"
                bgColor="var(--primary)"
                value={"Фильтр"}
                fs="16px"
                fw="500"
                type="button"
                loading={false}
                padding="6px 15px"
                width="fit-content"
                onClick={() => dispatch(getManagerActivities({ query }))}
              />
            </FlexBetween>
          </Stack>
        )}
        <FlexWrapper gap="30px" flexWrap="wrap">
          {chartList.map((chart) => (
            <Stack width="calc(50% - 15px)">
              <FlexBetween>
                <Text fs="24px" fw="600" c="var(--primary)">
                  {chart.chartTitle}
                </Text>
                {/* <CustomButton
                bgColor="var(--primary)"
                fs="14px"
                value="Сдача"
                color="#fff"
                type="button"
                height="21px"
                fw="700"
                width="83px"
                onClick={() =>
                  dispatch(
                    downloadStatistics({
                      date_from: dateFromType,
                      date_to: dateToType,
                    })
                  )
                }
              /> */}
              </FlexBetween>
              <Chart
                options={{
                  chart: {
                    type: "donut",
                    width: "100%",
                  },
                  legend: { width: 200 },
                  plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          show: true,
                          value: {
                            formatter: function (value) {
                              return prettierNum(+value);
                            },
                          },
                          total: {
                            show: true,
                            color: "#282C2D",
                            label:
                              chart.chartTitle ===
                              "Успешно реализованные по менеджерам"
                                ? "Общая сумма"
                                : "Общее количество",
                            fontSize: "13px",
                            fontWeight: "500",
                            formatter: function (w) {
                              return prettierNum(
                                +w.globals.series.reduce(
                                  (accumulator: any, currentValue: any) =>
                                    accumulator + currentValue,
                                  0
                                )
                              );
                            },
                          },
                        },
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                  },

                  labels: chart.labels,
                  colors: chart.colors,
                }}
                series={chart.series}
                type="donut"
                width={"100%"}
              />
              <FlexBetween pr={"30px"} mt="auto">
                <LocalizationProvider
                  adapterLocale="en-gb"
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    color="#E0E0E0"
                    keyPicker="date_from"
                    title="От"
                    value={
                      query.get("date_from")
                        ? dayjs(query.get("date_from"))
                        : null
                    }
                    onChangePicker={(e) =>
                      navigate(
                        `${pathname}${UseReplace(
                          "date_from",
                          String(e?.format().split("T")[0])
                        )}`
                      )
                    }
                    width="85px"
                    pl="15px"
                  />
                  <DatePicker
                    color="#E0E0E0"
                    keyPicker="date_to"
                    title="До"
                    value={
                      query.get("date_to") ? dayjs(query.get("date_to")) : null
                    }
                    onChangePicker={(e) =>
                      navigate(
                        `${pathname}${UseReplace(
                          "date_to",
                          String(e?.format().split("T")[0])
                        )}`
                      )
                    }
                    width="85px"
                    pl="15px"
                  />
                </LocalizationProvider>
                <CustomButton
                  color="#fff"
                  bgColor="var(--primary)"
                  value={"Фильтр"}
                  fs="16px"
                  fw="500"
                  type="button"
                  loading={false}
                  padding="6px 15px"
                  width="fit-content"
                  onClick={chart.filterDispatchFunction}
                />
              </FlexBetween>
            </Stack>
          ))}

          <VerticalDivider />
        </FlexWrapper>

        {/* <VerticalDivider /> */}
      </Stack>
    </>
  );
};

export default Main;
