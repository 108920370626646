import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { FlexBetween, FlexWrapper } from "../../Common/FlexWrapper";
import { useDrop } from "react-dnd";
import { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd/dist/hooks";
import { setEditingApp } from "../../../store/slices/PCApplicationsSlice";
import { Text } from "../../../globalStyle";
import type { Identifier, XYCoord } from "dnd-core";
import dateIcon from "../../../assets/icons/process/date.svg";
import dateExpiredIcon from "../../../assets/icons/process/date-green.svg";
import timeIcon from "../../../assets/icons/process/time.svg";
import timeExpiredIcon from "../../../assets/icons/process/time-green.svg";
import { handleOpen } from "../../../store/slices/PCApplicationsSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { CancelReason } from "../style";
import CustomCheckbox from "../../Common/CustomCheckbox";
import { setSelectedItems } from "../../../store/slices/PCApplicationsSlice";
import { PCAppsDataT } from "../../../types/commonTypes";

type T = {
  item: PCAppsDataT;
  index: number;
  step: string;
};

const Application: React.FC<T> = ({ item, index, step }) => {
  const { selectedItems } = useAppSelector((state) => state.PCApplications);
  const { profile } = useAppSelector((state) => state.settings);
  const ref = useRef<HTMLDivElement>(null);

  const {
    client,
    reason,
    id,
    status_list,
    started_at,
    manager,
    is_expired,
    deadline,
  } = item;
  const statusTypes = [status_list.map((item) => item?.name)].join(", ");
  const { company_name, full_name, telegram } = client;
  const { first_name, last_name } = manager;
  // const [date, time] = created_at.split(", ");
  const hasPermission = [
    "director",
    "executive_director", 
    "head_community_department",
  ];
  const myDate = new Date();

  const today = [
    myDate.getDate() + 1 > 10 ? myDate.getDate() : "0" + myDate.getDate(),
    myDate.getMonth() + 1 > 9
      ? myDate.getMonth() + 1
      : "0" + (myDate.getMonth() + 1),
    myDate.getFullYear(),
  ].join("-");

  const dispatch = useAppDispatch();

  const [, drop] = useDrop<PCAppsDataT, void, { handlerId: Identifier | null }>(
    {
      accept: "app",
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item: PCAppsDataT, monitor) {
        if (!ref.current) return;
        const dragIndex = item.index!;
        const hoverIndex = index;
        if (hoverIndex === dragIndex) return;

        const hoveredRect = ref?.current?.getBoundingClientRect();
        const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
        const mousePosition = monitor?.getClientOffset();
        const hoveredClientY = (mousePosition as XYCoord).y - hoveredRect.top;

        if (dragIndex < hoverIndex && hoveredClientY < hoverMiddleY) return;
        if (dragIndex > hoverIndex && hoveredClientY > hoverMiddleY) return;

        // for changing order of applications in column
        // moveItem(dragIndex, hoverIndex, item.step);

        item.index = hoverIndex;
      },
    }
  );
  const [isExpiredApplication, setIsExpiredApplication] = useState(false);
  useEffect(() => {
    setIsExpiredApplication(is_expired && !deadline);
  }, [is_expired, deadline]);
  const [{ isDragging }, drag] = useDrag({
    type: "app",
    // canDrag: () => {
    //   return false;
    // },
    item: () => ({ ...item, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Stack
      width={"100%"}
      ref={ref}
      // data-handler-id={handlerId}
      bgcolor={
        isExpiredApplication
          ? "var(--primary)"
          : isDragging
          ? "#FFD585"
          : "#FCFCFC"
      }
      borderRadius="6px"
      boxShadow={"0px 4px 6px -2px rgba(0, 0, 0, 0.1)"}
      p="11px"
      style={{ cursor: "move", transition: "1s", position: "relative" }}
      mt="10px"
      onClick={(e) => {
        if (e.detail === 2) {
          dispatch(setEditingApp(item));
          dispatch(handleOpen("add-application"));
        }
      }}
    >
      {hasPermission.includes(profile.role) && (
        <CustomCheckbox
          id={id!}
          selectedItems={selectedItems}
          onClick={() => dispatch(setSelectedItems(id!))}
          style={{ top: "0", right: "0", position: "absolute" }}
        />
      )}

      <Stack gap="10px" width="100%">
        <Stack>
          <Text fs="14px" fw="500">
            {full_name}
          </Text>
          <Text fs="12px" fw="400" c="#828282">
            {telegram}
          </Text>
        </Stack>
        <CancelReason>
          {reason?.length > 15 ? reason?.slice(0, 15) + "..." : reason}
        </CancelReason>
        <FlexBetween width="100%">
          <FlexWrapper gap="4px" alignItems="center">
            <img
              src={isExpiredApplication ? dateExpiredIcon : dateIcon}
              alt=""
              style={{ marginBottom: "2px" }}
            />
            <Text
              fs="10px"
              fw="400"
              c={
                today === started_at?.split(", ")[0] || isExpiredApplication
                  ? "#32CD32"
                  : "var(--primary)"
              }
            >
              {started_at?.split(", ")[0]}
            </Text>
          </FlexWrapper>
          <FlexWrapper gap="4px">
            <img
              src={isExpiredApplication ? timeExpiredIcon : timeIcon}
              alt=""
              style={{ marginBottom: "2px" }}
            />
            <Text
              fs="10px"
              fw="400"
              c={
                today === started_at?.split(", ")[1] || isExpiredApplication
                  ? "#32CD32"
                  : "var(--primary)"
              }
            >
              {started_at?.split(", ")[1]}
            </Text>
          </FlexWrapper>
        </FlexBetween>
      </Stack>

      <Divider style={{ margin: "6px 0" }} />

      <Text fs="12px" fw="300" c="#828282">
        {statusTypes.length > 70
          ? statusTypes.substring(0, 70) + "..."
          : statusTypes}
      </Text>

      <Text fs="12px" fw="400" c="#282C2D" style={{ marginTop: "10px" }}>
        {first_name} {last_name}
      </Text>
    </Stack>
  );
};

export default Application;
