import { Box, Stack } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import CustomInput from "../Common/CustomInput";
import { useLocation, useNavigate } from "react-router";
import filter from "../../assets/icons/filter.svg";
import UseReplace from "../../hooks/useReplace";
import searchIcon from "../../assets/icons/search.svg";
import { useTranslation } from "react-i18next";
import { Text } from "../../globalStyle";
import { FlexBetween, FlexWrapper } from "../Common/FlexWrapper";
import MonitoringTable from "./MonitoringTable";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { nameTypes } from "../PCApplications/data";
import SaveIcon from "@mui/icons-material/Save";
import CustomButton from "../Common/CustomButton";
import { handleClose, handleOpen } from "../../store/slices/monitoringSlice";
import CommonModal from "../Common/CustomModal";
import Filter from "./Filter";
import {
  downloadReportLCClients,
  downloadReportPCClients,
} from "../../store/actions/clientsListActions";
import useSearch from "../../hooks/useSearch";
import {
  getMonitoringAppList,
  getMonitoringClientList,
} from "../../store/actions/monitoringActions";

type Props = {};

const Monitoring = (props: Props) => {
  const { pending, openModal, applicationsList, clientsList } = useAppSelector(
    (state) => state.monitoring
  );
  const [activeType, setActiveType] = useState<string>(
    window.location.pathname.split("/")[2]
  );
  const [searchValue, setSearchValue] = useState("");
  const [finalValue, setFinalValue] = useState<string>("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  console.log(window.location.pathname === "/monitoring", "logqilib");

  useEffect(() => {
    const delay = 500; // Adjust the delay as needed (e.g., 500 milliseconds)
    const timeoutId = setTimeout(() => {
      setFinalValue(searchValue);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);

  useEffect(() => {
    navigate(
      `${pathname}${UseReplace("search-name", String(finalValue.trim()))}`
    );
  }, [finalValue]);
  const onChange = (value: string | number, name: string) => {
    setSearchValue(String(value));
  };
  let loadingExcelReport = false;

  const [anchorElSelector, setAnchorElSelector] =
    React.useState<null | HTMLElement>(null);
  const openSelector = Boolean(anchorElSelector);
  const dispatch = useAppDispatch();
  const query = useSearch();
  useEffect(() => {
    setActiveType(window.location.pathname.split("/")[2]);
    window.location.pathname === "/monitoring" &&
      navigate("/monitoring/application");
  }, [window.location.pathname]);
  return (
    <div>
      <Stack gap="14px" width="100%">
        <FlexBetween width="100%">
          <Text fw="600" fs="24px" style={{ marginBottom: "15px" }}>
            {t("monitoring.title")}{" "}
          </Text>
        </FlexBetween>
      </Stack>
      <FlexBetween>
        <Box position={"relative"} width="100%" marginRight={"10px"}>
          <img
            src={searchIcon}
            alt=""
            style={{ position: "absolute", right: "10px", top: "10px" }}
          />

          <CustomInput
            name="search"
            showError={false}
            value={searchValue}
            errorText={t("validations.fill")!}
            onChange={onChange}
            fullWidth
            height="41px"
            placeholder="Введите данные поиска..."
          />
        </Box>

        <div style={{ marginLeft: "10px" }}>
          <CustomButton
            bgColor="#e1e1e1"
            value={<img src={filter} alt="" />}
            fs="19px"
            type="button"
            aria-controls={openSelector ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openSelector ? "true" : undefined}
            loading={false}
            height="43px"
            width="50px"
            onClick={() => {
              dispatch(handleOpen("filter"));
              setAnchorElSelector(null);
            }}
          />
        </div>
        <CommonModal
          open={openModal === "filter"}
          handleClose={() => dispatch(handleClose())}
        >
          <Filter handleClose={() => dispatch(handleClose())} />
        </CommonModal>
      </FlexBetween>
      <FlexWrapper mt="10px" gap="10px">
        <CustomButton
          bgColor={activeType === "client" ? "#FFC491" : "#e1e1e1"}
          value={t("monitoring.client")}
          fs="19px"
          type="button"
          padding="5px"
          loading={pending}
          height="43px"
          width="max-content"
          onClick={() => {
            navigate("/monitoring/client");
            dispatch(getMonitoringClientList({}));
            setActiveType("client");
          }}
        />
        <CustomButton
          bgColor={activeType === "application" ? "#FFC491" : "#e1e1e1"}
          value={t("monitoring.application")}
          fs="19px"
          type="button"
          loading={pending}
          height="43px"
          padding="5px"
          width="max-content"
          onClick={() => {
            navigate("/monitoring/application");
            dispatch(getMonitoringAppList({}));
            setActiveType("application");
          }}
        />
      </FlexWrapper>
      <h3 style={{ marginTop: "1rem" }}>
        Общее количество :{" "}
        {window.location.pathname === "/monitoring/application"
          ? applicationsList.count
          : clientsList.count}
      </h3>
      <MonitoringTable searchValue={finalValue} />
    </div>
  );
};

export default Monitoring;
