import { SelectChangeEvent, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Text } from "../../globalStyle";
import CustomButton from "../Common/CustomButton";
import CustomSelect from "../Common/CustomSelect";
import dayjs, { Dayjs } from "dayjs";
import { getTypes } from "../../store/actions/settingsActions";
import useSearch from "../../hooks/useSearch";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useLocation, useNavigate } from "react-router-dom";
import UseReplace from "../../hooks/useReplace";
import { useTranslation } from "react-i18next";
import "dayjs/locale/en-gb";
import { FilterWrapper } from "../PCApplications/style";
import {
  getPCApplications,
  getPCApplicationsByStep,
} from "../../store/actions/PCApplicationsActions";
import { FlexWrapper } from "../Common/FlexWrapper";
import { Replay } from "@mui/icons-material";
import {
  setPCAppsByStepPending,
  setAppsData,
} from "../../store/slices/PCApplicationsSlice";
import { statusT } from "../../types/commonTypes";
import { nameTypes } from "../PCApplications/data";
import {
  getLCClientsList,
  getPCClientsList,
} from "../../store/actions/clientsListActions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "../Common/DatePicker";
import Autocomplete from "../Common/CustomAutocomplete";
import { getEmployeesList } from "../../store/actions/employeesActions";
import { getManagersThunk } from "../../store/actions/mainActions";
import {
  getMonitoringAppList,
  getMonitoringClientList,
} from "../../store/actions/monitoringActions";

const Filter: React.FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  const { Data, profile } = useAppSelector((state) => state.settings);
  const { managers } = useAppSelector((state) => state.main);

  const { dataList: employees } = useAppSelector((state) => state.employees);
  const { about, activities, lsStatuses, pcStatuses } = Data;
  const query = useSearch();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");

  const handleInputChange = (value: string) => {
    dispatch(getEmployeesList({ name: value }));
    setInputValue(value);
    setAutoCompleteOptions(employees?.results);
  };
  // const appTypes = t("applications.applicationsTypes", {
  //   returnObjects: true,
  // }) as statusT[];
  const onChangePicker = (value: Dayjs | null, keyPicker: string) => {
    const date = `${value?.year()}-${
      value?.month()! + 1 > 9
        ? value?.month()! + 1
        : "0" + (value?.month()! + 1)
    }-${value?.date()}`;
    navigate(`${pathname}${UseReplace(keyPicker, date)}`);
  };
  const onChange = (event: SelectChangeEvent, name: string) => {
    navigate(`${pathname}${UseReplace(name, event.target.value)}`);
  };
  useEffect(() => {
    if (employees.results) {
      setAutoCompleteOptions(
        employees.results.map((value) => ({
          id: Number(value?.id),
          name: value?.first_name + " " + value?.last_name,
        }))
      );
    }
  }, [employees, dispatch]);
  useEffect(() => {
    dispatch(getTypes({ url: "legal-settings", dataType: "lsStatuses" }));
    dispatch(getTypes({ url: "physical-settings", dataType: "pcStatuses" }));
    dispatch(getTypes({ url: "activity" }));
    dispatch(getTypes({ url: "activity" }));
    dispatch(getManagersThunk(""));
    dispatch(getEmployeesList({}));
    dispatch(getTypes({ url: "survey", dataType: "about" }));
  }, [dispatch]);

  return (
    <Stack
      gap="26px"
      bgcolor={"#FCFCFC"}
      borderRadius="5px"
      boxShadow="0px 4px 6px -3px rgba(0, 0, 0, 0.1)"
      padding="15px 37px 20px 37px"
      alignItems="center"
      position="relative"
    >
      <Text c="#4f4f4f" fs="24px" fw="700">
        {t("filter.filter")}
      </Text>
      <Stack gap="50px" direction="row">
        <Stack gap="20px" width="50%">
          {/* {hasPermission.includes(profile.role) && ( */}
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              Менеджер
            </Text>
            <CustomSelect
              width="261px"
              value={query.get("manager") || ""}
              values={managers?.map((item) => ({
                id: item.id,
                name: `${item.first_name} ${item.last_name}`,
              }))}
              onChange={(e) => onChange(e, "manager")}
            />
          </FilterWrapper>
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              От куда о нас узнали
            </Text>
            <CustomSelect
              width="261px"
              value={query.get("survey")!}
              values={about}
              onChange={(e) => onChange(e, "survey")}
            />
          </FilterWrapper>
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              {t("common.activity")}
            </Text>
            <CustomSelect
              width="261px"
              value={query.get("activity") || ""}
              values={activities}
              onChange={(e) => onChange(e, "activity")}
            />
          </FilterWrapper>
        </Stack>
        <Stack gap="20px" width="50%">
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              {t("common.changed_by")}
            </Text>
            <CustomSelect
              width="261px"
              value={query.get("changed_by") || ""}
              values={employees?.results?.map((value) => ({
                id: Number(value?.id),
                name: value?.first_name + " " + value?.last_name,
              }))}
              onChange={(e) => onChange(e, "changed_by")}
            />
          </FilterWrapper>
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              {t("common.changed_type")}
            </Text>
            <CustomSelect
              width="261px"
              value={query.get("changed_type") || ""}
              values={[
                { id: "post", name: "Создано" },
                { id: "update", name: "Обновленный" },
                { id: "delete", name: "Удалено" },
                { id: "restore", name: "Восстановлено" },
              ]}
              onChange={(e) => onChange(e, "changed_type")}
            />
          </FilterWrapper>
          {/* )} */}
          <FilterWrapper>
            <Text c="#828282" fs="9px" fw="400">
              {t("filter.time")}
            </Text>
            <Stack direction={"row"} gap="15px">
              <LocalizationProvider
                adapterLocale="en-gb"
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  keyPicker="date_from"
                  title={t("filter.from")}
                  value={dayjs(query.get("date_from")) || dayjs("2023-01-01")}
                  onChangePicker={onChangePicker}
                />

                <DatePicker
                  keyPicker="date_to"
                  title={t("filter.to")}
                  value={dayjs(query.get("date_to")) || dayjs("2023-01-01")}
                  onChangePicker={onChangePicker}
                />
              </LocalizationProvider>
            </Stack>
          </FilterWrapper>
        </Stack>
      </Stack>

      <Stack direction={"row"} gap="17px" alignItems="center">
        <Text
          c="#6C6C6C"
          fs="20px"
          fw="700"
          onClick={handleClose}
          style={{ cursor: "pointer" }}
        >
          {t("common.cancel")}
        </Text>
        <CustomButton
          bgColor="var(--primary)"
          fs="20px"
          value="Поиск"
          color="#fff"
          type="button"
          width="123px"
          height="32px"
          padding="0"
          fw="700"
          onClick={() => {
            handleClose();
            if (window.location.pathname.split("/")[2] === "application") {
              dispatch(
                getMonitoringAppList({
                  query,
                })
              );
            } else if (window.location.pathname.split("/")[2] === "client") {
              dispatch(
                getMonitoringClientList({
                  query,
                })
              );
            }
          }}
        />
      </Stack>

      <FlexWrapper
        position="relative"
        bottom="0"
        right="0"
        color="#828282"
        alignItems="center"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          if (window.location.pathname.split("/")[2] === "application") {
            dispatch(getMonitoringAppList({}));
          } else if (window.location.pathname.split("/")[2] === "client") {
            dispatch(getMonitoringClientList({}));
          }
          navigate(`/monitoring/${window.location.pathname.split("/")[2]}`);
          handleClose();
        }}
      >
        <Replay fontSize="small" />
        <Text c="#828282" fs="14px" fw="400">
          Сбросить
        </Text>
      </FlexWrapper>
    </Stack>
  );
};

export default Filter;
