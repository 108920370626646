import {
  Autocomplete,
  Box,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { Text } from "../../../../globalStyle";
import CustomSelect from "../../../Common/CustomSelect";
import { FlexBetween, FlexWrapper } from "../../../Common/FlexWrapper";
import DatePicker from "../../../Common/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomButton from "../../../Common/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { useLocation, useNavigate } from "react-router-dom";
import UseReplace from "../../../../hooks/useReplace";
import "dayjs/locale/en-gb";
import useSearch from "../../../../hooks/useSearch";
import { downloadStatisticsByFilter } from "../../../../store/actions/statisticsActions";
import { SearchInputWrapper } from "../Header/style";
import CustomInput from "../../../Common/CustomInput";
import searchIcon from "../../../../assets/icons/applications/search.svg";
import { useEffect, useState } from "react";
import { getPCContact } from "../../../../store/actions/PCContactActions";

const Card: React.FC<{
  item: { title: string; url: string };
  isLegal?: boolean;
}> = ({ item, isLegal }) => {
  const { managers } = useAppSelector((state) => state.main);
  const { data: contacts } = useAppSelector((state) => state.PCContact);
  const { profile, Data } = useAppSelector((state) => state.settings);
  const { pcStatuses, lsStatuses } = Data;
  const [inputValue, setInputValue] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useSearch();
  const onChangeSelect = (event: SelectChangeEvent, filterType: string) =>
    navigate(`${pathname}${UseReplace(filterType, event.target.value)}`);

  // const onChangeSelectWorkType = (event: SelectChangeEvent) =>
  //   navigate(`${pathname}${UseReplace("work", event.target.value)}`);

  const onChangePicker = (value: Dayjs | null, keyPicker: string) => {
    const date = `${value?.year()}-${
      value?.month()! + 1 > 9
        ? value?.month()! + 1
        : "0" + (value?.month()! + 1)
    }-${value?.date()}`;
    navigate(`${pathname}${UseReplace(keyPicker, date)}`);
  };
  const optionsRecommended: { id: number | string; name: string }[] =
    contacts.results
      ? [
          { id: "choose", name: "Выбирать" },
          { id: 99999, name: "По всем имени рекомендуемого" },
          ...contacts?.results?.map(({ name, id }: any) => ({
            name: name,
            id,
          })),
        ]
      : [];

  useEffect(() => {
    console.log(optionsRecommended);

    dispatch(getPCContact(query));
  }, [inputValue]);
  const hasPermission = [
    "director",
    "executive_director",
    "head_community_department",
  ];
  return (
    <Box
      pl="4px"
      borderRadius="9px"
      bgcolor="var(--primary)"
      boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1)"
      height="fit-content"
    >
      <Stack p="18px 24px" borderRadius="9px" gap="53px" bgcolor="#FCFCFC">
        <Text fw="600" fs="18px" c="#282C2D">
          {item.title}
        </Text>

        <Stack gap="19px">
          <FlexWrapper gap="18px">
            {profile.role !== "account_manager" &&
            item.url === "analytics/recomended/excel/" ? (
              <SearchInputWrapper>
                <Autocomplete
                  value={
                    optionsRecommended.find(
                      (item) => item.id == query.get("recomended")
                    ) || null
                  }
                  onChange={(
                    event: any,
                    newValue: { id: number | string | any; name: string } | null
                  ) => {
                    navigate(
                      `${pathname}${UseReplace("recomended", newValue?.id)}`
                    );
                  }}
                  getOptionDisabled={(option) => option.id === "choose"}
                  inputValue={query.get("search-recommended-client-name") || ""}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    console.log(newInputValue);
                    navigate(
                      `${pathname}${UseReplace(
                        "search-recommended-client-name",
                        newInputValue
                      )}`
                    );
                  }}
                  className="recommendedInput"
                  id="controllable-states-demo"
                  options={optionsRecommended}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: "100%", padding: 0 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Выбирать"
                      defaultValue={optionsRecommended[0]}
                    />
                  )}
                />
              </SearchInputWrapper>
            ) : (
              <>
                {(item.url === "excels/physical/manager/by/order/excel/" ||
                  item.url === "excels/legal/manager/by/order/excel/" ||
                  item.url === "excels/legal/client/contact/excel/") && (
                  <CustomSelect
                    width="100%"
                    value={query.get(`${item.url}-status`) || ""}
                    values={
                      isLegal
                        ? lsStatuses
                          ? [
                              { id: "", name: "По всем статусам" },
                              ...lsStatuses
                                ?.map(({ name, id }: any) => ({
                                  name: name,
                                  id,
                                }))
                                .slice(-3),
                            ]
                          : []
                        : pcStatuses
                        ? [
                            { id: "", name: "По всем статусам" },
                            ...pcStatuses?.map(({ name, id }: any) => ({
                              name: name,
                              id,
                            })),
                          ]
                        : []
                    }
                    onChange={(event) =>
                      onChangeSelect(event, `${item.url}-status`)
                    }
                    height="31px"
                    showError={false}
                  />
                )}
                {hasPermission.includes(profile.role) && (
                  <CustomSelect
                    width="100%"
                    value={query.get(`${item.url}-manager`) || ""}
                    values={[
                      { id: "", name: "По всем менеджерам" },
                      ...managers.map(({ first_name, last_name, id }) => ({
                        name: `${first_name} ${last_name}`,
                        id,
                      })),
                    ]}
                    onChange={(event) =>
                      onChangeSelect(event, `${item.url}-manager`)
                    }
                    height="31px"
                    showError={false}
                  />
                )}
              </>
            )}

            {/* {item.url === "analytics/successful/failed/project/excel/" && (
              <CustomSelect
                width="100%"
                value={query.get("work") || ""}
                values={workTypes}
                onChange={onChangeSelectWorkType}
                height="31px"
                showError={false}
              />
            )} */}
          </FlexWrapper>
          <FlexBetween>
            <LocalizationProvider
              adapterLocale="en-gb"
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                color="#E0E0E0"
                keyPicker={`${item.url}-date_from`}
                title="От"
                value={
                  dayjs(query.get(`${item.url}-date_from`)) ||
                  dayjs("2023-01-01")
                }
                onChangePicker={onChangePicker}
                width="85px"
                pl="15px"
              />
              <DatePicker
                color="#E0E0E0"
                keyPicker={`${item.url}-date_to`}
                title="До"
                value={
                  dayjs(query.get(`${item.url}-date_to`)) || dayjs("2023-01-01")
                }
                onChangePicker={onChangePicker}
                width="85px"
                pl="15px"
              />
            </LocalizationProvider>
            <CustomButton
              color="#fff"
              bgColor="var(--primary)"
              value={"Сгенерировать"}
              fs="16px"
              fw="500"
              type="button"
              loading={false}
              padding="6px 15px"
              width="fit-content"
              onClick={() =>
                dispatch(downloadStatisticsByFilter({ query, url: item.url }))
              }
            />
          </FlexBetween>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Card;
