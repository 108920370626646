import React from "react";
import styled from "styled-components";

interface RemoveContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
}

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;

  p {
    margin-bottom: 20px;
  }

  button {
    margin-right: 10px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button.remove {
    background-color: #e74c3c;
    color: #fff;
  }

  button.cancel {
    background-color: #3498db;
    color: #fff;
  }
`;

const RemoveContactModal: React.FC<RemoveContactModalProps> = ({
  isOpen,
  onClose,
  onRemove,
}) => {
  const handleRemoveContact = () => {
    onRemove();
    onClose();
  };
  return (
    <ModalContent>
      <p>Вы уверены, что хотите удалить этот контакт?</p>
      <button className="cancel" onClick={onClose}>
        Отменить
      </button>
      <button className="remove" onClick={handleRemoveContact}>
        Удалить
      </button>
    </ModalContent>
  );
};

export default RemoveContactModal;
