import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getLCClientsList,
  getPCClientsList,
} from "../actions/clientsListActions";

const initialState = {
  pending: false,
  cannotEditApp: false,
  openModal: "",
  PCClientsList: [] as any,
  LCClientsList: [] as any,
};

export const LtvSlice = createSlice({
  name: "clientsList",
  initialState,
  reducers: {
    handleOpen: (state, action: PayloadAction<string>) => {
      state.openModal = action.payload;
    },
    handleClose: (state) => {
      state.openModal = "";
      state.cannotEditApp = false;
    },
  },
  extraReducers: {
    [(getPCClientsList as any).pending]: (state) => {
      state.pending = true;
    },
    [(getPCClientsList as any).fulfilled]: (state, action) => {
      state.pending = false;
      state.PCClientsList = action.payload as any;
    },
    [(getLCClientsList as any).pending]: (state) => {
      state.pending = true;
    },
    [(getLCClientsList as any).fulfilled]: (state, action) => {
      state.pending = false;
      state.LCClientsList = action.payload as any;
    },
  },
});

export const { handleOpen, handleClose } = LtvSlice.actions;
export default LtvSlice.reducer;
