import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTop,
  SearchInputWrapper,
} from "../../PCApplications/components/Header/style";
import CustomInput from "../../Common/CustomInput";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import searchIcon from "../../../assets/icons/applications/search.svg";
import manImg from "../../../assets/icons/applications/man.svg";
import useSearch from "../../../hooks/useSearch";
import { useLocation, useNavigate } from "react-router-dom";
import UseReplace from "../../../hooks/useReplace";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { PCClientDataT } from "../../PCApplications/types";
import { PCAppsDataT, dataType } from "../../../types/commonTypes";
import { defaultPCClientData } from "../../PCApplications/data";
import { getPCContactForCreate } from "../../../store/actions/PCContactActions";
import { updateLCOrdersManager } from "../../../store/actions/clientsListActions";
import { openSnackbar } from "../../../store/slices/mainSlice";
import i18next from "i18next";
import { FlexBetween } from "../../Common/FlexWrapper";
import { getManagersThunk } from "../../../store/actions/mainActions";

type T = {
  handleClose?: () => void;
  selectedOrders?: number[];
};

const UpdateOrderManager: React.FC<T> = ({ selectedOrders, handleClose }) => {
  const { dataForCreate: contacts } = useAppSelector(
    (state) => state.PCContact
  );
  const { managers } = useAppSelector((state) => state.main);
  const [selectedContact, setSelectedContact] = useState<number>();
  const handleContactSelection = (itemID: number) => {
    setSelectedContact(itemID);
  };
  const query = useSearch();
  console.log(selectedOrders);
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [finalValue, setFinalValue] = useState<string>("");

  useEffect(() => {
    const delay = 500; // Adjust the delay as needed (e.g., 500 milliseconds)
    const timeoutId = setTimeout(() => {
      setFinalValue(searchValue);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);
  useEffect(() => {
    // dispatch(getPCContactForCreate(String(finalValue)));
    dispatch(getManagersThunk(finalValue));
  }, [finalValue]);
  return (
    <Card>
      <CardTop>Поиск по базе</CardTop>
      <CardBody>
        <SearchInputWrapper>
          <CustomInput
            name="search"
            showError={false}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(String(e));
            }}
            fullWidth
            type="text"
            height="33px"
            placeholder="Введите наименование …"
            pl="10px"
            pr="40px"
          />
          <img src={searchIcon} alt="" />
        </SearchInputWrapper>

        {managers?.length > 0 ? (
          <List>
            {managers.slice(0, 5).map((item: any) => (
              <ListItem disablePadding key={item.id}>
                <ListItemButton
                  onClick={() => handleContactSelection(item.id)}
                  // Add a checkbox for selecting multiple contacts
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "10px",
                    padding: "5px 15px",
                    marginTop: "7px",
                    background:
                      selectedContact === item.id
                        ? "var(--primary)"
                        : "#e4e4e4",
                    color: selectedContact === item.id ? "#fff" : "#000",
                  }}
                >
                  <FlexBetween
                    style={{ alignItems: "flex-start" }}
                    flexDirection={"column"}
                  >
                    <ListItemText
                      sx={{
                        textAlign: "left",
                        span: {
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "Inter !important",
                        },
                      }}
                      primary={item.first_name + " " + item.last_name}
                    />
                    <ListItemText
                      sx={{
                        textAlign: "left",
                        span: {
                          fontSize: "12px",
                          fontWeight: "400",
                          fontFamily: "Inter !important",
                          color: "#00000080",
                        },
                      }}
                      primary={item?.phone_number || item?.telegram}
                    />
                  </FlexBetween>
                  {/* <FlexBetween>
                    <ListItemText
                      sx={{
                        textAlign: "left",
                        span: {
                          fontSize: "12px",
                          fontWeight: "400",
                          fontFamily: "Inter !important",
                          color: "#000000",
                        },
                      }}
                      primary={item.total_orders}
                    />
                  </FlexBetween> */}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <img src={manImg} height="250px" alt="" />
        )}
      </CardBody>
      {managers?.length > 0 && (
        <Button
          variant="contained"
          sx={{
            width: "100%",
            height: "50px",
            borderRadius: "0px",
            background: "var(--primary)",
            color: "#fff",
            textTransform: "none",
            fontWeight: "600",
            fontSize: "16px",
            "&:hover": {
              background: "var(--primary)",
            },
          }}
          onClick={() => {
            dispatch(
              updateLCOrdersManager({
                manager: selectedContact,
                orders: selectedOrders,
              })
            ).then(() => {
              console.log(handleClose);
              // handleClose && handleClose();
              // window.location.reload();
            });
          }}
        >
          Сохранить
        </Button>
      )}
    </Card>
  );
};

export default UpdateOrderManager;
