import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpRequest } from "../../utils/request";

export const getLegalPartnersStatistics = createAsyncThunk(
  "statistics/getLegalPartnersStatistics",
  async (data: any, thunkAPI) => {
    const date_to = data?.query?.get("date_to");
    const date_from = data?.query?.get("date_from");
    return await thunkAPI.dispatch(
      httpRequest({
        url: `analytics/legal/partners/${
          date_from ? "?date_from=" + date_from : ""
        }${date_to ? "&date_to=" + date_to : ""}`,
      })
    );
  }
);
export const getLegalStatusFinishedStatistics = createAsyncThunk(
  "statistics/getLegalStatusFinishedStatistics",
  async (data: any, thunkAPI) => {
    const date_to = data?.query?.get("date_to");
    const date_from = data?.query?.get("date_from");
    return await thunkAPI.dispatch(
      httpRequest({
        url: `analytics/legal/status/finished${
          date_from ? "?date_from=" + date_from : ""
        }${date_to ? "&date_to=" + date_to : ""}`,
      })
    );
  }
);
export const getManagerStatistics = createAsyncThunk(
  "statistics/getManagerStatistics",
  async (data: any, thunkAPI) => {
    const date_to = data?.query?.get("date_to");
    const date_from = data?.query?.get("date_from");
    return await thunkAPI.dispatch(
      httpRequest({
        url: `analytics/manager${date_from ? "?date_from=" + date_from : ""}${
          date_to ? "&date_to=" + date_to : ""
        }`,
      })
    );
  }
);
export const getManagerActivities = createAsyncThunk(
  "statistics/getManagerActivities",
  async (data: any, thunkAPI) => {
    const date_to = data?.query?.get("date_to");
    const date_from = data?.query?.get("date_from");
    return await thunkAPI.dispatch(
      httpRequest({
        url: `analytics/manager/activity${date_from ? "?date_from=" + date_from : ""}${
          date_to ? "&date_to=" + date_to : ""
        }`,
      })
    );
  }
);
export const getPhysicalOrdersStatistics = createAsyncThunk(
  "statistics/getPhysicalOrdersStatistics",
  async (data: any, thunkAPI) => {
    const date_from = data?.query?.get("date_from");
    const date_to = data?.query?.get("date_to");
    const manager = data?.query?.get("manager");
    const status = data?.query?.get("status");
    const ltv = data?.query?.get("ltv");
    return await thunkAPI.dispatch(
      httpRequest({
        url: `analytics/physical/orders/${
          date_from ? "?date_from=" + date_from : ""
        }${date_to ? "&date_to=" + date_to : ""}${
          status ? "&status=" + status : ""
        }${ltv ? "&ltv=" + ltv : ""}${manager ? "&manager=" + manager : ""}`,
      })
    );
  }
);
export const getLegalOrdersStatistics = createAsyncThunk(
  "statistics/getLegalOrdersStatistics",
  async (data: any, thunkAPI) => {
    const date_from = data?.query?.get("date_from");
    const date_to = data?.query?.get("date_to");
    const manager = data?.query?.get("manager");
    const status = data?.query?.get("status");
    const ltv = data?.query?.get("ltv");
    return await thunkAPI.dispatch(
      httpRequest({
        url: `analytics/legal/orders${
          date_from ? "?date_from=" + date_from : ""
        }${date_to ? "&date_to=" + date_to : ""}${
          status ? "&status=" + status : ""
        }${ltv ? "&ltv=" + ltv : ""}${manager ? "&manager=" + manager : ""}`,
      })
    );
  }
);
export const getPhysicalInterestsStatistics = createAsyncThunk(
  "statistics/getPhysicalInterestsStatistics",
  async (data: any, thunkAPI) => {
    const date_to = data?.query?.get("date_to");
    const date_from = data?.query?.get("date_from");
    return await thunkAPI.dispatch(
      httpRequest({
        url: `analytics/physical/interests${
          date_from ? "?date_from=" + date_from : ""
        }${date_to ? "&date_to=" + date_to : ""}`,
      })
    );
  }
);
export const downloadStatistics = createAsyncThunk(
  "statistics/downloadStatistics",
  async (data: any, thunkAPI) => {
    const fileRes = await thunkAPI.dispatch(
      httpRequest({
        url: `analytics/excel/${
          data.date_from ? "?date_from=" + data.date_from : ""
        }${data.date_to ? "&date_to=" + data.date_to : ""}`,
        noJson: true,
      })
    );
    const blob = await fileRes.blob();

    const blobURL = window.URL.createObjectURL(new Blob([blob]));
    const file = document.createElement("a");
    file.href = blobURL;
    file.setAttribute("download", "document.xlsx");
    document.body.appendChild(file);
    file.click();
    file.remove();
  }
);
export const downloadTopWorkStatistics = createAsyncThunk(
  "statistics/downloadTopWorkStatistics",
  async (data: any, thunkAPI) => {
    const fileRes = await thunkAPI.dispatch(
      httpRequest({
        url: `analytics/top-work/excel${
          data.date_from ? "?date_from=" + data.date_from : ""
        }${data.date_to ? "&date_to=" + data.date_to : ""}`,
        noJson: true,
      })
    );
    const blob = await fileRes.blob();

    const blobURL = window.URL.createObjectURL(new Blob([blob]));
    const file = document.createElement("a");
    file.href = blobURL;
    file.setAttribute("download", "document.xlsx");
    document.body.appendChild(file);
    file.click();
    file.remove();
  }
);
export const downloadTypeOfWorkStatistics = createAsyncThunk(
  "statistics/downloadTypeOfWorkStatistics",
  async (data: any, thunkAPI) => {
    const fileRes = await thunkAPI.dispatch(
      httpRequest({
        url: `analytics/type-of-work/excel${
          data.date_from ? "?date_from=" + data.date_from : ""
        }${data.date_to ? "&date_to=" + data.date_to : ""}`,
        noJson: true,
      })
    );
    const blob = await fileRes.blob();

    const blobURL = window.URL.createObjectURL(new Blob([blob]));
    const file = document.createElement("a");
    file.href = blobURL;
    file.setAttribute("download", "document.xlsx");
    document.body.appendChild(file);
    file.click();
    file.remove();
  }
);

export const downloadPaymentStatistics = createAsyncThunk(
  "statistics/downloadPaymentStatistics",
  async (_, thunkAPI) => {
    const fileRes = await thunkAPI.dispatch(
      httpRequest({ url: `analytics/payment/`, noJson: true })
    );
    const blob = await fileRes.blob();

    const blobURL = window.URL.createObjectURL(new Blob([blob]));
    const file = document.createElement("a");
    file.href = blobURL;
    file.setAttribute("download", "document.xlsx");
    document.body.appendChild(file);
    file.click();
    file.remove();
  }
);

export const downloadStatisticsByFilter = createAsyncThunk(
  "statistics/downloadStatisticsByFilter",
  async ({ query, url }: { query: any; url: string }, thunkAPI) => {
    const manager = query.get(`${url}-manager`);
    const status = query.get(`${url}-status`);
    const date_from = query.get(`${url}-date_from`);
    const date_to = query.get(`${url}-date_to`);

    const fileRes = await thunkAPI.dispatch(
      httpRequest({
        url: `${url}?${manager ? `&manager=${manager}` : ``}${
          status ? `&status=${status}` : ``
        }${date_from ? `&date_from=${date_from}` : ``}${
          date_to ? `&date_to=${date_to}` : ``
        } `,
        noJson: true,
      })
    );
    const blob = await fileRes.blob();

    const blobURL = window.URL.createObjectURL(new Blob([blob]));
    const file = document.createElement("a");
    file.href = blobURL;
    file.setAttribute("download", "Document.xlsx");
    document.body.appendChild(file);
    file.click();
    file.remove();
  }
);
