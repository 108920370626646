import { postLCDataType, statusT } from "../../types/commonTypes";
import { PCAppType } from "../../components/PCApplications/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import i18next, { t } from "i18next";
import { httpRequest } from "../../utils/request";
import { openSnackbar } from "../slices/mainSlice";
import {
  handleClose,
  setAppsByStepPending,
  setAppsData,
} from "../slices/LCApplicationsSlice";
import { postDataType } from "../../types/commonTypes";
import { nameTypes } from "../../components/PCApplications/data";

export const getLCApplications = createAsyncThunk(
  "LCApplications/getLCApplications",
  async (data: any, thunkAPI) => {
    const ordering = data?.query?.get("ordering") || data?.ordering;
    const date_from = data?.query?.get("date_from");
    const date_by_year = data?.query?.get("monthly")?.split("-")[0];
    const date_by_month = data?.query?.get("monthly")?.split("-")[1];
    const date_to = data?.query?.get("date_to");
    const job_type = data?.query?.get("job_type");
    const status = data?.query?.get("status");
    const manager = data?.query?.get("manager");
    const page = data?.query?.get("page");
    const survey = data?.query?.get("survey");
    const name = data?.query?.get("search-name");
    console.log("dedewdewtest");

    return await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/order/list/?${name ? "&search=" + name : ""}${
          data.page_size ? "&page_size=" + data.page_size : ""
        }${job_type ? "&job_type=" + job_type : ""}${
          status ? "&status=" + status : ""
        }${date_by_year ? "&date_by_year=" + date_by_year : ""}${
          date_by_month ? "&date_by_month=" + date_by_month : ""
        }${date_from ? "&date_from=" + date_from : ""}${
          date_to ? "&date_to=" + date_to : ""
        }${manager ? "&manager=" + manager : ""}${page ? "&page=" + page : ""}${
          ordering ? "&ordering=" + ordering : ""
        }${survey ? "&survey=" + survey : ""}`,
      })
    );
  }
);

export const getLCApplicationsArchives = createAsyncThunk(
  "LCApplications/getLCApplicationsArchives",
  async (data: any, thunkAPI) => {
    const ordering = data.ordering;
    const date_from = data.query.get("date_from");
    const date_to = data.query.get("date_to");
    const manager = data.query.get("manager");
    const page = data.page;
    const page_size = data.page_size;
    const survey = data.query.get("survey");
    const name = data.query.get("search-name");
    const status = data.query.get("status");

    return await thunkAPI.dispatch(
      httpRequest({
        url: `archive/?${status ? "&status=" + status : ""}${
          page_size ? "&page_size=" + page_size : ""
        }${date_from ? `&date_from=${date_from}` : ``}${
          date_to ? `&date_to=${date_to}` : ``
        }${manager ? `&manager=${manager}` : ``}${page ? `&page=${page}` : ``}${
          name ? `&name=${name}` : ``
        }${ordering ? `&ordering=${ordering}` : ``}${
          survey ? `&survey=${survey}` : ``
        }`,
      })
    );
  }
);

export const getLCApplicationsByStep = createAsyncThunk(
  "LCApplications/getLCApplicationsByStep",
  async (
    data: {
      api: string;
      page?: number;
      refetch?: boolean;
      query?: any;
    },
    thunkAPI
  ) => {
    const { api, refetch, page } = data;
    const ordering = data?.query?.get("ordering");
    const date_from = data?.query?.get("date_from");
    const date_by_year = data?.query?.get("monthly")?.split("-")[0];
    const date_by_month = data?.query?.get("monthly")?.split("-")[1];
    const date_to = data?.query?.get("date_to");
    const job_type = data?.query?.get("job_type");
    const status = JSON.parse(data?.query?.get("status") || "[]")?.map(
      (item: any) => item?.id
    );
    const interest = JSON.parse(data?.query?.get("interest") || "[]")?.map(
      (item: any) => item?.id
    );
    const activity = data?.query?.get("activity");
    const manager = data?.query?.get("manager");
    const survey = data?.query?.get("survey");
    const name = data?.query?.get("search-name");
    const resData = await thunkAPI.dispatch(
      httpRequest({
        url: `legal/order/step/${api}/?${name ? "&search=" + name : ""}${
          job_type ? "&job_type=" + job_type : ""
        }${status.length ? "&status=" + status : ""}${
          interest.length ? "&interest=" + interest : ""
        }${date_by_year ? "&date_by_year=" + date_by_year : ""}${
          date_by_month ? "&date_by_month=" + date_by_month : ""
        }${date_from ? "&date_from=" + date_from : ""}${
          date_to ? "&date_to=" + date_to : ""
        }${manager ? "&manager=" + manager : ""}${page ? "&page=" + page : ""}${
          ordering ? "&ordering=" + ordering : ""
        }${survey ? "&survey=" + survey : ""}${
          activity ? "&activity=" + activity : ""
        }`,
      })
    );

    return { data: resData, step: api, page, refetch };
  }
);

export const getLCNotifications = createAsyncThunk(
  "LCApplications/getLCNotifications",
  async (_, thunkAPI) =>
    await thunkAPI.dispatch(
      httpRequest({ url: "notification/my_notifications/" })
    )
);

export const createLCApplicationThunk = createAsyncThunk(
  "LCApplications/createLCApplicationThunk",
  async (data: any, thunkAPI) => {
    const { order, user, clientId } = data;

    const resUser = await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/update/${clientId}/`,
        method: "PATCH",
        body: user,
      })
    );
    if (resUser.data.id) {
      const resOrder = await thunkAPI.dispatch(
        httpRequest({
          url: `legal-client/order/create/`,
          method: "POST",
          body: { ...order, client: resUser.data.id },
        })
      );

      if (!!resOrder.result.data.id) {
        thunkAPI.dispatch(
          openSnackbar({
            status: "success",
            message: i18next.t("settings.snackbar.created"),
          })
        );

        thunkAPI.dispatch(setAppsByStepPending("introduction"));
        thunkAPI.dispatch(
          getLCApplicationsByStep({ api: "introduction", refetch: true })
        );
        if (
          (thunkAPI.getState() as any).settings?.profile?.role ===
          "account_manager"
        )
          thunkAPI.dispatch(getLCNotifications());
      }
    }
  }
);

export const createLCApplicationWithClientThunk = createAsyncThunk(
  "LCApplications/createLCApplicationWithClientThunk",
  async (data: { user: postLCDataType; appData: any }, thunkAPI) => {
    const { user, appData } = data;

    const clientInfo = {
      company_name: user.company_name,
      inn: user.inn,
      note: user.note,
      brand: !!user.brand?.filter((item) => item?.trim()).length
        ? user.brand
        : undefined,
      activity: user.activity?.id,
      manager: user.manager?.id,
      contacts: user.contacts,
    };

    const resUser = await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/create/`,
        method: "POST",
        body: clientInfo,
      })
    );

    if (resUser.result.id) {
      const { order, objects } = appData;

      const resOrder = await thunkAPI.dispatch(
        httpRequest({
          url: `legal-client/order/create/`,
          method: "POST",
          body: { ...order, client: order.i || resUser.result.id },
        })
      );

      if (resOrder.result.data.id) {
        thunkAPI.dispatch(
          openSnackbar({
            status: "success",
            message: i18next.t("settings.snackbar.created"),
          })
        );

        thunkAPI.dispatch(setAppsByStepPending("introduction"));
        thunkAPI.dispatch(
          getLCApplicationsByStep({ api: "introduction", refetch: true })
        );
        if (
          (thunkAPI.getState() as any).settings?.profile?.role ===
          "account_manager"
        )
          thunkAPI.dispatch(getLCNotifications());
        thunkAPI.dispatch(handleClose());
      }
    }
  }
);

export const deleteLCContactsThunk = createAsyncThunk(
  "LCApplications/deleteLCContactsThunk",
  async (data: any, thunkAPI) => {
    const { contacts, clientId } = data;
    console.log(contacts);

    await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/delete/`,
        method: "POST",
        body: { ids: contacts },
      })
    );
  }
);
export const editLCApplicationThunk = createAsyncThunk(
  "LCApplications/editLCApplicationThunk",
  async (data: any, thunkAPI) => {
    // const navigate = useNavigate();
    const { orderId, objects, order, client, clientId, query, step } = data;

    const appTypes = t("LCApplications.applicationsTypes", {
      returnObjects: true,
    }) as statusT[];

    const resUser = await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/update/${clientId}/`,
        method: "PATCH",
        body: client,
      })
    );

    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/order/update/${orderId}/`,
        method: "PATCH",
        body: {
          ...order,
          is_expired: false,
          is_archived: false,
          payment_status: false,
        },
      })
    );
    if (res.result.data.id || resUser.data.id) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.updated"),
        })
      );
      // thunkAPI.dispatch(setAppsData([]));
      // appTypes.forEach((item) => {
      //   thunkAPI.dispatch(setAppsByStepPending(item.step));
      //   thunkAPI.dispatch(getLCApplicationsByStep({ api: item.step }));
      // });
      thunkAPI.dispatch(handleClose());
    }
  }
);

export const editApplicationObject = createAsyncThunk(
  "LCApplications/editApplicationObject",
  async (obj: any, thunkAPI) => {
    const { data, id, step } = obj;

    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `application/${id}/`,
        method: "PATCH",
        body: data,
      })
    );

    if (res.id) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.updated"),
        })
      );

      thunkAPI.dispatch(setAppsByStepPending(step));
      thunkAPI.dispatch(getLCApplicationsByStep({ api: step, refetch: true }));
    }

    return { status: "success" };
  }
);

export const deleteApplicationObject = createAsyncThunk(
  "LCApplications/deleteApplicationObject",
  async (obj: any, thunkAPI) => {
    const { id, step } = obj;
    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `application/${id}/`,
        method: "DELETE",
        noJson: true,
      })
    );

    if (res.status === 200 || res.status === 204) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.deleted"),
        })
      );

      thunkAPI.dispatch(setAppsByStepPending(step));
      thunkAPI.dispatch(getLCApplicationsByStep({ api: step, refetch: true }));
    }
  }
);

export const deleteLCApplicationThunk = createAsyncThunk(
  "LCApplications/deleteLCApplicationThunk",
  async (_, thunkAPI) => {
    const ids = (thunkAPI.getState() as any).LCApplications.selectedItems;
    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/order/delete/`,
        method: "POST",
        body: { ids },
        noJson: true,
      })
    );

    if (res.status === 204 || res.status === 200) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.deleted"),
        })
      );
      return { status: "success" };
    }
  }
);
