import i18next from "i18next";
import React from "react";
import styled from "styled-components";
import Input from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Wrapper = styled.div<{
  fullWidth?: boolean;
  width?: string;
  height?: string;
  pl?: string;
  fs?: string;
  pr?: string;
  p?: string;
}>`
  width: ${({ fullWidth }) => fullWidth && "100%"};
  text-align: start;

  textarea {
    width: ${({ width }) => width && width};
    width: ${({ fullWidth }) => fullWidth && "100%"};
    height: ${({ height }) => height && height};
    padding: ${({ p }) => (p ? p : "14px 21px")};
    padding-left: ${({ pl }) => pl && pl};
    padding-right: ${({ pr }) => pr && pr};
    border: 1px solid #bebebe;
    border-radius: 6px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: ${({ fs }) => (fs ? fs : "16px")};
    line-height: 19px;
    outline: none;
    background-color: #fff;
  }
`;

const Error = styled.div<{ errorFs?: string }>`
  color: red;
  margin-top: 5px;
  font-size: ${({ errorFs }) => (errorFs ? errorFs : "16px")};
`;

type InputType = {
  value: string | number;
  defaultValue?: string | number;
  name: string;
  fullWidth?: boolean;
  onChange?: (e: string | number, name: string) => void;
  showError?: boolean;
  errorText?: string;
  type?: string;
  width?: string;
  height?: string;
  pl?: string;
  fs?: string;
  disable?: boolean;
  placeholder?: string;
  errorFs?: string;
  pr?: string;
  p?: string;
};

const CustomInputTextfield: React.FC<InputType> = ({
  value,
  defaultValue,
  name,
  fullWidth,
  onChange,
  showError,
  errorText,
  type,
  width,
  height,
  pl,
  pr,
  fs,
  placeholder,
  errorFs,
  disable,
  p,
}) => {
  document.addEventListener("DOMContentLoaded", function () {
    const textarea = document.getElementById("autoResizeTextarea");

    textarea?.addEventListener("input", function () {
      this.style.height = "auto";
      this.style.height = this.scrollHeight + "px";
    });
  });
  return (
    <Wrapper
      fullWidth={fullWidth}
      height={height}
      pl={pl}
      fs={fs}
      pr={pr}
      p={p}
      width={width}
    >
      <textarea
        id="autoResizeTextarea"
        value={value ? String(value) : ""}
        name={name}
        disabled={disable}
        style={{ width: "100%", overflow: "hidden" }}
        defaultValue={defaultValue ? String(defaultValue) : ""}
        // onBlur={}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          onChange
            ? onChange(
                name === "username"
                  ? e.target.value.toLowerCase()
                  : e.target.value,
                name
              )
            : null
        }
        placeholder={placeholder}
      />

      {showError && (
        <Error errorFs={errorFs}>
          *{errorText ? errorText : i18next.t("validations.fill")}
        </Error>
      )}
    </Wrapper>
  );
};

export default CustomInputTextfield;
