import { AsyncThunkAction, createAsyncThunk } from "@reduxjs/toolkit";
import { httpRequest } from "../../utils/request";
import { managerT } from "../../types/commonTypes";
import { openSnackbar } from "../slices/mainSlice";
import i18next from "i18next";

export const getMonitoringClientList = createAsyncThunk(
  "monitoring/getMonitoringClientList",
  async (data: any, thunkAPI) => {
    const changed_type = data?.query?.get("changed_type");
    const changed_by = data?.query?.get("changed_by");
    const manager = data?.query?.get("manager");
    const survey = data?.query?.get("survey");
    const activity = data?.query?.get("activity");
    const search = data?.query?.get("search-name");
    const date_from = data?.query?.get("date_from");
    const date_to = data?.query?.get("date_to");

    return await thunkAPI.dispatch(
      httpRequest({
        url: `monitoring/history/client/list/?page_size=${data?.page_size}${
          data?.order_by ? "&order_by=" + data?.order_by : ""
        }${data?.page ? "&page=" + data?.page : ""}${
          search ? "&search=" + search : ""
        }${changed_type ? `&changed_type=${changed_type}` : ``}${
          changed_by ? `&changed_by=${changed_by}` : ``
        }${manager ? `&manager=${manager}` : ``}${
          activity ? `&activity=${activity}` : ``
        }${survey ? `&survey=${survey}` : ``}${
          date_from ? `&date_from=${date_from}` : ``
        }${date_to ? `&date_to=${date_to}` : ``}`,
      })
    );
  }
);

export const getMonitoringAppList = createAsyncThunk(
  "monitoring/getMonitoringAppList",
  async (data: any, thunkAPI) => {
    const changed_type = data?.query?.get("changed_type");
    const changed_by = data?.query?.get("changed_by");
    const manager = data?.query?.get("manager");
    const survey = data?.query?.get("survey");
    const activity = data?.query?.get("activity");
    const search = data?.query?.get("search-name");
    const date_from = data?.query?.get("date_from");
    const date_to = data?.query?.get("date_to");

    return await thunkAPI.dispatch(
      httpRequest({
        url: `monitoring/history/order/list/?page_size=${data?.page_size}${
          data?.order_by ? "&order_by=" + data?.order_by : ""
        }${data?.page ? "&page=" + data?.page : ""}${
          search ? "&search=" + search : ""
        }${changed_type ? `&changed_type=${changed_type}` : ``}${
          changed_by ? `&changed_by=${changed_by}` : ``
        }${manager ? `&manager=${manager}` : ``}${
          activity ? `&activity=${activity}` : ``
        }${survey ? `&survey=${survey}` : ``}${
          date_from ? `&date_from=${date_from}` : ``
        }${date_to ? `&date_to=${date_to}` : ``}`,
      })
    );
  }
);
