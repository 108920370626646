import { useEffect } from "react";
import { Stack } from "@mui/material";
import Header from "./components/Header";
import Main from "./components/Main";
import { getManagersThunk } from "../../store/actions/mainActions";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { getTypes } from "../../store/actions/settingsActions";
import { getPCContact } from "../../store/actions/PCContactActions";
import useSearch from "../../hooks/useSearch";

const Statistics = () => {
  const dispatch = useAppDispatch();
  const query = useSearch();

  useEffect(() => {
    dispatch(getPCContact(query));
    dispatch(getManagersThunk("")); 
  }, [dispatch]);

  return (
    <Stack gap="14px">
      <Header />
      <Main />
    </Stack>
  );
};

export default Statistics;
