import React, { FC, useEffect, useState } from "react";
import {
  ItemsPerPage,
  PaginationTable,
  WrapperClientsTable,
  WrapperTable,
} from "./style";
import { useDispatch } from "react-redux";
import {
  getLCClientsList,
  getPCClientsList,
} from "../../../store/actions/clientsListActions";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { nameTypes } from "../../PCApplications/data";
import UseReplace from "../../../hooks/useReplace";
import { useLocation, useNavigate } from "react-router-dom";
import useSearch from "../../../hooks/useSearch";
import { calculateOrderInPage } from "../../../utils/helpers";
import { Pagination } from "@mui/material";
type Props = {
  searchValue: string;
};

const PCClientsTable: FC<Props> = ({ searchValue }) => {
  const { PCClientsList, pending } = useAppSelector(
    (state) => state.clientsList
  );
  console.log(pending);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const location = useLocation();
  const { pathname } = useLocation();
  const query = useSearch();
  useEffect(() => {
    !location.search && navigate("/clients-list-pc");
  }, []);
  useEffect(() => {
    // if (query.get("search-name")) {
    dispatch(
      getPCClientsList({
        query,
        person_contact: searchValue,
        page_size: 10,
      })
    );
    setPageSize(10);
    setCurrentPage(1);
    setSortDirection("");
    setSortField("");
    setCurrentSort("");
    // }
  }, [query.get("search-name")]);
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [currentSort, setCurrentSort] = useState("");

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    dispatch(
      getPCClientsList({
        query,
        person_contact: searchValue,
        page_size: pageSize,
        page: pageNumber,
        order_by: currentSort,
      })
    );
  };
  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    setCurrentPage(1);
    dispatch(
      getPCClientsList({
        query,
        person_contact: searchValue,
        page_size: newSize,
        page: currentPage,
        order_by: currentSort,
      })
    );
  };
  const handleSortChange = (field: string) => {
    console.log(field);
    if (sortField === field) {
      // Toggle sort direction if the same field is clicked again
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Set sort field and default sort direction
      setSortField(field);
      setSortDirection("asc");
    }
    setCurrentSort(sortDirection === "asc" ? field : "reverse_" + field);

    dispatch(
      getPCClientsList({
        query,
        person_contact: searchValue,
        page_size: pageSize,
        page: currentPage,
        order_by: sortDirection === "asc" ? field : "reverse_" + field,
      })
    );
  };
  function convertToFormattedString(number: number) {
    return number?.toLocaleString("en-US");
  }
  return (
    <WrapperClientsTable>
      <WrapperTable>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>
                <div onClick={() => !pending && handleSortChange("full_name")}>
                  <p>ФИО</p>
                  <ArrowUpwardIcon
                    style={{
                      cursor: "pointer",
                      transform:
                        sortField === "full_name"
                          ? `rotate(${sortDirection === "asc" ? 0 : 180}deg)`
                          : "rotate(0deg)",
                    }}
                  />{" "}
                </div>
              </th>
              <th>Номер телефона/Телеграм</th>
              <th>Место работы</th>
              <th>Должность</th>
              <th>Интерес</th>
              <th>
                <div onClick={() => !pending && handleSortChange("created_at")}>
                  <p>Дата создания карточки</p>
                  <ArrowUpwardIcon
                    style={{
                      cursor: "pointer",
                      transform:
                        sortField === "created_at"
                          ? `rotate(${sortDirection === "asc" ? 0 : 180}deg)`
                          : "rotate(0deg)",
                    }}
                  />{" "}
                </div>
              </th>
              <th>Ответственный менеджер</th>
              <th>
                <div onClick={() => !pending && handleSortChange("manager")}>
                  <p>ФИ сотрудника, создавшего данную карточку</p>
                  <ArrowUpwardIcon
                    style={{
                      cursor: "pointer",
                      transform:
                        sortField === "manager"
                          ? `rotate(${sortDirection === "asc" ? 0 : 180}deg)`
                          : "rotate(0deg)",
                    }}
                  />{" "}
                </div>
              </th>

              <th>
                <div
                  onClick={() => !pending && handleSortChange("num_of_booking")}
                >
                  <p>Кол-во ролей</p>{" "}
                  <ArrowUpwardIcon
                    style={{
                      cursor: "pointer",
                      transform:
                        sortField === "num_of_booking"
                          ? `rotate(${sortDirection === "asc" ? 0 : 180}deg)`
                          : "rotate(0deg)",
                    }}
                  />{" "}
                </div>
              </th>
              <th>Заметка</th>
              <th>
                <div onClick={() => !pending && handleSortChange("ltv")}>
                  <p>LTV</p>{" "}
                  <ArrowUpwardIcon
                    style={{
                      cursor: "pointer",
                      transform:
                        sortField === "ltv"
                          ? `rotate(${sortDirection === "asc" ? 0 : 180}deg)`
                          : "rotate(0deg)",
                    }}
                  />{" "}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {PCClientsList.results?.map((client: any, idx: number) => (
              <tr key={idx}>
                <td>{calculateOrderInPage(currentPage, idx, pageSize)}</td>
                <td
                  style={{ cursor: "pointer", textDecorationLine: "underline" }}
                  onClick={() =>
                    navigate(
                      `/contact-pc${UseReplace("id", String(client.id))}`,
                      {
                        state: { prevPath: location.pathname },
                      }
                    )
                  }
                >
                  {client.full_name}
                </td>
                <td>{client.contact || client.telegram}</td>
                <td>{client.company_name}</td>
                <td>{client.job_title}</td>
                <td>
                  {client.interests
                    .map((item: any) => item.name)
                    ?.slice(0, 3)
                    .join(",")}
                </td>
                <td>{client.created_at}</td>
                <td>
                  <p style={{ width: "max-content" }}>{client?.last_manager}</p>
                </td>
                <td>
                  <p style={{ width: "max-content" }}>
                    {client?.manager?.first_name +
                      " " +
                      client.manager?.last_name}
                  </p>
                </td>
                <td>
                  <p style={{ width: "max-content" }}>
                    {client?.num_of_booking}
                  </p>
                </td>
                <td style={{ textAlign: "center" }}>{client?.note}</td>
                <td style={{ textAlign: "center" }}>
                  {convertToFormattedString(client.ltv)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </WrapperTable>
      {/* Pagination */}
      {/* <PaginationTable>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1 || pending}
        >
          <ArrowBackIosNewIcon />
        </button>
        <span>{currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={!PCClientsList.next || pending}
        >
          <ArrowForwardIosIcon />
        </button>
        <ItemsPerPage>
          <p>Элементов на странице:</p>
          <input
            type="number"
            min={1}
            disabled={pending}
            value={pageSize}
            onChange={handlePageSizeChange}
          />
        </ItemsPerPage>
      </PaginationTable> */}
      <PaginationTable>
        <ItemsPerPage>
          <p>Элементов на странице:</p>
          <input
            type="number"
            min={1}
            disabled={pending}
            value={pageSize}
            onChange={handlePageSizeChange}
          />
        </ItemsPerPage>
      </PaginationTable>
      <Pagination
        sx={{
          position: "absolute",
          bottom: "30px",
          right: "14rem",
        }}
        count={
          Math.floor(PCClientsList?.count / pageSize) +
            (PCClientsList?.count % pageSize && 1) || 1
        }
        page={Number(currentPage)}
        onChange={(e, value) => {
          handlePageChange(value);
        }}
        color="primary"
      />
    </WrapperClientsTable>
  );
};

export default PCClientsTable;
