import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createPCApplicationThunk,
  createPCApplicationWithClientThunk,
  deletePCApplicationThunk,
  editPCApplicationThunk,
  getPCApplications,
  getPCApplicationsArchives,
  getPCApplicationsByStep,
  getPCNotifications,
} from "../actions/PCApplicationsActions";
import {
  PCAppsDataT,
  notificationT,
  PCOnDropT,
  showMoreT,
} from "../../types/commonTypes";

const initialState = {
  PCAppsData: {
    type: [] as { step: string; count: number }[],
    results: [] as PCAppsDataT[],
  },
  archivedAppData: {},
  totalCount: "",
  openModal: "",
  pending: false,
  applicationsPending: false,
  applicationsByStepPending: [] as string[],
  notificationsPending: false,
  actionsPending: "",
  cancelReason: {} as PCOnDropT,
  fillPricingData: null as PCOnDropT | null,
  notifications: [] as notificationT[],
  notificationId: null as number | null,
  exceptedNotification: null as notificationT | null,
  editingApp: null as PCAppsDataT | null,
  copiedApp: null as PCAppsDataT | null,
  indicator: "",
  selectedItems: [] as number[],
  isChangedAppObject: false,
  cannotEditApp: false,
  showMoreDefault: [] as showMoreT[],
};

export const PCApplicationsSlice = createSlice({
  name: "PCApplications",
  initialState,
  reducers: {
    handleOpen: (state, action: PayloadAction<string>) => {
      state.openModal = action.payload;
    },
    handleClose: (state) => {
      state.openModal = "";
      state.cannotEditApp = false;
    },
    setAppsData: (state, action: PayloadAction<PCAppsDataT[]>) => {
      state.PCAppsData.results = action.payload;
      state.PCAppsData.type = [];
      state.showMoreDefault = [];
    },
    setCancelReason: (state, action: PayloadAction<PCOnDropT>) => {
      state.cancelReason = action.payload;
    },
    setFillPricingData: (state, action: PayloadAction<PCOnDropT>) => {
      state.fillPricingData = action.payload;
    },
    setNotification: (state, action: PayloadAction<notificationT | null>) => {
      state.exceptedNotification = action.payload;
    },

    setEditingApp: (state, action: PayloadAction<PCAppsDataT | null>) => {
      state.editingApp = action.payload;
      if (
        action.payload?.step === "cancelled" ||
        action.payload?.step === "finish"
      )
        state.cannotEditApp = true;
    },
    setCopiedApp: (state, action: PayloadAction<PCAppsDataT | null>) => {
      state.copiedApp = action.payload;
    },

    setSelectedItems: (state, action: PayloadAction<number>) => {
      const selectedId = action.payload;
      let ids = state.selectedItems;

      if (ids.includes(selectedId)) ids = ids.filter((i) => i !== selectedId);
      else ids.push(selectedId);
      state.selectedItems = ids;

      if (ids.length > 0) {
        if (ids.length > 1) state.indicator = "delete";
        else state.indicator = "delete edit";
      } else state.indicator = "";
    },

    setIsChangedAppObject: (state) => {
      state.isChangedAppObject = false;
    },

    setPCAppsByStepPending: (state, action: PayloadAction<string>) => {
      state.applicationsByStepPending.push(action.payload);
    },
  },
  extraReducers: {
    [(getPCApplications as any).pending]: (state) => {
      state.pending = true;
      state.applicationsPending = true;
    },
    [(getPCApplications as any).rejected]: (state) => {
      state.pending = false;
      state.applicationsPending = false;
    },
    [(getPCApplications as any).fulfilled]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.pending = false;
      state.PCAppsData.results = action.payload.results;
      state.applicationsPending = false;
    },
    [(getPCApplicationsArchives as any).pending]: (state) => {
      state.pending = true;
    },
    [(getPCApplicationsArchives as any).rejected]: (state) => {
      state.pending = false;
    },
    [(getPCApplicationsArchives as any).fulfilled]: (
      state,
      action: PayloadAction<PCAppsDataT[]>
    ) => {
      state.pending = false;
      state.archivedAppData = action.payload;
    },
    [(getPCApplicationsByStep as any).fulfilled]: (
      state,
      action: PayloadAction<{
        data: { results: PCAppsDataT[]; count: number };
        step: string;
        page: number;
        refetch?: boolean;
      }>
    ) => {
      const { data, step, page, refetch } = action.payload;
      state.pending = false;
      const uniqueDataResults: PCAppsDataT[] = refetch
        ? data.results.filter((item) => {
            return !state.PCAppsData.results.some(
              (existingItem) =>
                existingItem.id === item.id &&
                existingItem?.current_status === item?.current_status
            );
          })
        : data.results.filter((item) => {
            return !state.PCAppsData.results.some(
              (existingItem) =>
                existingItem.id === item.id &&
                existingItem?.current_status === item?.current_status
            );
          });
      console.log(state.PCAppsData, action.payload, "sasasasasdedwe");

      state.PCAppsData.results = refetch
        ? [
            ...state.PCAppsData.results.filter((item) => item.step !== step),
            ...uniqueDataResults,
          ]
        : [...state.PCAppsData.results, ...uniqueDataResults];
      state.PCAppsData.type = [
        ...state.PCAppsData.type,
        { step: step, count: data.count },
      ];

      if (!page || page === 1 || refetch)
        state.showMoreDefault = [
          ...state.showMoreDefault,
          {
            step,
            page: 1,
            maxPage:
              data.count % 10 > 0
                ? Math.floor(data.count / 10) + 1
                : data.count / 10,
          },
        ];

      state.applicationsByStepPending = state.applicationsByStepPending.filter(
        (item) => item !== step
      );
    },

    [(getPCNotifications as any).pending]: (state) => {
      state.notificationsPending = true;
    },
    [(getPCNotifications as any).rejected]: (state) => {
      state.notificationsPending = false;
    },
    [(getPCNotifications as any).fulfilled]: (
      state,
      action: PayloadAction<notificationT[]>
    ) => {
      state.notifications = action.payload;
      state.notificationsPending = false;
    },

    [(createPCApplicationThunk as any).pending]: (state) => {
      state.actionsPending = "post-application";
    },
    [(createPCApplicationThunk as any).rejected]: (state) => {
      state.actionsPending = "";
    },
    [(createPCApplicationThunk as any).fulfilled]: (state) => {
      state.actionsPending = "";
      state.openModal = "";
    },

    [(createPCApplicationWithClientThunk as any).pending]: (state) => {
      state.actionsPending = "post-application";
    },
    [(createPCApplicationWithClientThunk as any).rejected]: (state) => {
      state.actionsPending = "";
    },
    [(createPCApplicationWithClientThunk as any).fulfilled]: (state) => {
      state.actionsPending = "";
      state.openModal = "";
    },

    [(editPCApplicationThunk as any).pending]: (state) => {
      state.actionsPending = "post-application";
    },
    [(editPCApplicationThunk as any).rejected]: (state) => {
      state.actionsPending = "";
      state.pending = false;
    },
    [(editPCApplicationThunk as any).fulfilled]: (state) => {
      // const data = action.payload;

      // if (data.id) {
      // const editingItem = data;
      // const items = state.appsData;

      // const index = items.findIndex((item) => item.id === editingItem.id);
      // items.splice(index, 1, editingItem);
      // state.appsData = items;
      // }
      state.editingApp = null;
      state.openModal = "";
      state.pending = false;
      state.actionsPending = "";
    },

    [(deletePCApplicationThunk as any).pending]: (state) => {
      state.actionsPending = "delete-process";
    },
    [(deletePCApplicationThunk as any).rejected]: (state) => {
      state.actionsPending = "";
    },
    [(deletePCApplicationThunk as any).fulfilled]: (
      state,
      action: PayloadAction<{ status: string }>
    ) => {
      if (action?.payload?.status === "success") {
        let ids = state.selectedItems;
        let items = state.PCAppsData.results;

        ids.forEach((selectedId) => {
          items = items.filter((item) => item.id !== selectedId);
        });

        state.PCAppsData.results = items;
        state.selectedItems = [];
        state.indicator = "";
      }

      state.actionsPending = "";
    },
  },
});

export const {
  handleOpen,
  handleClose,
  setAppsData,
  setCancelReason,
  setEditingApp,
  setNotification,
  setSelectedItems,
  setIsChangedAppObject,
  setFillPricingData,
  setPCAppsByStepPending,
  setCopiedApp,
} = PCApplicationsSlice.actions;
export default PCApplicationsSlice.reducer;
