import { AsyncThunkAction, createAsyncThunk } from "@reduxjs/toolkit";
import { httpRequest } from "../../utils/request";
import { managerT } from "../../types/commonTypes";
import { openSnackbar } from "../slices/mainSlice";
import i18next from "i18next";

export const getPCClientsList = createAsyncThunk(
  "clientsList/getPCClientsList",
  async (data: any, thunkAPI) => {
    const status = JSON.parse(data?.query?.get("status") || "[]")?.map(
      (item: any) => item?.id
    );
    const interest = JSON.parse(data?.query?.get("interest") || "[]")?.map(
      (item: any) => item?.id
    );
    const manager = data?.query?.get("manager");
    const search = data?.query?.get("search-name");
    const date_from = data?.query?.get("date_from");
    const date_to = data?.query?.get("date_to");

    return await thunkAPI.dispatch(
      httpRequest({
        url: `ltv/physical/list/?page_size=${data?.page_size}${
          data?.order_by ? "&order_by=" + data?.order_by : ""
        }${data?.page ? "&page=" + data?.page : ""}${
          search ? "&search=" + search : ""
        }${status ? `&statuses=${status}` : ``}${
          interest ? `&interest=${interest}` : ``
        }${manager ? `&manager=${manager}` : ``}${
          date_from ? `&date_from=${date_from}` : ``
        }${date_to ? `&date_to=${date_to}` : ``}`,
      })
    );
  }
);
export const getLCClientsList = createAsyncThunk(
  "clientsList/getLCClientsList",
  async (data: any, thunkAPI) => {
    const survey = data?.query?.get("survey");
    const status = JSON.parse(data?.query?.get("status") || "[]")?.map(
      (item: any) => item?.id
    );
    const interest = JSON.parse(data?.query?.get("interest") || "[]")?.map(
      (item: any) => item?.id
    );
    const activity = data?.query?.get("activity");
    const manager = data?.query?.get("manager");
    const search = data?.query?.get("search-name");
    const date_from = data?.query?.get("date_from");
    const date_to = data?.query?.get("date_to");

    return await thunkAPI.dispatch(
      httpRequest({
        url: `ltv/legal/list/?page_size=${data.page_size}${
          data.order_by ? "&order_by=" + data.order_by : ""
        }${survey ? "&survey=" + survey : ""}${
          data.page ? "&page=" + data.page : ""
        }${search ? "&search=" + search : ""}${
          activity ? "&activity=" + activity : ""
        }${status ? `&status=${status}` : ``}${
          interest ? `&interest=${interest}` : ``
        }${manager ? `&manager=${manager}` : ``}${
          date_from ? `&date_from=${date_from}` : ``
        }${date_to ? `&date_to=${date_to}` : ``}`,
      })
    );
  }
);
export const downloadReportLCClients = createAsyncThunk(
  "statistics/downloadReportLCClients",
  async (query: any, thunkAPI) => {
    const date_from = query?.get("date_from");
    const date_to = query?.get("date_to");
    const survey = query?.get("survey");
    const activity = query?.get("activity");
    const name_type = query?.get("name_type");
    const manager = query?.get("manager");

    const fileRes = await thunkAPI.dispatch(
      httpRequest({
        url: `excels/ltv/legal/?${manager ? "&manager=" + manager : ""}${
          date_from ? "&date_from=" + date_from : ""
        }${date_to ? "&date_to=" + date_to : ""}${
          survey ? `&survey=${survey}` : ``
        }${activity ? `&activity=${activity}` : ``}${
          name_type ? `&name_type=${name_type}` : ``
        }`,
        noJson: true,
      })
    );
    const blob = await fileRes.blob();

    const blobURL = window.URL.createObjectURL(new Blob([blob]));
    const file = document.createElement("a");
    file.href = blobURL;
    file.setAttribute("download", "document.xlsx");
    document.body.appendChild(file);
    file.click();
    file.remove();
  }
);
export const downloadReportPCClients = createAsyncThunk(
  "statistics/downloadReportPCClients",
  async (query: any, thunkAPI) => {
    const date_from = query?.get("date_from");
    const date_to = query?.get("date_to");
    const survey = query?.get("survey");
    const activity = query?.get("activity");
    const name_type = query?.get("name_type");
    const manager = query?.get("manager");
    const fileRes = await thunkAPI.dispatch(
      httpRequest({
        url: `excels/ltv/physical/?${manager ? "&manager=" + manager : ""}${
          date_from ? "&date_from=" + date_from : ""
        }${date_to ? "&date_to=" + date_to : ""}${
          survey ? `&survey=${survey}` : ``
        }${activity ? `&activity=${activity}` : ``}${
          name_type ? `&name_type=${name_type}` : ``
        }`,
        noJson: true,
      })
    );
    const blob = await fileRes.blob();

    const blobURL = window.URL.createObjectURL(new Blob([blob]));
    const file = document.createElement("a");
    file.href = blobURL;
    file.setAttribute("download", "document.xlsx");
    document.body.appendChild(file);
    file.click();
    file.remove();
  }
);
export const updateLCOrdersManager = createAsyncThunk(
  "clientsList/updateLCOrdersManager",
  async (data: any, thunkAPI) => {
    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `legal-client/order/change/order/manager/`,
        method: "POST",
        body: { manager: data.manager, orders: data.orders || [] },
        // haveImg: true,
      })
    );

    if (res.id) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.created"),
        })
      );

      return res;
    }
  }
);
export const updatePCOrdersManager = createAsyncThunk(
  "clientsList/updatePCOrdersManager",
  async (data: any, thunkAPI) => {
    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/order/change/order/manager/`,
        method: "POST",
        body: { manager: data.manager, orders: data.orders || [] },
        // haveImg: true,
      })
    );

    if (res.id) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.created"),
        })
      );

      return res;
    }
  }
);
