export const prettierNum = (num: string | number) =>
  num?.toLocaleString().replace(/,/g, " ");
export function calculateOrderInPage(
  page: string | number | null,
  index: number,
  pageSize: number
) {
  return (Number(page || 1) - 1) * pageSize + index + 1;
}

export function sum(numbers: number[]) {
  let total = 0;
  for (let i = 0; i < numbers.length; i++) {
    total += numbers[i];
  }
  return total;
}

export function convertDateFormat(dateStr: string) {
  // Split the input date string by the hyphen
  let parts = dateStr.split("-");

  // Rearrange the parts to MM-DD-YYYY
  let newDateStr = `${parts[1]}-${parts[0]}-${parts[2]}`;

  return newDateStr;
}
export function removeDuplicates(array: any[]) {
  const seen = new Set();
  return array.filter((item) => {
    const serializedItem = JSON.stringify(item);
    const duplicate = seen.has(serializedItem);
    seen.add(serializedItem);
    return !duplicate;
  });
}
