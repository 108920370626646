import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTop,
  SearchInputWrapper,
} from "../../../PCApplications/components/Header/style";
import CustomInput from "../../../Common/CustomInput";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import searchIcon from "../../../../assets/icons/applications/search.svg";
import manImg from "../../../../assets/icons/applications/man.svg";
import useSearch from "../../../../hooks/useSearch";
import { useLocation, useNavigate } from "react-router-dom";
import UseReplace from "../../../../hooks/useReplace";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { PCClientDataT } from "../../../PCApplications/types";
import {
  LCAppsDataT,
  LCDataType,
  PCDataType,
  PCAppsDataT,
  dataType,
} from "../../../../types/commonTypes";
import { defaultPCClientData } from "../../../PCApplications/data";
import { LCClientDataT } from "../../types";
import { defaultLCClientData } from "../../data";

type T = {
  isRecommended?: boolean;
  editingApp: LCAppsDataT | null;
  handleClose?: () => void;
  setClientData?: (arg: LCClientDataT) => void;
  setRecommendedData?: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        name: string;
      }[]
    >
  >;
  setCheckErrors: (arg: boolean) => void;
  selectContact?: (id: number) => void;
  selectedContact?: LCDataType | null;
  selectedContacts?: number[];
  setSelectedContacts?: (arg: number[]) => void;
};

const SearchClientCard: React.FC<T> = ({
  isRecommended,
  editingApp,
  setClientData,
  setRecommendedData,
  setCheckErrors,
  selectContact,
  selectedContact,
  selectedContacts,
  setSelectedContacts,
  handleClose,
}) => {
  const { data: contacts } = useAppSelector((state) => state.LCContact);
  const query = useSearch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchValue, setSearchValue] = useState<string>(
    (isRecommended
      ? query.get("search-recommended-name")
      : query.get("search-client-name")) || ""
  );
  const [finalValue, setFinalValue] = useState<string>("");

  const handleContactSelection = (item: any) => {
    if (isRecommended && setSelectedContacts!) {
      const index = selectedContacts?.indexOf(item.id!);
      console.log(index, selectedContacts !== undefined);
      if (index === -1) {
        setSelectedContacts([...selectedContacts!, item.id!]);
      } else {
        selectedContacts &&
          setSelectedContacts(
            selectedContacts?.filter((id) => id !== item.id!)
          );
      }
    } else {
      selectContact && selectContact(item.id!);
      // Rest of your logic for setting client data and navigation
      setClientData &&
        setClientData({
          ...item,
          activity: item.activity,
          name: item.name,
          telegram: item.telegram,
          brand: item.brand || [""],
          contacts: item.contacts,
          name_type: item.name_type,
          created_by: item.created_by,
        });
    }
  };
  console.log(pathname);
  useEffect(() => {
    const delay = 500; // Adjust the delay as needed (e.g., 500 milliseconds)
    const timeoutId = setTimeout(() => {
      setFinalValue(searchValue);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue]);
  useEffect(() => {
    navigate(
      `${pathname}${UseReplace(
        isRecommended ? "search-recommended-name" : "search-client-name",
        String(finalValue.trim())
      )}`
    );
  }, [finalValue]);
  return (
    <Card>
      <CardTop>Поиск по базе</CardTop>
      <CardBody>
        <SearchInputWrapper>
          <CustomInput
            name="search"
            showError={false}
            value={searchValue}
            onChange={(e) => {
              !e && setClientData && setClientData(defaultLCClientData);
              !e && setRecommendedData && setRecommendedData([]);
              if (!editingApp || isRecommended) {
                setSearchValue(String(e));
                setCheckErrors(false);
              }
            }}
            fullWidth
            type="text"
            height="33px"
            placeholder="Введите наименование …"
            pl="10px"
            pr="40px"
          />
          <img src={searchIcon} alt="" />
        </SearchInputWrapper>

        {(isRecommended
          ? query.get("search-recommended-name")
          : query.get("search-client-name")) && contacts.results?.length > 0 ? (
          <List>
            {contacts.results.slice(0, 6).map((item: any) => (
              <ListItem disablePadding key={item.id}>
                <ListItemButton
                  onClick={() => handleContactSelection(item)}
                  // Add a checkbox for selecting multiple contacts
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px 15px",
                    marginTop: "7px",
                    background: (
                      isRecommended
                        ? selectedContacts?.includes(item.id)
                        : selectedContact?.id === item.id
                    )
                      ? "var(--primary)"
                      : "#e4e4e4",
                    color: (
                      isRecommended
                        ? selectedContacts?.includes(item.id)
                        : selectedContact?.id === item.id
                    )
                      ? "#fff"
                      : "#000",
                  }}
                >
                  {/* Use a Checkbox to handle selection */}
                  {isRecommended && (
                    <Checkbox
                      checked={selectedContacts?.includes(item.id)}
                      color="default"
                    />
                  )}
                  <ListItemText
                    sx={{
                      span: {
                        fs: "16px",
                        fontWeight: "400",
                        fontFamily: "Inter !important",
                      },
                    }}
                    primary={item.company_name}
                  />
                  <ListItemText
                    sx={{
                      span: {
                        fs: "16px",
                        fontWeight: "400",
                        fontFamily: "Inter !important",
                      },
                    }}
                    primary={
                      item.contacts[0]?.phone_number ||
                      item.contacts[0]?.telegram
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <img src={manImg} height="250px" alt="" />
        )}
      </CardBody>
      {isRecommended && (
        <Button
          variant="contained"
          sx={{
            width: "100%",
            height: "50px",
            borderRadius: "0px",
            background: "var(--primary)",
            color: "#fff",
            textTransform: "none",
            fontWeight: "600",
            fontSize: "16px",
            "&:hover": {
              background: "var(--primary)",
            },
          }}
          onClick={() => {
            console.log(selectedContacts);
            setRecommendedData && setRecommendedData([]);
            selectedContacts?.forEach((item) => {
              contacts.results?.forEach((contact) => {
                if (contact.id === item) {
                  setRecommendedData &&
                    setRecommendedData((prev: any) => [
                      ...prev,
                      { id: contact.id, name: contact.company_name },
                    ]);
                }
              });
            });
            if (handleClose) {
              navigate(
                `${pathname}${UseReplace("search-recommended-name", "")}`
              );
              handleClose();
            }
          }}
        >
          Сохранить
        </Button>
      )}
    </Card>
  );
};

export default SearchClientCard;
