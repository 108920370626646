import { DndProvider } from "react-dnd/dist/core";
import Application from "./components/Application";
import ApplicationTopStatus from "./components/ApplicationTopStatus";
import { Column, Container, MoveIcon, Wrapper } from "./style";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useRef } from "react";
import DropWrapper from "./components/DropWrapper";
import { useCallback, useEffect, useState } from "react";
import Header from "./components/Header";
import { Box, SelectChangeEvent, Stack } from "@mui/material";
import rightIcon from "../../assets/icons/applications/chevron-right.svg";
import {
  getPCApplications,
  getPCApplicationsByStep,
} from "../../store/actions/PCApplicationsActions";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  handleClose,
  setPCAppsByStepPending,
  setAppsData,
  setEditingApp,
} from "../../store/slices/PCApplicationsSlice";
import MainLoading from "../MainLoading";
import CommonModal from "../Common/CustomModal";
import { httpRequest } from "../../utils/request";
import useSearch from "../../hooks/useSearch";
import CustomButton from "../Common/CustomButton";
import { Text } from "../../globalStyle";
import { FlexWrapper } from "../Common/FlexWrapper";
import CustomSelect from "../Common/CustomSelect";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FadingBalls } from "react-cssfx-loading";
import { getPCContact } from "../../store/actions/PCContactActions";
import AddApplication from "./components/AddApplication";
import {
  PCAppsDataT,
  PCOnDropT,
  PCStatusT,
  showMoreT,
  statusT,
} from "../../types/commonTypes";
import { getTypes } from "../../store/actions/settingsActions";

const PCApplications = () => {
  const {
    pending,
    openModal,
    PCAppsData: appsData,
    cancelReason,
    fillPricingData,
    showMoreDefault,
  } = useAppSelector((state) => state.PCApplications);

  const { Data } = useAppSelector((state) => state.settings);
  const { cancel, pcStatuses } = Data;
  console.log(pcStatuses, "pcSttausedeFRilter");

  const [cancelId, setCancelId] = useState<number | null>(null);
  const [showArrows, setShowArrows] = useState({ left: false, right: true });
  const [stepLoading, setStepLoading] = useState(false);
  const [showMore, setShowMore] = useState<showMoreT[]>([]);
  const removeDuplicateObjects = (arr: any[]): any[] => {
    const uniqueSet = new Set<number>();
    return arr.filter(
      (item) => !uniqueSet.has(item.step) && uniqueSet.add(item.step)
    );
  };
  useEffect(() => {
    if (showMoreDefault.length === 5) {
      setShowMore(showMoreDefault);
    } else if (showMoreDefault.length > 5) {
      const filteredShowMore = removeDuplicateObjects(showMoreDefault);
      setShowMore(filteredShowMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMoreDefault, showMoreDefault.length]);
  console.log(showMoreDefault, "showmoredefaultsystem");

  const onChangeSelect = (event: SelectChangeEvent) =>
    setCancelId(
      cancel.find((item) => item.id === Number(event.target.value))!.id
    );
  // @ts-ignore
  const appsDataSecond = appsData?.results;
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const query = useSearch();
  const { t } = useTranslation();

  const appTypes = t("PCApplications.applicationsTypes", {
    returnObjects: true,
  }) as statusT[];
  // pcStatuses.map((item) => {
  //   return { title: item.name, step: String(item?.id) };
  // }) as PCStatusT[];
  console.log(appTypes);

  const onDrop = async (data: PCOnDropT) => {
    console.log(data, "iwanttosee");

    setStepLoading(true);
    const { item, step } = data;
    let newStepId = null as number | null;

    const newItems =
      // (appsDataSecond || appsData)
      //   .filter((i: any) => i.id !== item.id)
      //   .concat({ ...item, step });
      [{ ...item, step }].concat(
        (appsDataSecond || appsData).filter((i: any) => i.id !== item.id)
      );

    await dispatch(
      httpRequest({
        url: `physical/order/step/set_new_step/`,
        method: "POST",
        body: {
          old_step: item.step_id,
          new_step: step,
          step_reason: cancelId || undefined,
          order_id: item.id,
        },
        noJson: true,
      })
    );
    // if (res?.id) {
    //   newStepId = res.id;
    //   const finishRes = await dispatch(
    //     httpRequest({
    //       url: `step/finish_step/`,
    //       method: "PATCH",
    //       body: { id: item.step_id },
    //       noJson: true,
    //     })
    //   );

    //   if (finishRes.status === 200) {
    //     if (cancelId || fillPricingData) dispatch(handleClose());
    //     const newChangedItems = newItems.map((app: any) => {
    //       if (app.id === item.id) return { ...app, step_id: newStepId! };
    //       else return app;
    //     });

    //     dispatch(setAppsData(newChangedItems));
    //     if (fillPricingData) {
    //       const resUser = await await dispatch(
    //         httpRequest({
    //           url: `order/${item.id}/`,
    //           method: "PATCH",
    //           body: item,
    //         })
    //       );
    //       if (resUser.id) {

    //       }
    //     }
    //   }
    // }
    dispatch(setAppsData([]));
    console.log("lopi");
    appTypes.forEach((item) => {
      dispatch(setPCAppsByStepPending(item.step));
      dispatch(getPCApplicationsByStep({ api: item.step }));
    });
    // dispatch(getPCApplications(query));
    setStepLoading(false);
    dispatch(handleClose());
    setCancelId(null);
  };

  // const moveItem = (dragIndex: number, hoverIndex: number, status: string) => {};

  const renderCard = useCallback(
    (i: PCAppsDataT, idx: number, step: string) => {
      return <Application key={i.id} item={i} index={idx} step={step} />;
    },
    []
  );

  const ref = useRef(null);

  const handleClick = (px: number) => {
    // @ts-ignore
    ref.current.scrollLeft += px;
    // @ts-ignore
    if (ref.current.scrollLeft === 0)
      setShowArrows({ ...showArrows, left: false });
    // @ts-ignore
    else if (!showArrows.left) setShowArrows({ ...showArrows, left: true });
    // ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (window.location.search) {
      dispatch(setAppsData([]));
      appTypes.forEach((item) => {
        dispatch(setPCAppsByStepPending(item.step));
        dispatch(getPCApplicationsByStep({ api: item.step, query }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get("search-name"), query.get("ordering")]);
  // useEffect(() => {}, []);
  // const callDatas = () => {
  //   dispatch(
  //     getTypes({ url: "physical-settings", dataType: "pcStatuses" })
  //   ).then((res: any) => {
  //     const appTypes = res.payload?.data?.map((item: any) => {
  //       return { title: item.name, step: String(item?.id) };
  //     }) as PCStatusT[];
  //     dispatch(setAppsData([]));
  //     appTypes.forEach((item) => {
  //       dispatch(setPCAppsByStepPending(item.step));
  //       dispatch(getPCApplicationsByStep({ api: item.step, query }));
  //     });
  //   });
  // };
  useEffect(() => {
    if (pathname === "/applications-pc" && !search) {
      dispatch(setAppsData([]));
      appTypes.forEach((item) => {
        dispatch(setPCAppsByStepPending(item.step));
        dispatch(getPCApplicationsByStep({ api: item.step }));
      });
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (ref?.current?.scrollLeft === 0)
      setShowArrows({ ...showArrows, left: false });
    // @ts-ignore
    else if (!showArrows?.left) setShowArrows({ ...showArrows, left: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openModal === "") dispatch(setEditingApp(null));
  }, [openModal, dispatch]);
  console.log(appsData, showMore, "deawnj34uj");

  return (
    <DndProvider backend={HTML5Backend}>
      <Header />
      <Box
        height="calc(100vh - 190px)"
        p="10px 30px 0 30px"
        position="relative"
      >
        <MoveIcon top="50%" right="15px" onClick={() => handleClick(150)}>
          <img src={rightIcon} alt="" />
        </MoveIcon>
        {showArrows.left && (
          <MoveIcon top="50%" left="310px" onClick={() => handleClick(-150)}>
            <img src={rightIcon} alt="" style={{ rotate: "180deg" }} />
          </MoveIcon>
        )}
        {pending ? (
          <MainLoading />
        ) : (
          <Container ref={ref}>
            {appTypes.map(({ title, step, color }: statusT, index: number) => (
              <Column key={index}>
                <Box width={"100%"} bgcolor="#e6e6e6" p="10px" pb="5px">
                  <ApplicationTopStatus
                    color={color}
                    count={
                      appsData.type.find((appType) => appType.step === step)
                        ?.count
                    }
                    title={title}
                    isPayingStep={step === "paying"}
                  />
                </Box>
                <Wrapper>
                  <DropWrapper
                    onDrop={onDrop}
                    step={step}
                    showMore={showMore}
                    setShowMore={setShowMore}
                  >
                    {showMore.map((item) => item.step).includes(step)
                      ? (appsData.results || appsDataSecond)
                          ?.filter((i: any) => i.step === step)
                          .map((i: any, idx: number) =>
                            renderCard(i, idx, step)
                          )
                      : (appsData.results || appsDataSecond)
                          ?.filter((i: any) => i.step === step)
                          .slice(0, 10)
                          .map((i: any, idx: number) =>
                            renderCard(i, idx, step)
                          )}
                  </DropWrapper>
                </Wrapper>
              </Column>
            ))}
          </Container>
        )}

        <CommonModal
          open={openModal === "cancel-alert"}
          handleClose={() => dispatch(handleClose())}
        >
          <Stack gap="25x" bgcolor="#fff" p="20px" borderRadius="10px">
            <CustomSelect
              width="230px"
              value={cancelId || ""}
              values={Data.cancel}
              onChange={onChangeSelect}
            />

            <FlexWrapper gap="17px" alignItems="center" mt="20px">
              <Text
                c="#6C6C6C"
                fs="20px"
                fw="700"
                onClick={() => dispatch(handleClose())}
                style={{ cursor: "pointer" }}
              >
                {t("common.cancel")}
              </Text>
              <CustomButton
                bgColor="var(--primary)"
                fs="20px"
                value="принять"
                color="#fff"
                type="button"
                disable={!cancelId}
                width="123px"
                height="32px"
                padding="0"
                fw="700"
                onClick={() => onDrop(cancelReason)}
              />
            </FlexWrapper>
          </Stack>
        </CommonModal>

        <CommonModal
          open={openModal === "setPricing"}
          handleClose={() => dispatch(handleClose())}
          customStyled
        >
          <AddApplication
            isFillPricing
            handleClose={() => dispatch(handleClose())}
            onDrop={onDrop}
          />
        </CommonModal>

        <CommonModal open={stepLoading} handleClose={() => {}}>
          <Box width="100px" height="100px">
            <FadingBalls color="#fff" width="70px" duration="0.4s" />
          </Box>
        </CommonModal>

        {/* <Pagination
          sx={{
            position: "absolute",
            bottom: "15px",
            right: "15px",
          }}
          count={
            Math.floor(appsData?.count / 20) + (appsData?.count % 20 && 1) || 1
          }
          page={Number(query.get("page")) || 1}
          onChange={(e, value) => {
            navigate(`${pathname}${UseReplace("page", String(value))}`);
          }}
          color="primary"
        /> */}
      </Box>
    </DndProvider>
  );
};

export default PCApplications;
