import user from "../../assets/icons/settings/user.svg";
import trash from "../../assets/icons/settings/trash.svg";
import edit1 from "../../assets/icons/settings/edit1.svg";
import users from "../../assets/icons/settings/users.svg";
import questionMark from "../../assets/icons/settings/question-mark.svg";
import UserProfile from "./components/UserProfile";
import KindOfClients from "./components/ClientTypes";
import About from "./components/About";
import OurCommitments from "./components/OurCommitments";
import PCStatus from "./components/PCStatus";
import LSStatus from "./components/LSStatus";
import Cancel from "./components/Cancel";
import PCInterest from "./components/PCInterest";
import LCInterest from "./components/LCInterest";

export const icons = [
  user,
  edit1,
  edit1,
  users,
  questionMark,
  trash,
  users,
  edit1,
  edit1,
  edit1,
  edit1,
];

export const pages = [
  {
    page: <UserProfile />,
    path: "user-settings",
  },
  {
    page: <PCStatus />,
    path: "pc-status",
  },
  {
    page: <LSStatus />,
    path: "ls-status",
  },
  {
    page: <OurCommitments />,
    path: "our-commitments",
  },
  {
    page: <About />,
    path: "know-about-us",
  },
  {
    page: <Cancel />,
    path: "reason-of-cancel",
  },
  {
    page: <KindOfClients />,
    path: "kind-of-activity-clients",
  },
  {
    page: <PCInterest />,
    path: "pc-interest",
  },
  {
    page: <LCInterest />,
    path: "lc-interest",
  },
];
