import { PCStatusT, postPCDataType, statusT } from "../../types/commonTypes";
import { PCAppType } from "../../components/PCApplications/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import i18next, { t } from "i18next";
import { httpRequest } from "../../utils/request";
import { openSnackbar } from "../slices/mainSlice";
import {
  handleClose,
  setPCAppsByStepPending,
  setAppsData,
} from "../slices/PCApplicationsSlice";
import { postDataType } from "../../types/commonTypes";
import { nameTypes } from "../../components/PCApplications/data";
import { useAppSelector } from "../../hooks/reduxHooks";

export const getPCApplications = createAsyncThunk(
  "PCApplications/getPCApplications",
  async (data: any, thunkAPI) => {
    const ordering = data.query.get("ordering") || data.ordering;
    const date_from = data.query.get("date_from");
    const date_to = data.query.get("date_to");
    const manager = data.query.get("manager");
    const page = data.query.get("page");
    const survey = data.query.get("survey");
    const search = data.query.get("search-name");

    return await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/order/list/?${
          data.page_size ? "&page_size=" + data.page_size : ""
        }${date_from ? `&date_from=${date_from}` : ``}${
          date_to ? `&date_to=${date_to}` : ``
        }${manager ? `&manager=${manager}` : ``}${page ? `&page=${page}` : ``}${
          search ? `&search=${search}` : ``
        }${ordering ? `&ordering=${ordering}` : ``}${
          survey ? `&survey=${survey}` : ``
        }`,
      })
    );
  }
);

export const getPCApplicationsArchives = createAsyncThunk(
  "PCApplications/getPCApplicationsArchives",
  async (data: any, thunkAPI) => {
    const ordering = data.ordering;
    const date_from = data.query.get("date_from");
    const date_to = data.query.get("date_to");
    const manager = data.query.get("manager");
    const page = data.page;
    const page_size = data.page_size;
    const survey = data.query.get("survey");
    const name = data.query.get("search-name");
    const status = data.query.get("status");

    return await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/archive/?${status ? "&status=" + status : ""}${
          page_size ? "&page_size=" + page_size : ""
        }${date_from ? `&date_from=${date_from}` : ``}${
          date_to ? `&date_to=${date_to}` : ``
        }${manager ? `&manager=${manager}` : ``}${page ? `&page=${page}` : ``}${
          name ? `&name=${name}` : ``
        }${ordering ? `&ordering=${ordering}` : ``}${
          survey ? `&survey=${survey}` : ``
        }`,
      })
    );
  }
);

export const getPCApplicationsByStep = createAsyncThunk(
  "PCApplications/getPCApplicationsByStep",
  async (
    data: {
      api: string;
      page?: number;
      refetch?: boolean;
      query?: any;
    },
    thunkAPI
  ) => {
    const { api, refetch, page } = data;
    const ordering = data?.query?.get("ordering");
    const date_from = data?.query?.get("date_from");
    const date_to = data?.query?.get("date_to");
    const manager = data?.query?.get("manager");
    const survey = data?.query?.get("survey");
    const search = data?.query?.get("search-name");
    const status = JSON.parse(data?.query?.get("status") || "[]")?.map(
      (item: any) => item?.id
    );
    const interest = JSON.parse(data?.query?.get("interest") || "[]")?.map(
      (item: any) => item?.id
    );
    const resData = await thunkAPI.dispatch(
      httpRequest({
        url: `physical/order/step/${api}/?${
          date_from ? `&date_from=${date_from}` : ``
        }${date_to ? `&date_to=${date_to}` : ``}${
          manager ? `&manager=${manager}` : ``
        }${page ? `&page=${page}` : ``}${search ? `&search=${search}` : ``}${
          ordering ? `&ordering=${ordering}` : ``
        }${survey ? `&survey=${survey}` : ``}${
          status.length ? `&status=${status}` : ``
        }${interest.length ? `&interest=${interest}` : ``}`,
      })
    );
    return { data: resData, step: api, page, refetch };
  }
);

export const getPCNotifications = createAsyncThunk(
  "PCApplications/getPCNotifications",
  async (_, thunkAPI) =>
    await thunkAPI.dispatch(
      httpRequest({ url: "notification/my_notifications/" })
    )
);

export const createPCApplicationThunk = createAsyncThunk(
  "PCApplications/createPCApplicationThunk",
  async (data: any, thunkAPI) => {
    // const notification = (thunkAPI.getState() as any).applications
    //   .exceptedNotification?.id;
    // const { Data } = useAppSelector((state) => state.settings);

    const { order, user, objects, clientId } = data;

    const resUser = await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/update/${clientId}/`,
        method: "PATCH",
        body: user,
      })
    );

    if (resUser.data.id) {
      const resOrder = await thunkAPI.dispatch(
        httpRequest({
          url: `physical-client/order/create/`,
          method: "POST",
          body: { ...order },
        })
      );

      if (!!resOrder.result.data.id) {
        thunkAPI.dispatch(
          openSnackbar({
            status: "success",
            message: i18next.t("settings.snackbar.created"),
          })
        );
        thunkAPI.dispatch(setAppsData([]));

        thunkAPI.dispatch(setPCAppsByStepPending("introduction"));
        thunkAPI.dispatch(getPCApplicationsByStep({ api: "introduction" }));

        if (
          (thunkAPI.getState() as any).settings?.profile?.role ===
          "account_manager"
        )
          thunkAPI.dispatch(getPCNotifications());
      }
    }
  }
);

export const createPCApplicationWithClientThunk = createAsyncThunk(
  "PCApplications/createPCApplicationWithClientThunk",
  async (data: { user: postPCDataType; appData: any }, thunkAPI) => {
    // const pcStatuses = (thunkAPI.getState() as any).settings.Data.pcStatuses;
    const appTypes = t("PCApplications.applicationsTypes", {
      returnObjects: true,
    }) as statusT[];
    const { user, appData } = data;
    const order = {
      company_name: user.company_name,
      full_name: user.full_name,
      note: user.note,
      job_title: user.job_title,
      telegram: user.telegram,
      interests: user.interests,
      manager: user.manager?.id,
      person_contacts: user.person_contacts,
    };

    const resUser = await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/create/`,
        method: "POST",
        body: order,
      })
    );

    console.log(order, "tezroq");
    if (resUser.result.id) {
      const { order, objects } = appData;

      const resOrder = await thunkAPI.dispatch(
        httpRequest({
          url: `physical-client/order/create/`,
          method: "POST",
          body: { ...order, client: order.id || resUser.result.id },
        })
      );

      if (resOrder.result.data.id) {
        thunkAPI.dispatch(
          openSnackbar({
            status: "success",
            message: i18next.t("settings.snackbar.created"),
          })
        );
        thunkAPI.dispatch(setAppsData([]));
        appTypes.forEach((item) => {
          thunkAPI.dispatch(setPCAppsByStepPending(item.step));
          thunkAPI.dispatch(getPCApplicationsByStep({ api: item.step }));
        });
        if (
          (thunkAPI.getState() as any).settings?.profile?.role ===
          "account_manager"
        )
          thunkAPI.dispatch(getPCNotifications());
      }
    }
  }
);

export const deletePCContactsThunk = createAsyncThunk(
  "PCApplications/deletePCContactsThunk",
  async (data: any, thunkAPI) => {
    const { contacts, clientId } = data;
    console.log(contacts);

    await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/delete/`,
        method: "POST",
        body: { id: contacts },
      })
    );
  }
);
export const editPCApplicationThunk = createAsyncThunk(
  "PCApplications/editPCApplicationThunk",
  async (data: any, thunkAPI) => {
    // const pcStatuses = (thunkAPI.getState() as any).settings.Data.pcStatuses;
    // const appTypes = pcStatuses.map((item: any) => {
    //   return { title: item?.name, step: String(item?.id) };
    // }) as PCStatusT[];
    const { orderId, order, client, clientId, query } = data;

    const resUser = await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/update/${clientId}/`,
        method: "PATCH",
        body: client,
      })
    );

    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/order/update/${orderId}/`,
        method: "PATCH",
        body: {
          ...order,
          is_expired: false,
          is_archived: false,
          payment_status: false,
        },
        noJson: true,
      })
    );

    console.log(res, "resloginfo");
    if (res.status === 200 || resUser.status === 200) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.updated"),
        })
      );
      // thunkAPI.dispatch(setAppsData([]));
      // appTypes.forEach((item) => {
      //   thunkAPI.dispatch(setPCAppsByStepPending(item.step));
      //   thunkAPI.dispatch(getPCApplicationsByStep({ api: item.step }));
      // });
      thunkAPI.dispatch(handleClose());
    }
  }
);

export const deletePCApplicationThunk = createAsyncThunk(
  "PCApplications/deletePCApplicationThunk",
  async (_, thunkAPI) => {
    const ids = (thunkAPI.getState() as any).PCApplications.selectedItems;
    const res = await thunkAPI.dispatch(
      httpRequest({
        url: `physical-client/order/delete/`,
        method: "POST",
        body: { ids },
        noJson: true,
      })
    );

    if (res.status === 204 || res.status === 200) {
      thunkAPI.dispatch(
        openSnackbar({
          status: "success",
          message: i18next.t("settings.snackbar.deleted"),
        })
      );
      return { status: "success" };
    }
  }
);
